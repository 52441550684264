import React from 'react';
import PropTypes from 'prop-types';
import './Badge.scss';
const Badge = ({
    color,
    count,
    offset,
    verticalPosition,
    overflowCount,
    showZero,
    size,
    text,
    title,
    children,
}) => {
    const renderBadge = () => {
        if (count === 0 && !showZero && !text) {
            return null;
        }

        const formattedCount =
            overflowCount && count > overflowCount
                ? `${overflowCount}+`
                : count;

        return (
            <span
                className={`badge ${size ? `badge-${size}` : ''}`}
                style={{ backgroundColor: color, top: verticalPosition }}
                title={title}
            >
                {text || formattedCount}
            </span>
        );
    };

    return (
        <div className='badge-wrapper'>
            <div>{children}</div>
            {renderBadge()}
        </div>
    );
};

Badge.propTypes = {
    color: PropTypes.string,
    count: PropTypes.number,
    offset: PropTypes.string,
    verticalPosition: PropTypes.string,
    overflowCount: PropTypes.number,
    showZero: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    text: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.node.isRequired,
};

Badge.defaultProps = {
    color: 'red',
    count: 0,
    offset: '0px',
    verticalPosition: '0px',
    overflowCount: null,
    showZero: false,
    size: 'default',
    text: '',
    title: '',
};

export default Badge;
