/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/support/e_action_result_code.py
Aucune modification manuelle acceptée
*/

// code de resultat d'action du wallet local
const eActionResultCode = Object.freeze({
  // Ok
  0: 'eActionResultCode.ok',

  // Echec de la requête
  1: 'eActionResultCode.requestFailed',

  // Echec de la transaction
  2: 'eActionResultCode.transactionFailed',
});

export default eActionResultCode;
