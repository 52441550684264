/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/system/e_access_level.py
Aucune modification manuelle acceptée
*/

// Niveau d'accès à une fonctionnalitée
const eAccessLevel = Object.freeze({
  // Uniquement l'exploitant peut accéder à cette fonctionnalité. Cette fonctionnalité peut être affichée sur les pages web.
  1: 'eAccessLevel.manager',

  // Tout utilisateur connecté peut accéder à cette fonctionnalité.
  2: 'eAccessLevel.publicLogin',

  // Tout utilisateur peut accéder à cette fonctionnalité.
  3: 'eAccessLevel.publicAnonymous',
});

export default eAccessLevel;
