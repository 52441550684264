import React from 'react';
import PropTypes from 'prop-types';
import './Spinner.scss';

const Spinner = ({ size, progress, color, children }) => {
    const ringRotation = progress * 180; // Convert progress to degrees (since it's half a ring)
    return (
        <div className='spinner-container'>
            <div className='ring'>
                <div className='ring-inner'>
                    <div
                        className='semi-ring'
                        style={{ transform: `rotate(${ringRotation}deg)` }}
                    />
                </div>
            </div>
        </div>
    );
};

Spinner.propTypes = {
    size: PropTypes.string,
    progress: PropTypes.number, // Progress as a value between 0 and 1
    //   color: PropTypes.string,
    //   children: PropTypes.node,
};

Spinner.defaultProps = {
    size: '48px',
    color: 'red',
};

export default Spinner;
