export const hasPermission = (apiPermission, groupPermissions) => {
    let checkPermission;

    if (groupPermissions === undefined || groupPermissions === null) {
        checkPermission = true;
    } else {
        checkPermission = groupPermissions?.some(
            (item) =>
                item.app_name === apiPermission.split(':')[0] &&
                item.code_name === apiPermission.split(':')[1],
        );
    }

    return checkPermission;
};
