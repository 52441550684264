import React, { useState, useEffect } from 'react';
import { Select } from '../../modUtils/components/componentsLibrary/componentsLibrary';
import { useTranslation } from 'react-i18next';

export default function SupportFilter({
    onValueChange,
    statusList,
    entityLink,
    actionInProgress,
    blacklistList,
}) {
    const { t } = useTranslation();
    const optionsGroup = [
        {
            label: t('SupportPage.optionBadgeLabel'),
            groupValue: 1,
            options: [
                { label: t('SupportPage.optionBadgeRegistred'), value: 'true' },
                {
                    label: t('SupportPage.optionBadgeNonRegistred'),
                    value: 'false',
                },
            ],
        },
        {
            label: t('SupportPage.optionStatusBadgeLabel'),
            groupValue: 2,
            options: [
                { label: t('SupportPage.optionStatusInStock'), value: 0 },
                { label: t('SupportPage.optionStatusUsed'), value: 1 },
            ],
        },
        {
            label: t('SupportPage.optionBlacklistLabel'),
            groupValue: 3,
            options: [
                { label: t('SupportPage.optionBlacklisted'), value: 2 },
                { label: t('SupportPage.optionNonBlacklisted'), value: 3 },
            ],
        },
        {
            label: t('SupportPage.optionActionsBadgeLabel'),
            groupValue: 4,
            options: [
                {
                    label: t('SupportPage.optionActionBadge'),
                    value: 'actionInProgress',
                },
            ],
        },
    ];
    const [selectedOptions, setSelectedOptions] = useState([
        {
            groupValue: 1,
            options: entityLink,
        },
        {
            groupValue: 2,
            options: statusList,
        },
        {
            groupValue: 3,
            options: blacklistList,
        },
        {
            groupValue: 4,
            options: actionInProgress,
        },
    ]);
    let numberOfSelectedOptions =
        selectedOptions[0].options.length +
        selectedOptions[1].options.length +
        selectedOptions[2].options.length +
        selectedOptions[3].options.length;

    useEffect(() => {
        onValueChange(selectedOptions);
    }, [selectedOptions, onValueChange]);

    const handleChange = (value) => {
        if (value == 0 || value == 1) {
            setSelectedOptions((prevSelectedOptions) => {
                const updatedOptions = [...prevSelectedOptions];
                const groupIndex = updatedOptions.findIndex(
                    (group) => group.groupValue === 2,
                );
                updatedOptions[groupIndex].options.push(value);
                return updatedOptions;
            });
        } else if (value == 2 || value == 3) {
            setSelectedOptions((prevSelectedOptions) => {
                const updatedOptions = [...prevSelectedOptions];
                const groupIndex = updatedOptions.findIndex(
                    (group) => group.groupValue === 3,
                );
                updatedOptions[groupIndex].options.push(value);
                return updatedOptions;
            });
        } else if (value == 'true' || value == 'false') {
            setSelectedOptions((prevSelectedOptions) => {
                const updatedOptions = [...prevSelectedOptions];
                const groupIndex = updatedOptions.findIndex(
                    (group) => group.groupValue === 1,
                );
                updatedOptions[groupIndex].options.push(value);
                return updatedOptions;
            });
        } else if (value == 'actionInProgress') {
            setSelectedOptions((prevSelectedOptions) => {
                const updatedOptions = [...prevSelectedOptions];
                const groupIndex = updatedOptions.findIndex(
                    (group) => group.groupValue === 4,
                );
                updatedOptions[groupIndex].options = ['true'];
                return updatedOptions;
            });
        }
    };

    const handleDeselect = (deselectedValue, selectedList) => {
        setSelectedOptions((prevSelectedOptions) => {
            const updatedOptions = [...prevSelectedOptions];
            let groupIndex = null;
            if (deselectedValue == 'actionInProgress') {
                groupIndex = 3;
                updatedOptions[groupIndex].options = [];
            } else {
                // Find the group that contains the deselected value
                groupIndex = updatedOptions.findIndex((group) =>
                    group.options.includes(deselectedValue),
                );
            }
            if (groupIndex !== -1) {
                // Remove the deselected value from the options array
                updatedOptions[groupIndex].options = updatedOptions[
                    groupIndex
                ].options.filter((option) => option !== deselectedValue);
            }

            return updatedOptions;
        });
    };

    return (
        <Select
            options={optionsGroup}
            defaultValue={[
                statusList,
                actionInProgress,
                entityLink,
                blacklistList,
            ]}
            dropdownMatchSelectWidth={false}
            multiple
            placeholder={
                'Filtres' +
                (numberOfSelectedOptions > 0
                    ? ` (${numberOfSelectedOptions})`
                    : '')
            }
            onChange={(value) => handleChange(value)}
            onDeselect={(value, selectedList) =>
                handleDeselect(value, selectedList)
            }
            onSelect={(value, option) => handleChange(value, option)}
        />
    );
}
