import React, { useState } from 'react';
import './Widget.scss';

export default function Widget({ title, content, className }) {
    const [isVisible, setIsVisible] = useState(true);
    function hideWidget() {
        setIsVisible(false);
    }

    return (
        isVisible && (
            <div
                className={`widget max-w-full${className ? ' ' + className : ''}`}
            >
                <div className='widget-header flex flex-row items-center justify-between'>
                    <div className='widget-title'>{title}</div>
                    <div onClick={hideWidget}> X </div>
                </div>

                <div className='widget-content'>{content}</div>
            </div>
        )
    );
}
