import React, { useState, useEffect } from 'react';
import StoreCard from './storeCard/StoreCard';
import FilterByEquipmentType from 'ck_commun/src/app/modUtils/components/filters/FilterByEquipmentType';
import FilterBySearchSite from 'ck_commun/src/app/modManager/components/filterBySearchSite/FilterBySearchSite';
import SubmitButton from 'ck_commun/src/app/modUtils/components/buttons/submitButton/SubmitButton';
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage';

export default function Store() {
    const [equipmentTypeList, setEquipmentTypeList] = useState();
    const [siteList, setSiteList] = useState();
    function handleSiteValueChange(selectedList) {
        setSiteList(selectedList?.length > 0 ? selectedList : null);
    }

    const handleEquipmentType = (selectedList) => {
        setEquipmentTypeList(selectedList?.length > 0 ? selectedList : null);
    };
    const onExecuteSubmitBtn = () => {
        console.log('submit');
    };

    return (
        <div>
            <BuildingPage />
            {/* <div className=" filters flex-display flex-row align-items-end">
        <FilterByEquipmentType
          onValueChange={handleEquipmentType}
        ></FilterByEquipmentType>
        <FilterBySearchSite
          onValueChange={handleSiteValueChange}
        ></FilterBySearchSite>
        <SubmitButton onClick={() => onExecuteSubmitBtn()} />
      </div> */
            /* <div className="flex-display flex-row">
        <div className="flex-display flex-column"> ajouter</div>
        <div className="flex-display flex-wrap">
          <StoreCard
            icon={
              process.env.PUBLIC_URL + "/img/promoIcons/widgetPromoIcon.png"
            }
            label="utiliser cette caisse pour vendre des produits"
            color="#2D62ED"
          ></StoreCard>
          <StoreCard
            icon={
              process.env.PUBLIC_URL + "/img/promoIcons/widgetPromoIcon.png"
            }
            label="réceptionner vos produits mettre à jour le stock"
            color="#00CCF2"
          ></StoreCard>
          <StoreCard
            icon={
              process.env.PUBLIC_URL + "/img/promoIcons/widgetPromoIcon.png"
            }
            label="synthèse journalière"
            color="#0088FF"
          ></StoreCard>
          <StoreCard
            icon={
              process.env.PUBLIC_URL + "/img/promoIcons/widgetPromoIcon.png"
            }
            label="articles catalogues"
            color="#3D09A2"
          ></StoreCard>
          <StoreCard
            icon={
              process.env.PUBLIC_URL + "/img/promoIcons/widgetPromoIcon.png"
            }
            label="fournisseurs"
            color={"#F53C56"}
          ></StoreCard>
          <StoreCard
            icon={
              process.env.PUBLIC_URL + "/img/promoIcons/widgetPromoIcon.png"
            }
            label="etats"
            color="#00D9A6"
          ></StoreCard>
          <StoreCard
            icon={
              process.env.PUBLIC_URL + "/img/promoIcons/widgetPromoIcon.png"
            }
            label="imprimer code barre"
            color="#008CB3"
          ></StoreCard>
          <StoreCard
            icon={
              process.env.PUBLIC_URL + "/img/promoIcons/widgetPromoIcon.png"
            }
            label="configuration"
            color="#003C4D"
          ></StoreCard>
        </div>
      </div> */}
        </div>
    );
}
