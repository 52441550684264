import React from 'react';
import './SupportCustomerInfosModal.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { get_entity_paginated } from '../../../modEntity/slices/entitySlice';
import { Modal } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import EntityCustomerCompanyForm from '../../../modEntity/components/EntityCustomerCompanyForm';

export default function SupportCustomerInfosModal({
    entity,
    modalVisible,
    setModalVisible,
}) {
    const isEdit = !!entity;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groupId } = useSelector((state) => state.manager);

    const onReset = () => {
        setModalVisible(false);
    };

    async function getEntities(onlyParent = true) {
        const orderingFilter = 'name';
        const typeFilter = 'customer,company';
        await dispatch(
            get_entity_paginated({
                groupId,
                limit: 100, // TODO gestion de la limite
                offset: 0,
                type: typeFilter,
                ordering: orderingFilter,
            }),
        );
    }

    return (
        <>
            <Modal
                title={
                    isEdit
                        ? t('supportCustomerInfosModal.customerInformation')
                        : t('supportCustomerInfosModal.creationCustomer')
                }
                isOpen={modalVisible}
                onClose={onReset}
                zIndex={1001}
                style={{
                    modalContent: {
                        minWidth: '500px',
                        width: '40%',
                        minHeight: '50%',
                    },
                }}
            >
                {modalVisible && (
                    <EntityCustomerCompanyForm
                        showSelectEntityType={true}
                        entity={entity}
                        closeModal={onReset}
                        getEntities={getEntities}
                    />
                )}
            </Modal>
        </>
    );
}
