import React from 'react';
import MainRoutes from './routes/MainRoutes';
import './App.scss';
import 'antd/dist/antd.min.css';

function App() {
    return <MainRoutes />;
}

export default App;
