import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_city_paginated } from '../slices/locationSlice';
import { Form, Select, Spin, message } from 'antd';
import AlertMessage from '../../modUtils/components/AlertMessage';
import { useTranslation } from 'react-i18next';
const { Option } = Select;

const CityInput = ({ defaultValue, setValue, readOnly, disabled }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(1);
    const { cities } = useSelector((state) => state.location);
    const [selectedValue, setSelectedValue] = useState(); // Nouvelle variable d'état pour la valeur sélectionnée
    // useEffect(() => {
    //   if (searchValue.length >= 3 && searchValue[searchValue.length - 1] !== "") {
    //     // setOptions(page === 1 ? cities.results : [...options, ...cities.results]);
    //     setHasMore(cities.next !== null);
    //     setLoading(false);
    //   }
    // }, [cities]);
    useEffect(() => {
        if (
            searchValue.length >= 3 &&
            searchValue[searchValue.length - 1] !== ''
        ) {
            const newOptions = cities?.map((city) => ({
                id: city.id,
                postalCode: city.postal_code,
                name: city.name,
            }));
            setOptions((prevOptions) =>
                page === 1 ? newOptions : [...prevOptions, ...newOptions],
            );
            //   setHasMore(cities.next !== null);
            setLoading(false);
        } else setOptions([]);
    }, [cities, searchValue, page]);

    const fetchOptions = async (search, page) => {
        if (search && search?.length > 3) {
            setLoading(true);
            try {
                await dispatch(
                    get_city_paginated({
                        offset: (page - 1) * 10,
                        limit: 10,
                        search,
                    }),
                ).unwrap();
            } catch (error) {
                message.error({
                    content: (
                        <AlertMessage
                            status={error?.status}
                            alertMessage={`Une erreur est survenue lors de la récupération de la liste des villes!`}
                            errorDetail={error?.data?.detail}
                        />
                    ),
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        setPage(1);
        if (
            searchValue.length >= 3 &&
            searchValue[searchValue.length - 1] !== ''
        )
            fetchOptions(value, 1);
    };

    const handlePopupScroll = (event) => {
        const { target } = event;
        if (
            !loading &&
            hasMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
        ) {
            const nextPage = page + 1;
            setPage(nextPage);
            fetchOptions(searchValue, nextPage);
        }
    };
    // Fonction pour gérer le changement de sélection
    const handleSelectChange = (value) => {
        setSelectedValue(value); // Met à jour la valeur sélectionnée
        if (setValue) {
            const newCity = cities?.find((city) => city.id == value);
            setValue({ ...newCity, postalCode: newCity?.postal_code });
        } // Propage la valeur sélectionnée vers le composant parent si une fonction de rappel est fournie
    };

    const handleClickReset = () => {
        setValue(null);
        setSelectedValue();
    };

    return (
        <div className='flex flex-row'>
            <Select
                showSearch={true}
                placeholder='Rechercher code postal ou ville'
                onSearch={handleSearch}
                onPopupScroll={handlePopupScroll}
                filterOption={false}
                notFoundContent={loading ? <Spin size='small' /> : null}
                value={
                    defaultValue?.id
                        ? `${defaultValue?.postalCode} - ${defaultValue?.name}`
                        : selectedValue
                }
                onChange={handleSelectChange}
                className={`select-customize-input ${
                    readOnly ? 'pointer-events-none' : ''
                }`} //désactiver le fonctionnement du select quand readOnly est true
                disabled={disabled}
            >
                {options?.map((option) => (
                    <Option
                        key={`${option.id}-${option.postalCode}-${option.name}`}
                        value={option.id}
                    >
                        {`${option.postalCode} - ${option.name}`}
                    </Option>
                ))}
            </Select>

            {defaultValue?.id && (
                <div
                    className='cursor-pointer p-2'
                    onClick={() => handleClickReset()}
                >
                    x
                </div>
            )}
        </div>
    );
};

export default CityInput;
