import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { MdEdit } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../modUtils/components/buttons/Button';
import {
    AlertMessage,
    Label,
    Modal,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { sharing_by_equipment_by_group_update } from '../../../modSharedEquipment/slices/sharedEquipmentSlice';
import { Form, Input, DatePicker, Select } from 'antd';
import SubmitButton from '../../../modUtils/components/buttons/submitButton/SubmitButton';
import { IoIosAddCircleOutline } from 'react-icons/io';
import ConfirmationModal from '../../../modUtils/components/confirmationModal/ConfirmationModal';
import { AiFillDelete } from 'react-icons/ai';
import i18next from 'i18next';

export default function SharedEquipmentForm({
    equipment,
    fetchData,
    sharedEquipment,
    buttonText,
    edited = false,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);
    const [modalVisibleFormShared, setmodalVisibleFormShared] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false); // modal de confirmation
    const { permissionsList } = useSelector((state) => state.sharedEquipment);
    const [alertMessage, setAlertMessage] = useState(null);
    const [form] = Form.useForm();
    const [dateErrors, setDateErrors] = useState([]);

    const [permissionRows, setPermissionRows] = useState([
        { permission: null, expirationDate: null, checked: true },
    ]);

    const onFinish = async (values) => {
        // Préparer les permissions partagées
        const sharedPermissions = permissionRows.map((perm) => ({
            permission: perm.permission,
            expiration_date: !perm.checked
                ? perm.expirationDate
                    ? Math.floor(perm.expirationDate.unix())
                    : null
                : null,
        }));

        const response = await dispatch(
            sharing_by_equipment_by_group_update({
                equipmentId: equipment?.id,
                groupId: groupId,
                group: values.group
                    ? values.group
                    : sharedEquipment?.group?.name,
                shared_permissions: sharedPermissions,
            }),
        );

        if (!response?.payload) {
            setAlertMessage({
                detail: 'Un problème est survenu.',
                status: 'error',
            });
        } else if (response?.payload?.status === 'error') {
            setAlertMessage(response?.payload);
        } else {
            fetchData();
            closeModalFormShared();
            form.resetFields();
            setPermissionRows([
                { permission: null, expirationDate: null, checked: true },
            ]);
        }
    };

    const openModalFormShared = () => {
        setmodalVisibleFormShared(true);
    };

    const closeModalFormShared = () => {
        setmodalVisibleFormShared(false);
        setAlertMessage(null);
        form.resetFields();
        setPermissionRows([
            { permission: null, expirationDate: null, checked: true },
        ]);
    };

    const handleAddRow = () => {
        setPermissionRows([
            ...permissionRows,
            { permission: null, expirationDate: null, checked: true },
        ]);
    };

    const handlePermissionChange = (index, value) => {
        const updatedRows = [...permissionRows];
        updatedRows[index].permission = value;
        setPermissionRows(updatedRows);

        // Récupère les permissions actuelles
        const currentPermissions =
            form.getFieldValue('sharedPermissions') || [];

        // Vérifie si la permission existe déjà
        const updatedPermissions = currentPermissions.filter(Boolean); // Filtre les valeurs nulles ou indéfinies

        // Ajoute la nouvelle permission si elle n'existe pas déjà
        if (!updatedPermissions.some((perm) => perm.permission === value)) {
            updatedPermissions.push({ permission: value });
        }

        form.setFieldsValue({
            [`sharedPermissions[${index}]`]: value,
        });
    };

    const handleDateChange = (index, date) => {
        const updatedRows = [...permissionRows];
        updatedRows[index].expirationDate = date ? dayjs(date) : null;
        setPermissionRows(updatedRows);

        if (date) {
            if (dayjs(date).isAfter(dayjs())) {
                const newDate = Math.floor(date.valueOf() / 1000);
                // Supprime l'erreur pour cet index
                setDateErrors((prevErrors) => {
                    const updatedErrors = [...prevErrors];
                    updatedErrors[index] = null; // Pas d'erreur
                    return updatedErrors;
                });
            } else {
                // Définit l'erreur pour cet index
                setDateErrors((prevErrors) => {
                    const updatedErrors = [...prevErrors];
                    updatedErrors[index] = t(
                        'sharedEquipmentForm.theDateMustBeLater',
                    );
                    return updatedErrors;
                });
            }
        } else {
            // Si aucune date n'est sélectionnée, vous pouvez également gérer cela
            setDateErrors((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = null; // Pas d'erreur
                return updatedErrors;
            });
        }
    };

    const handleCheckboxChange = (index) => {
        const updatedRows = [...permissionRows];

        // Inversez la valeur de la case à cocher
        updatedRows[index].checked = !updatedRows[index].checked;

        // Si la case est décochée, réinitialisez la date
        if (!updatedRows[index].checked) {
            updatedRows[index].expirationDate = null; // Réinitialiser la date si la case est décochée
            setDateErrors((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = null; // Pas d'erreur pour cette date
                return updatedErrors;
            });
        }

        setPermissionRows(updatedRows);
    };

    // Soumet le formulaire
    const handleConfirm = (form) => {
        const values = form.getFieldValue();
        setIsModalVisible(false); // Cacher le modal
        onFinish(values); // Soumettre le formulaire avec les valeurs stockées
    };

    // annule la confirmation
    const handleCancel = () => {
        setIsModalVisible(false); // Cacher le modal sans soumettre le formulaire
    };

    const handleValidation = () => {
        form.validateFields()
            .then(() => {
                setIsModalVisible(true); // Affiche la modale si le formulaire est valide
            })
            .catch((errorInfo) => {
                // console.log("Validation échouée:", errorInfo);
            });
    };

    useEffect(() => {
        if (edited && sharedEquipment?.shared_permissions) {
            const initialPermissions = sharedEquipment.shared_permissions.map(
                (permission) => ({
                    permission: `${permission.permission.app_name}:${permission.permission.code_name}`,
                    expirationDate: permission.expiration_date
                        ? dayjs.unix(permission.expiration_date)
                        : null,
                    checked: !permission.expiration_date,
                }),
            );

            setPermissionRows(initialPermissions);

            initialPermissions.forEach((perm, index) => {
                form.setFieldsValue({
                    [`sharedPermissions[${index}]`]: perm.permission,
                    [`expirationDate[${index}]`]: perm.expirationDate,
                });
            });
        }
    }, [modalVisibleFormShared, i18next.language]);

    const handleRemoveRow = (index) => {
        // Supprime la ligne d'index spécifié de permissionRows
        const updatedRows = [...permissionRows];
        updatedRows.splice(index, 1);
        setPermissionRows(updatedRows);

        // Réinitialise la valeur du formulaire pour la ligne supprimée
        form.setFieldsValue({
            [`sharedPermissions[${index}]`]: null,
            [`expirationDate[${index}]`]: null,
        });
    };

    return (
        <>
            {edited ? (
                <MdEdit
                    size={20}
                    className='text-primary-color cursor-pointer'
                    onClick={openModalFormShared}
                />
            ) : (
                <Button
                    onClick={openModalFormShared}
                    color='primary'
                    className='!text-xs'
                >
                    {buttonText}
                </Button>
            )}

            <Modal
                title={t('sharedEquipmentForm.createEditEquipmentShare')}
                isOpen={modalVisibleFormShared}
                onClose={closeModalFormShared}
                width={700}
                height={600}
                zIndex={1000}
            >
                <Form form={form} onFinish={onFinish} layout='vertical'>
                    {sharedEquipment?.group?.name ? (
                        <>
                            <Label
                                label={t('sharedEquipmentForm.companyName')}
                            />
                            <p>{sharedEquipment?.group?.name}</p>
                        </>
                    ) : (
                        <Form.Item
                            label={t('sharedEquipmentForm.companyName')}
                            name='group'
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'sharedEquipmentForm.theCompanyNameIsAEequired',
                                    ),
                                },
                                {
                                    min: 4,
                                    message: t(
                                        'sharedEquipmentForm.pleaseEnterMoreCharacters',
                                    ),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    )}

                    {permissionRows.map((row, index) => (
                        <div
                            key={index}
                            className='flex items-center justify-center mb-4'
                        >
                            <div className='w-[250px]'>
                                <Form.Item
                                    name={`sharedPermissions[${index}]`}
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'sharedEquipmentForm.pleaseSelectPermission',
                                            ),
                                        },
                                    ]}
                                >
                                    <label className='block mb-[4px]'>
                                        {t(
                                            'sharedEquipmentForm.selectPermission',
                                        )}
                                    </label>

                                    <Select
                                        className='w-full'
                                        value={row.permission || undefined}
                                        onChange={(value) => {
                                            handlePermissionChange(
                                                index,
                                                value,
                                            );
                                            form.setFieldsValue({
                                                [`sharedPermissions[${index}]`]:
                                                    value,
                                            });
                                        }}
                                    >
                                        {permissionsList.map((permission) => (
                                            <Select.Option
                                                key={`${permission.app_name}:${permission.code_name}`}
                                                value={`${permission.app_name}:${permission.code_name}`}
                                            >
                                                {permission.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className='ml-4 w-[250px]'>
                                <div>
                                    <input
                                        type='checkbox'
                                        checked={row.checked}
                                        onChange={() =>
                                            handleCheckboxChange(index)
                                        }
                                    />
                                    <Label
                                        label={t(
                                            'sharedEquipmentForm.noExpiration',
                                        )}
                                    />
                                </div>

                                {!row.checked && (
                                    <Form.Item
                                        name={`expirationDate[${index}]`}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'sharedEquipmentForm.pleaseSelectExpirationDate',
                                                ),
                                            },
                                            {
                                                validator: (_, value) => {
                                                    if (dateErrors[index]) {
                                                        return Promise.reject(
                                                            new Error(
                                                                dateErrors[
                                                                    index
                                                                ],
                                                            ),
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            format='YYYY-MM-DD'
                                            onChange={(date) =>
                                                handleDateChange(index, date)
                                            }
                                            style={{ width: '100%' }}
                                            value={dayjs(row.expirationDate)}
                                        />
                                    </Form.Item>
                                )}
                            </div>

                            {index > 0 ? (
                                <AiFillDelete
                                    onClick={() => handleRemoveRow(index)}
                                    className='cursor-pointer ml-4 text-red-500 w-5 h-5 my-auto'
                                />
                            ) : (
                                <div className='ml-4 w-5 h-5'></div>
                            )}
                        </div>
                    ))}

                    {permissionRows.length < permissionsList?.length && (
                        <IoIosAddCircleOutline
                            onClick={handleAddRow}
                            className='w-[50px] h-[50px] cursor-pointer'
                        />
                    )}

                    <AlertMessage
                        statusMessage={alertMessage?.status}
                        defaultMessage={alertMessage?.detail}
                    />

                    <Form.Item>
                        <div className='flex items-center'>
                            {edited ? (
                                <Button
                                    color='success'
                                    onClick={handleValidation}
                                    className='m-auto'
                                >
                                    VALIDER
                                </Button>
                            ) : (
                                <SubmitButton
                                    label={t('sharedEquipmentForm.validate')}
                                    className='m-auto'
                                />
                            )}
                        </div>

                        <ConfirmationModal
                            onOk={() => handleConfirm(form)}
                            onCancel={handleCancel}
                            visible={isModalVisible}
                            content={t(
                                'sharedEquipmentForm.confirmEquipmentSharingChange',
                            )}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
