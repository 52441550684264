import React from 'react';
import Button from './Button';

interface ButtonLoginWithDemoAccountProps {
    onClick: (values: { email: string; password: string }) => void;
    className?: string;
    children?: React.ReactNode;
}

const ButtonLoginWithDemoAccount: React.FC<ButtonLoginWithDemoAccountProps> = ({
    onClick,
    className = '',
    children,
}) => {
    const loginWithDemoAccount = () => {
        const values = {
            email: 'demo@cksquare.fr',
            password: 'DEMO_cksquare1',
        };
        onClick(values);
    };

    return (
        <Button
            color='dark'
            size='sm'
            className={className}
            onClick={loginWithDemoAccount}
        >
            {children}
        </Button>
    );
};

export default ButtonLoginWithDemoAccount;
