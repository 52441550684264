import moment from 'moment';
import {
    create_entity_customers,
    update_entity_customer_detail,
    create_entity_companies,
    update_entity_company_detail,
} from '../slices/entitySlice';
import React, { useState, useEffect } from 'react';
import { Form, Input, Select, message } from 'antd';
import { useTranslation } from 'react-i18next';
import EntityFormStatus from './EntityFormStatus';
import { useDispatch, useSelector } from 'react-redux';
import FormLegend from '../../modUtils/components/FormLegend';
import Button from '../../modUtils/components/buttons/Button';
import CityInput from '../../modLocation/components/CityInput';
import AlertMessage from '../../modUtils/components/AlertMessage';
import DismissButton from '../../modUtils/components/buttons/DismissButton';
import SelectEntityParent from './SelectEntityParent/SelectEntityParent';
import DatePicker from '../../modUtils/components/componentsLibrary/datePicker/DatePicker';
import ConfirmationModal from '../../modUtils/components/confirmationModal/ConfirmationModal';
import { Spinner } from '../../modUtils/components/componentsLibrary/componentsLibrary';
import SubmitButton from '../../modUtils/components/buttons/submitButton/SubmitButton';
import i18next from 'i18next';

export default function EntityCustomerCompanyForm({
    entity,
    supportId,
    closeModal,
    getEntities,
    entityParent,
    showSelectEntityType,
    fetchUpdateEntityCustomer,
    fetchUpdateEntityCompany,
}) {
    const isEdit = !!entity;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const formatDate = 'DD/MM/YYYY';
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [errorEmail, setErrorEmail] = useState(null);
    const [selectedCity, setSelectedCity] = useState();
    const [formFieldValue, setFormFieldValue] = useState();
    const [selectedDate, setSelectedDate] = useState(null);
    const { groupId } = useSelector((state) => state.manager);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [entityStatus, setEntityStatus] = useState(entity?.status);
    const [selectedParentEntityId, setSelectedParentEntityId] = useState();
    const [statusSelectedOption, setStatusSelectedOption] = useState(null);
    const { entityCompanyDetail, entityCustomerDetail, entityDetail } =
        useSelector((state) => state.entity);
    const entityTypeOptions = [
        {
            label: t('entityCustomerCompanyForm.company'),
            value: 'EntityCompany',
        },
        {
            label: t('entityCustomerCompanyForm.userIndividual'),
            value: 'EntityCustomer',
        },
    ];
    const [entityType, setEntityType] = useState(
        entity?.entity_type || entityTypeOptions[1].value,
    );

    const handleSelect = (value) => {
        setStatusSelectedOption(value);
        form.setFieldsValue({ status: value });
    };

    useEffect(() => {
        setEntityType(entity?.entity_type || entityTypeOptions[1].value);
        setStatusSelectedOption(entityDetail?.status);
        setEntityStatus(entityDetail?.status);
        getFormSetFieldsValue();
    }, [groupId, entity, entityDetail]);

    // Utilisation de useEffect pour écouter les changements de errorEmail
    useEffect(() => {
        // Met à jour le champ email avec l'erreur lorsqu'il y a un problème
        form.setFields([
            {
                name: 'email',
                errors: [errorEmail],
            },
        ]);
    }, [errorEmail, form]);

    const getFormSetFieldsValue = () => {
        form.resetFields();
        if (entity) {
            const entityDetailGroup =
                entityDetail?.entity_customer || entityDetail?.entity_company;

            setSelectedCity({
                id: entityDetailGroup?.location_address?.city_id,
                postalCode: entityDetailGroup?.location_address?.zipcode,
                name: entityDetailGroup?.location_address?.city,
            });

            const birthDate = entityDetailGroup?.date_of_birth
                ? moment.unix(entityDetailGroup?.date_of_birth)
                : null;

            setSelectedDate(birthDate);

            form.setFieldsValue({
                status: isEdit && entityDetailGroup?.status,
                entityType: entityDetailGroup?.entity_type || null,
                name: entityDetailGroup?.name,
                firstName: entityDetailGroup?.first_name || '',
                siret: entityDetailGroup?.siret || '',
                locationNumber:
                    entityDetailGroup?.location_address?.street_number || '',
                locationStreet:
                    entityDetailGroup?.location_address?.street || '',
                locationCity:
                    entityDetailGroup?.location_address?.city_id || null,
                email: entityDetailGroup?.email || '',
                phoneMobile: entityDetailGroup?.phone_mobile || '',
                dateOfBirth: birthDate,
                entity: entityDetailGroup?.parent?.id || '',
            });
            setFormFieldValue(form.getFieldsValue());
        }
    };

    useEffect(() => {
        getFormSetFieldsValue();
    }, [
        entityCompanyDetail,
        entityCustomerDetail,
        entity,
        form,
        entityDetail,
        i18next.language,
    ]);

    // ajouter une entité
    const addNewEntity = async (values, type) => {
        let promise = null;
        switch (type) {
            case 'EntityCompany':
                promise = create_entity_companies({ groupId, ...values });
                break;
            case 'EntityCustomer':
                promise = create_entity_customers({
                    groupId,
                    ...values,
                    entity: entityParent,
                });
                break;
        }
        if (promise !== null) {
            try {
                await dispatch(promise).unwrap();
                getEntities();

                setErrorEmail(null);
                closeModal && closeModal();
            } catch (error) {
                if (error?.status === 400) {
                    setErrorEmail(error?.data?.detail.email);
                } else {
                    message.error({
                        content: (
                            <AlertMessage
                                status={error?.status}
                                alertMessage={`Une erreur est survenue lors de l'ajout d'un nouveau client !`}
                                errorDetail={error?.data?.detail}
                            />
                        ),
                    });
                }
            }
        }
    };

    // modifier les informations de l'entité
    const updateEntity = async (values, type) => {
        try {
            setLoading(true);
            if (type === 'EntityCompany') {
                fetchUpdateEntityCompany(entity?.id, values);
            } else if (type === 'EntityCustomer') {
                fetchUpdateEntityCustomer(
                    entity?.id,
                    selectedParentEntityId,
                    values,
                );
            }
            setStatusSelectedOption(entityDetail?.status);
        } catch (error) {
            if (
                error?.status === 400 &&
                error?.data?.detail.includes('UNIQUE constraint failed')
            ) {
                setErrorEmail('Adresse email deja utilisé.');
            } else {
                message.error({
                    content: (
                        <AlertMessage
                            status={error?.status}
                            alertMessage={`Une erreur est survenue lors de la modification d'un client !`}
                            errorDetail={error?.data?.detail}
                        />
                    ),
                });
            }
        } finally {
            setLoading(false);
            closeModal && closeModal();
        }
    };

    // Fonction pour comparer deux objets et extraire les différences
    const getDifferences = (objInitial, objToCompare) => {
        const differences = {};
        if (objToCompare) {
            Object.keys(objInitial).forEach((key) => {
                if (objInitial[key] !== objToCompare[key]) {
                    differences[key] = objInitial[key];
                }
            });
            return differences;
        } else {
            return objInitial;
        }
    };

    // onFinish appelée lors du submit du formulaire
    const onFinish = (values) => {
        setEntityStatus(values.status);

        const formattedValues = {
            ...values,
            locationCity: selectedCity?.id || null,
        };

        const formattedValuesWithDate = {
            ...values,
            locationCity: selectedCity?.id || null,
            dateOfBirth:
                values?.dateOfBirth !== formFieldValue?.dateOfBirth
                    ? values?.dateOfBirth
                        ? Math.floor(values?.dateOfBirth.valueOf() / 1000)
                        : null
                    : values?.dateOfBirth,
            entity:
                selectedParentEntityId || selectedParentEntityId === null
                    ? selectedParentEntityId
                    : values.entity,
        };

        setSelectedParentEntityId(
            selectedParentEntityId || selectedParentEntityId === null
                ? selectedParentEntityId
                : values.entity,
        );

        // Comparer formFieldValue avec formFieldValue pour obtenir les différences
        const formFieldDifferences = getDifferences(
            formattedValues,
            formFieldValue,
        );

        // Comparer formattedValuesWithDate avec formFieldValue pour obtenir les différences
        const formattedValuesDifferences = getDifferences(
            formattedValuesWithDate,
            formFieldValue,
        );

        if (
            Object.keys(formFieldDifferences).length !== 0 ||
            Object.keys(formattedValuesDifferences).length !== 0 ||
            closeModal
        ) {
            if (entity) {
                if (entityType === 'EntityCompany') {
                    updateEntity(formFieldDifferences, entityType);
                } else if (entityType === 'EntityCustomer') {
                    updateEntity(formattedValuesDifferences, entityType);
                }
            } else {
                if (entityType === 'EntityCompany') {
                    addNewEntity(formFieldDifferences, entityType);
                } else if (entityType === 'EntityCustomer') {
                    addNewEntity(formattedValuesDifferences, entityType);
                }
            }
        }
    };

    const handleResetForm = () => {
        getFormSetFieldsValue();
        closeModal && closeModal();
    };

    // soumission du formulaire
    const handleFormSubmit = (values) => {
        if (!closeModal && entityStatus !== values.status) {
            setFormValues(values); // Stocker les valeurs du formulaire temporairement
            setIsModalVisible(true); // Afficher le modal de confirmation
        } else {
            onFinish(values); // Soumettre le formulaire
        }
    };

    // Soumet le formulaire
    const handleConfirm = () => {
        setIsModalVisible(false); // Cacher le modal
        onFinish(formValues); // Soumettre le formulaire avec les valeurs stockées
    };

    // annule la confirmation
    const handleCancel = () => {
        setIsModalVisible(false); // Cacher le modal sans soumettre le formulaire
    };

    const handleDateChange = (date) => {
        const momentDate = moment(date, formatDate, true); // Spécifie le format et vérifie la strictesse
        const unixTimestamp = Math.floor(momentDate.valueOf() / 1000); // Convertit en timestamp Unix
        setSelectedDate(momentDate); // Met à jour l'état avec l'objet moment
        form.setFieldsValue({ dateOfBirth: momentDate }); // Met à jour le formulaire avec l'objet moment
    };

    // Efface les erreurs du champ quand l'utilisateur change la valeur
    const handleEmailChange = () => {
        setErrorEmail(null);
        form.setFields([
            {
                name: 'email',
                errors: [], // Supprime l'erreur manuellement quand l'utilisateur modifie l'email
            },
        ]);
    };

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {showSelectEntityType && !entityParent && (
                        <Form.Item
                            label={t('entityCustomerCompanyForm.entityType')}
                            name='entityType'
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'entityCustomerCompanyForm.pleaseChooseAnEntityType',
                                    ),
                                },
                            ]}
                        >
                            <Select
                                placeholder={t(
                                    'entityCustomerCompanyForm.chooseAType',
                                )}
                                onChange={setEntityType}
                                defaultValue={entityType}
                                disabled={entity}
                            >
                                {entityTypeOptions?.map((type) => (
                                    <Option value={type?.value}>
                                        {type?.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    <Form
                        form={form}
                        onFinish={handleFormSubmit}
                        layout='vertical'
                    >
                        {/* status */}
                        {isEdit && (
                            <EntityFormStatus
                                handleSelect={handleSelect}
                                statusSelectedOption={statusSelectedOption}
                            />
                        )}

                        {entityType === 'EntityCustomer' && (
                            <div className='grid grid-cols-1 xl:grid-cols-2 xl:gap-2'>
                                {/* name */}
                                <Form.Item
                                    label={t('entityCustomerCompanyForm.name')}
                                    name='name'
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'entityCustomerCompanyForm.pleaseEnterCustomerName',
                                            ),
                                        },
                                    ]}
                                >
                                    <Input placeholder='' />
                                </Form.Item>

                                {/* firstName */}
                                <Form.Item
                                    label={t(
                                        'entityCustomerCompanyForm.firstName',
                                    )}
                                    name='firstName'
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'entityCustomerCompanyForm.pleaseEnterCustomerFirstName',
                                            ),
                                        },
                                    ]}
                                >
                                    <Input placeholder='' />
                                </Form.Item>
                            </div>
                        )}

                        {entityType === 'EntityCompany' && (
                            <div className='grid grid-cols-1 xl:grid-cols-2 xl:gap-2'>
                                {/* name */}
                                <Form.Item
                                    label={t(
                                        'entityCustomerCompanyForm.companyName',
                                    )}
                                    name='name'
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'entityCustomerCompanyForm.pleaseEnterCompanyName',
                                            ),
                                        },
                                    ]}
                                >
                                    <Input placeholder='' />
                                </Form.Item>

                                {/* siret */}
                                <Form.Item
                                    label={t('entityCustomerCompanyForm.siret')}
                                    name='siret'
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'entityCustomerCompanyForm.pleaseEnterTheSiret',
                                            ),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t(
                                            'entityCustomerCompanyForm.siret',
                                        )}
                                    />
                                </Form.Item>
                            </div>
                        )}

                        <div className='grid grid-cols-4 gap-0 lg:gap-2'>
                            {/* locationNumber */}
                            <div className='col-span-4 xl:col-span-2 2xl:col-span-1'>
                                <Form.Item
                                    label={t(
                                        'entityCustomerCompanyForm.addressNumber',
                                    )}
                                    name='locationNumber'
                                >
                                    <Input type='number' placeholder='' />
                                </Form.Item>
                            </div>

                            {/* locationStreet */}
                            <div className='col-span-4 xl:col-span-2 2xl:col-span-3'>
                                <Form.Item
                                    label={t(
                                        'entityCustomerCompanyForm.street',
                                    )}
                                    name='locationStreet'
                                >
                                    <Input placeholder='' />
                                </Form.Item>
                            </div>
                        </div>

                        <div className='grid grid-cols-1 xl:grid-cols-2 gap-0 xl:gap-2'>
                            {/* locationCity */}
                            <Form.Item
                                label={t(
                                    'entityCustomerCompanyForm.postalCodeCity',
                                )}
                                name='locationCity'
                            >
                                <CityInput
                                    defaultValue={selectedCity}
                                    setValue={setSelectedCity}
                                />
                            </Form.Item>
                        </div>

                        <div className='grid grid-cols-1 xl:grid-cols-2 xl:gap-2'>
                            {/* email */}
                            <Form.Item
                                label={t('entityCustomerCompanyForm.email')}
                                name='email'
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'entityCustomerCompanyForm.pleaseEnterEmail',
                                        ),
                                    },
                                    {
                                        type: 'email',
                                        message: t(
                                            'entityCustomerCompanyForm.pleaseEnterValidEmail',
                                        ),
                                    },
                                    {
                                        // Validation personnalisée avec errorEmail
                                        validator: (_, value) => {
                                            if (errorEmail) {
                                                return Promise.reject(
                                                    new Error(errorEmail),
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    type='email'
                                    placeholder={t(
                                        'entityCustomerCompanyForm.email',
                                    )}
                                    onChange={handleEmailChange}
                                />
                            </Form.Item>

                            {/* phoneMobile */}
                            {entityType === 'EntityCustomer' && (
                                <Form.Item
                                    label={t(
                                        'entityCustomerCompanyForm.telephoneNumber',
                                    )}
                                    name='phoneMobile'
                                >
                                    <Input
                                        type='number'
                                        placeholder='0758964123'
                                    />
                                </Form.Item>
                            )}
                        </div>

                        <div className='flex flex-row justify-between items-center'>
                            {/* birthDate */}
                            {entityType === 'EntityCustomer' && (
                                <div className='flex flex-col items-start'>
                                    <Form.Item name='dateOfBirth'>
                                        <DatePicker
                                            label={t(
                                                'entityCustomerCompanyForm.dateOfBirth',
                                            )}
                                            format={formatDate}
                                            value={form.getFieldValue(
                                                'dateOfBirth',
                                            )}
                                            selectedDate={selectedDate}
                                            onDateChange={(date) =>
                                                handleDateChange(date)
                                            }
                                            color='neutral'
                                        />
                                    </Form.Item>
                                </div>
                            )}
                        </div>

                        {!showSelectEntityType && (
                            <div className='maw-w-[300px]'>
                                {/* entity user */}
                                <p className='text-left'>
                                    {t(
                                        'entityCustomerCompanyForm.associatedUser',
                                    )}
                                    {entity?.user
                                        ? entity?.user
                                        : t('entityCustomerCompanyForm.none')}
                                </p>

                                {/* entity Entité parente */}
                                {entityType === 'EntityCustomer' && (
                                    <Form.Item
                                        label={t('entityGroupForm.FormLabel')}
                                        name='entity'
                                    >
                                        <SelectEntityParent
                                            entity={entity}
                                            setSelected={
                                                setSelectedParentEntityId
                                            }
                                            entityDetail={entityDetail}
                                        />
                                    </Form.Item>
                                )}
                            </div>
                        )}

                        <FormLegend requiredFormItemLegend={true} />

                        {/* button submit */}
                        <div className='flex flex-row justify-center gap-2 mt-2'>
                            <Form.Item>
                                <DismissButton
                                    onClick={() => handleResetForm()}
                                />
                            </Form.Item>

                            <Form.Item>
                                <SubmitButton
                                    label={
                                        isEdit
                                            ? t(
                                                  'entityCustomerCompanyForm.save',
                                              )
                                            : t('entityCustomerCompanyForm.add')
                                    }
                                    className='h-[42px]'
                                />
                            </Form.Item>

                            <ConfirmationModal
                                onOk={handleConfirm}
                                onCancel={handleCancel}
                                visible={isModalVisible}
                                content={t(
                                    'entityCustomerCompanyForm.areYouSureChangeStatus',
                                )}
                            />
                        </div>
                    </Form>
                </>
            )}
        </>
    );
}
