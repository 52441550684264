import axios from 'axios';
import {
    getAccessToken,
    getRefreshToken,
    refreshAuthToken,
} from '../functions/handleTokens';

// createHttpInstance permet de créer une nouvelle instance d’Axios avec une configuration spécifique
const createHttpInstance = (url, headers) => {
    const instance = axios.create({
        baseURL: url,
        headers: headers,
    });

    // Request interceptor
    instance.interceptors.request.use(
        (config) => {
            // add token to headers
            const accessToken = getAccessToken();
            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            }
            return config;
        },
        (error) => {
            // Handle request error
            return Promise.reject(error);
        },
    );

    // Response interceptor
    instance.interceptors.response.use(
        (response) => {
            // normalize the data or handle common response errors
            return response;
        },
        async (error) => {
            // Handle response error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const status = error.response.status;
                switch (status) {
                    case 400:
                        console.error('Bad Request:', error.response.data);
                        break;
                    case 401:
                        // Si la réponse a un statut 401 (Non autorisé), rafraîchissez le token
                        let accessToken = getAccessToken();
                        let refreshToken = getRefreshToken();
                        if (accessToken && refreshToken) {
                            // S'assurer que l'utilisateur est déjà connecté
                            try {
                                await refreshAuthToken();
                                // Rée appeler la requête après le rafraîchissement du token
                                return instance(error.config);
                            } catch (refreshError) {
                                // Gérer les erreurs de rafraîchissement du token
                                return Promise.reject(refreshError);
                            }
                        }
                        break;
                    case 404:
                        console.error('Not Found:', error.response.data);
                        break;
                    case 500:
                        window.location.href = `${process.env.PUBLIC_URL}/error`;
                        console.error(
                            'Internal Server Error:',
                            error.response.data,
                        );
                        break;
                    // Add more cases as needed

                    default:
                        console.error(`Unhandled Status Code: ${status}`);
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Request error:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error:', error.message);
            }

            // Dispatch an action or handle the error as needed
            // store.dispatch(handleError(error));

            //rethrow the error to propagate it to the component that made the request
            return Promise.reject(error);
        },
    );

    // Configurer l'intervalle de rafraîchissement du token toutes les 15 minutes
    setInterval(refreshAuthToken, 15 * 60 * 1000);
    return instance;
};

export default createHttpInstance;
