import React, { FC } from 'react';
import { ReactComponent as AuthenticationSvg } from '../../assets/images/authentication.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface PermissionDeniedPageProps {
    direction: string;
    closeModal: () => void;
    permission: boolean;
}

const PermissionDeniedPage: FC<PermissionDeniedPageProps> = ({
    direction,
    closeModal,
    permission,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function redirectRoutePermission() {
        if (closeModal) {
            closeModal();
        }
        navigate('/plans');
    }

    return (
        <div
            className={`text-center mx-auto flex items-center h-full justify-center
                ${direction == 'row' ? 'flex-row' : 'flex-col'}
            `}
        >
            <div className='w-full'>
                <AuthenticationSvg className={'max-w-sm'} />
            </div>

            <div className='flex-col'>
                <h2
                    className={`text-xl font-bold tracking-tight text-gray-900`}
                >
                    {permission
                        ? String(t('permissionDeniedPage.titlePermission'))
                        : String(t('permissionDeniedPage.titlePlan'))}
                </h2>

                <p className='text-lg text-gray-500'>
                    {permission
                        ? String(t('permissionDeniedPage.textPermission'))
                        : String(t('permissionDeniedPage.textPlan'))}
                </p>

                <button
                    onClick={() => redirectRoutePermission()}
                    className={`bg-primary-color text-lg text-white border-none font-bold px-4 py-2 mt-2
                        rounded cursor-pointer shadow-xl items-center hover:bg-primary-color-600
                    `}
                >
                    <span>{String(t('permissionDeniedPage.buttonPlan'))}</span>
                </button>
            </div>
        </div>
    );
};

export default PermissionDeniedPage;
