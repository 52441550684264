import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import EntityCustomerCompanyForm from './EntityCustomerCompanyForm';
import { Modal } from '../../modUtils/components/componentsLibrary/componentsLibrary';
import ButtonAdd from '../../modUtils/components/buttons/ButtonAdd';

export default function AddEntityCustomerCompanyModal({
    closeMenu,
    supportId,
    entityParent,
    getEntities,
    type = 'text',
}) {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState(false);

    const openModal = () => {
        if (typeof closeMenu === 'function') {
            closeMenu();
        }
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    return (
        <>
            {type !== 'button' ? (
                <p onClick={openModal}>
                    {t('addEntityCustomerCompanyModal.createCustomer')}
                </p>
            ) : (
                <ButtonAdd
                    label={t('addEntityCustomerCompanyModal.addCompanyUser')}
                    onClick={openModal}
                />
            )}

            <Modal
                title={t('addEntityCustomerCompanyModal.creationCustomer')}
                isOpen={modalVisible}
                onClose={() => setModalVisible(false)}
                zIndex={1001}
                style={{
                    modalContent: {
                        minWidth: '500px',
                        width: '40%',
                    },
                }}
            >
                {modalVisible && (
                    <EntityCustomerCompanyForm
                        showSelectEntityType={true}
                        closeModal={handleCancel}
                        supportId={supportId}
                        entityParent={entityParent}
                        getEntities={getEntities}
                    />
                )}
            </Modal>
        </>
    );
}
