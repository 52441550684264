import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';
export default function Button({
    htmltype,
    type,
    disabled,
    onClick,
    href,
    block,
    round,
    width,
    key,
    color,
    style = {},
    className = {},
    ...props
}) {
    return (
        <button
            id={key}
            className={`${type !== 'image' ? 'btn' : ''} btn-${type} ${block && 'btn-block'} ${round && 'btn-round'} ${className}`}
            type={htmltype}
            onClick={onClick}
            href={href}
            disabled={disabled}
            style={{ width: width, ...style }}
        >
            {props.children} {/** a voir disabled pour button image */}
        </button>
    );
}

Button.defaultProps = {
    disabled: false,
    block: false,
    type: 'default',
};

Button.propTypes = {
    htmltype: PropTypes.oneOf(['submit', 'reset', 'button']), //définit le type html original du bouton [submit, reset, button]
    type: PropTypes.oneOf(['default', 'primary', 'text', 'image']), //définie le type du bouton
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    block: PropTypes.bool, // le bouton remplisse toute la largeur de l'élément conteneur (élement parent)
    href: PropTypes.string,
};
