import React from 'react';
import { Route } from 'react-router-dom';
import BadgesHome from '../pages/badgesAndKeys/badgesHome/BadgesHome';
import BadgesStatistics from '../pages/badgesAndKeys/statistics/BadgesStatistics';
import SubscriptionBadges from '../pages/badgesAndKeys/subscriptionBadges/SubscriptionBadges';
import CkRoutes from 'ck_commun/src/app/modUtils/routes/CkRoutes';

export default function BadgesAndKeysRoutes() {
    return (
        <CkRoutes>
            <Route path='dashboard' element={<BadgesHome />} />
            <Route path='statistics' element={<BadgesStatistics />} />
            <Route path='subscription' element={<SubscriptionBadges />} />
        </CkRoutes>
    );
}
