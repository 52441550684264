import React from 'react';
import { useTranslation } from 'react-i18next';
import { eEquipmentType } from 'ck_commun/src/commun_global/enums';
import FilterSection from './ExportFilterSection';
import { useSelector } from 'react-redux';

export default function ExportModalFilter({ selectForm }) {
    const { t } = useTranslation();
    const { sites } = useSelector((state) => state.manager);
    const convertSiteIdsToNames = (siteIds, sites) => {
        return siteIds
            .map((id) => sites.find((site) => site.id === id)?.name)
            .filter(Boolean);
    };
    // Data pour gerer tous les types de filtres, ce système n'est pas parfait car il traite tous les filtres ici pour tout type de données
    // exemple vente , télécollecte, relevés
    const filterData = {
        equipmentType: {
            label: t('exportData.transExportModalEquipmentType'),
            values:
                selectForm?.equipmentType?.length > 0
                    ? selectForm?.equipmentType?.map((type) =>
                          t(eEquipmentType[type]),
                      )
                    : ['Tous'],
        },

        siteListName: {
            label: t('exportData.transExportModalSite'),
            values:
                selectForm?.siteListName?.length > 0
                    ? convertSiteIdsToNames(selectForm.siteListName, sites)
                    : ['Tous'],
        },
        payment: {
            label: t('exportData.transExportModalPaymentGroup'),
            values: selectForm?.payment
                ? [selectForm.payment]
                : [t('exportData.transExportModalNone')],
        },

        // TODO AJOUTER TOUS LES TYPES DE FITRES DE RECHERCHE PAR NOM
        // ON NE LE GÈRE PAS ACTUELLEMENT
        // creditCard: {
        //   label: t("exportData.transExportModalBlueCard"),
        //   values: selectForm?.creditCard
        //     ? [selectForm.creditCard]
        //     : [t("exportData.transExportModalNone")],
        // },
        dateStart: {
            label: t('exportData.transExportModalDateStart'),
            values: [selectForm?.dateStart],
        },
        dateEnd: {
            label: t('exportData.transExportModalDateEnd'),
            values: [selectForm?.dateEnd],
        },
    };
    return (
        <>
            <h2>{t('exportData.transExportModalFilter')}</h2>
            {Object.entries(filterData)
                .filter(([key]) => key in selectForm) // Filtrer les clés présentes dans selectForm
                .map(([key, { label, values }]) => (
                    <div key={key}>
                        <FilterSection
                            key={key}
                            label={label}
                            values={values}
                        />
                    </div>
                ))}
        </>
    );
}
