/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/support/e_action_status.py
Aucune modification manuelle acceptée
*/

// Type des status action
const eActionStatus = Object.freeze({
  // En cours
  0: 'eActionStatus.inProgress',

  // Annulé
  1: 'eActionStatus.canceled',

  // Terminé
  2: 'eActionStatus.done',

  // Erreur
  3: 'eActionStatus.error',
});

export default eActionStatus;
