/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/support/e_support_format.py
Aucune modification manuelle acceptée
*/

// Format du support présenté. Ce format permet d'avoir différent type de support et
// de contenu en fonction de ces formats
const eSupportFormat = Object.freeze({
  // Badge lue par un badgic reader, il peut être autonome et contenir les informations
  // en local, ou être stocké sur le serveur.
  1: 'eSupportFormat.badgeMifare',

  // Qr Code
  2: 'eSupportFormat.qrCode',

  // Badge lue en long range par RFID. Il s'agit de badge collé en générale sur le parebrise type
  // télépéage.
  // ATTENTION. N'est pas prêt sur le serveur Python. Avant toute utilisation, il faut configurer
  // et tester les serveur Python
  3: 'eSupportFormat.badgeLongRange',
});

export default eSupportFormat;
