import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage';
import React from 'react';

export default function Promotions() {
    return (
        <div>
            <BuildingPage />
        </div>
    );
}
