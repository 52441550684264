import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGet, httpPut, httpPost } from '../../modUtils/apis/httpConfig';

//récupérer les machine par un groupeId lié à un siteId lié à un equipmentId
export const imachines_gb_machine_kinds_by_equipment_by_site_by_group_get =
    createAsyncThunk(
        'mod_imachine/gb_machine_kinds_by_equipment_by_site_by_group_get',
        async (values) => {
            const response = await httpGet(
                `/mod_imachine/groups/${values.groupId}/sites/${values.siteId}/equipments/${values.equipmentId}/machinekinds_machines/`,
                {
                    params: {
                        offset: 0,
                    },
                },
            );
            return [response.data.results];
        },
    );

// modifier une machine par l'id
export const machine_management_by_id_action = createAsyncThunk(
    'mod_imachine/machine_management_by_id_action',
    async (values) => {
        try {
            const response = await httpPost(
                `mod_imachine/groups/${values.groupId}/sites/${values.siteId}/equipments/${values.equipmentId}/machines_index/${values.machineId}/`,
                {
                    command: values.command,
                    count: values.count,
                    programId: values.programId,
                    value: values.value,
                    power: values.power,
                },
            );
            return response.data;
        } catch (error) {
            let messageError;
            if (error.response) {
                const status = error.response.status;
                switch (status) {
                    // case 400:
                    //     messageError = {status: false, detail: 'Format de la requête incorrecte'};
                    //     break;
                    // case 401:
                    //     messageError = {status: false, detail: "Authentification incorrecte (token d'accès expiré)"};
                    //     break;
                    // case 404:
                    //     messageError = {status: false, detail: 'Not found'};
                    //     break;
                    // case 500:
                    //     messageError = {status: false, detail: "Maintenance d'un site internet ou à une mise à jour"};
                    //     break;
                    case 504:
                        // console.log(`erreur: ${status}`);
                        // console.log(error.response.data);
                        messageError = {
                            status: false,
                            detail: "Impossible de se connecter à l'équipement",
                        };
                        break;
                    default:
                        messageError = {
                            status: false,
                            detail: 'Un problème est survenu',
                        };
                }
            } else {
                messageError = {
                    status: false,
                    detail: 'Un problème est survenu',
                };
            }
            return messageError;
        }
    },
);

// modifier une machine par l'index
export const machine_management_by_index_action = createAsyncThunk(
    'mod_imachine/machine_management_by_index_action',
    async (values) => {
        try {
            const response = await httpPost(
                `mod_imachine/groups/${values.groupId}/sites/${values.siteId}/equipments/${values.equipmentId}/machines_index/${values.machineIndex}/`,
                {
                    command: values.command,
                    count: values.count,
                    programId: values.programId,
                    value: values.value,
                    power: values.power,
                },
            );
            return response.data;
        } catch (error) {
            let messageError;
            if (error.response) {
                const status = error.response.status;
                switch (status) {
                    case 504:
                        messageError = {
                            status: false,
                            detail: "Impossible de se connecter à l'équipement",
                        };
                        break;
                    default:
                        messageError = {
                            status: false,
                            detail: 'Un problème est survenu',
                        };
                }
            } else {
                messageError = {
                    status: false,
                    detail: 'Un problème est survenu',
                };
            }
            return messageError;
        }
    },
);

// vider le message d'alert
export const delete_alert_message = createAsyncThunk(
    'mod_imachine/delete_alert_message',
    async () => {
        const response = [];
        return response.data;
    },
);

const machineSlice = createSlice({
    name: 'machine',
    initialState: {
        machines: [],
        messageAlert: [],
        loading: true,
    },
    extraReducers: (builder) => {
        builder
            // imachines_gb_machine_kinds_by_equipment_by_site_by_group_get : récupérer les machine par un groupeId lié à un siteId lié à un equipmentId
            .addCase(
                imachines_gb_machine_kinds_by_equipment_by_site_by_group_get.pending,
                (state, action) => {
                    state.machines = [];
                },
            )
            .addCase(
                imachines_gb_machine_kinds_by_equipment_by_site_by_group_get.fulfilled,
                (state, action) => {
                    state.machines = action.payload;
                    state.loading = false;
                },
            )
            .addCase(
                imachines_gb_machine_kinds_by_equipment_by_site_by_group_get.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            // mettre à jour une machine par l'id
            .addCase(
                machine_management_by_id_action.fulfilled,
                (state, action) => {
                    state.messageAlert = action.payload;
                    state.loading = false;
                },
            )
            .addCase(
                machine_management_by_id_action.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            // mettre à jour une machine par l'index
            .addCase(
                machine_management_by_index_action.fulfilled,
                (state, action) => {
                    state.messageAlert = action.payload;
                    state.loading = false;
                },
            )
            .addCase(
                machine_management_by_index_action.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            // vider le message d'alert
            .addCase(delete_alert_message.fulfilled, (state, action) => {
                state.messageAlert = action.payload;
                state.loading = false;
            })
            .addCase(delete_alert_message.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export default machineSlice.reducer;
