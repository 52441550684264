import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowRightOutlined } from '@ant-design/icons';
import Select from '../../../modUtils/components/select/Select';
import Button from '../../../modUtils/components/buttons/Button';
import OptionLoading from '../../../modUtils/components/select/OptionLoading';
import {
    InputSearch,
    Label,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import {
    shared_groups_paginated_get,
    shared_sites_paginated_get,
} from '../../slices/sharedEquipmentSlice';

export default function FormFilter({
    fetchDataSharedEquipments,
    setSerialNumber,
    setGroups,
    setSites,
    limit,
    setLoadingSearch,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { sharedGroups, sharedSites } = useSelector(
        (state) => state.sharedEquipment,
    );
    const [loading, setLoading] = useState(false);

    function handleClickSearchCompany() {
        const selectElementCompany = document.getElementById('searchCompany');
        const selectValueCompany = selectElementCompany.value;
        fetchDataSites(setLoading, selectValueCompany);
    }

    async function fetchDataSites(setLoading, groups) {
        setLoading(true);
        await dispatch(
            shared_sites_paginated_get({
                groups: groups && groups,
            }),
        );
        setLoading(false);
    }

    async function fetchDataGroups(setLoading) {
        setLoading(true);
        await dispatch(shared_groups_paginated_get({}));
        setLoading(false);
    }

    function onExecuteSubmitBtnFilter() {
        const inputElementSerialNumber =
            document.getElementById('searchSerialNumber');
        const inputValueSerialNumber = inputElementSerialNumber.value;

        if (inputValueSerialNumber.length > 0) {
            setSerialNumber(inputValueSerialNumber);
        } else {
            setSerialNumber(null);
        }

        const selectElementCompany = document.getElementById('searchCompany');
        const selectValueCompany = selectElementCompany.value;

        if (typeof selectValueCompany === 'string') {
            setGroups(selectValueCompany);
        } else {
            setGroups(null);
        }

        const selectElementSite = document.getElementById('searchSite');
        const selectValueSite = selectElementSite.value;

        if (typeof selectValueCompany === 'string') {
            setSites(selectValueSite);
        } else {
            setSites(null);
        }

        fetchDataSharedEquipments(
            limit,
            0,
            inputValueSerialNumber,
            selectValueCompany,
            selectValueSite,
            setLoadingSearch,
        );
    }

    return (
        <>
            <div className='flex flex-row items-start flex-wrap'>
                <div className='h-10 flex items-center mt-6 mr-2.5 mb-4'>
                    <p className='font-bold m-0'>
                        {t('sharedEquipmentFormFilter.filters')}
                    </p>
                </div>

                <div className='w-52 mr-2.5'>
                    <Select
                        id='searchCompany'
                        label={t('sharedEquipmentFormFilter.company')}
                        options={sharedGroups}
                        onClick={() => handleClickSearchCompany()}
                        fetchAPI={(setLoading) => fetchDataGroups(setLoading)}
                    />
                </div>

                <div className='w-52 mr-2.5'>
                    {loading ? (
                        <>
                            <Label
                                label={t('sharedEquipmentFormFilter.sites')}
                                className='block h-6 text-primary-color'
                            />
                            <OptionLoading />
                        </>
                    ) : (
                        <Select
                            id='searchSite'
                            label={t('sharedEquipmentFormFilter.sites')}
                            options={sharedSites}
                            fetchAPI={(setLoading) =>
                                fetchDataSites(setLoading)
                            }
                        />
                    )}
                </div>

                <InputSearch
                    id='searchSerialNumber'
                    placeholder={t('sharedEquipmentFormFilter.serialNumber')}
                />

                <Button
                    onClick={() => onExecuteSubmitBtnFilter()}
                    color='success'
                    className={`mt-6`}
                >
                    {t('sharedEquipmentFormFilter.execute')}{' '}
                    <ArrowRightOutlined />
                </Button>
            </div>
        </>
    );
}
