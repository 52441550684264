import { Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import EntityFormStatus from './EntityFormStatus';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormLegend from '../../modUtils/components/FormLegend';
import SelectEntityParent from './SelectEntityParent/SelectEntityParent';
import DismissButton from '../../modUtils/components/buttons/DismissButton';
import SubmitButton from '../../modUtils/components/buttons/submitButton/SubmitButton';
import {
    create_entity_groups,
    update_entity_group_detail,
} from '../slices/entitySlice';
import ConfirmationModal from '../../modUtils/components/confirmationModal/ConfirmationModal';
import {
    AlertMessage,
    Spinner,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';

export default function EntityGroupForm({
    entity,
    closeModal,
    fetchEntities,
    entityCompanyParent,
    showSelectEntityType,
}) {
    const isEdit = !!entity;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    const { groupId } = useSelector((state) => state.manager);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { entityDetail } = useSelector((state) => state.entity);
    const [entityStatus, setEntityStatus] = useState(entity?.status);
    const [entityGroupDetail, setEntityGroupDetail] = useState(null);
    const [statusSelectedOption, setStatusSelectedOption] =
        useState(entityStatus);
    const [selectedParentEntityId, setSelectedParentEntityId] = useState();
    const [formFieldValue, setFormFieldValue] = useState();

    //selection du status
    const handleSelect = (value) => {
        setStatusSelectedOption(value);
        form.setFieldsValue({ status: value });
    };

    // Créateur d'action utilisant redux-thunk
    const addNewEntityGroup = (values) => {
        return async (dispatch) => {
            try {
                setLoading(true);
                // Action asynchrone qui attend la création du groupe
                await dispatch(create_entity_groups({ ...values }));
            } catch (error) {
                message.error({
                    content: (
                        <AlertMessage
                            status={error.status}
                            alertMessage={
                                'Erreur lors de la création du groupe'
                            }
                        />
                    ),
                });
            } finally {
                fetchEntities();
                setLoading(false);
            }
        };
    };

    useEffect(() => {
        if (entityDetail) {
            setEntityGroupDetail(entityDetail);
            setStatusSelectedOption(entityDetail?.status);
        }
    }, [groupId, entity, entityDetail]);

    useEffect(() => {
        form.resetFields();
        if (entity) {
            form.setFieldsValue({
                name: entityGroupDetail?.entity_group?.name || '',
                companyName:
                    entityCompanyParent?.name ||
                    entityGroupDetail?.entity_group?.entity ||
                    null,
                status: entityGroupDetail?.status,
                parent:
                    selectedParentEntityId ||
                    entityGroupDetail?.entity_group?.parent?.id ||
                    null,
                entity: entityGroupDetail?.entity_group?.parent?.id || null,
            });
            setFormFieldValue(form.getFieldsValue());
        }
    }, [entity, entityCompanyParent, entityGroupDetail, form, entityDetail]);

    useEffect(() => {
        if (selectedParentEntityId) {
            form.setFieldsValue({
                entity: selectedParentEntityId,
            });
        }
    }, [selectedParentEntityId]);

    const onFinish = async (values) => {
        const formFieldDifferences = getDifferences(values, formFieldValue);

        if (Object.keys(formFieldDifferences).length !== 0) {
            if (entity) {
                try {
                    setLoading(true);
                    await dispatch(
                        update_entity_group_detail({
                            groupId,
                            entityGroupId: entity?.id,
                            entity: values.entity,
                            name: values.name,
                            status: values.status,
                        }),
                    );
                } catch (error) {
                    message.error({
                        content: (
                            <AlertMessage
                                status={error.status}
                                alertMessage={'Authentification échouée!'}
                            />
                        ),
                    });
                } finally {
                    fetchEntities();
                    setLoading(false);
                    setStatusSelectedOption(values.status);
                }
            } else {
                dispatch(
                    addNewEntityGroup({
                        groupId,
                        ...values,
                        entity: entityCompanyParent?.id,
                    }),
                );
            }
        }
    };

    const handleResetForm = () => {
        form.resetFields();
        closeModal && closeModal();
    };

    // Soumet le formulaire
    const handleConfirm = () => {
        setIsModalVisible(false); // Cacher le modal
        onFinish(formValues); // Soumettre le formulaire avec les valeurs stockées
    };

    // annule la confirmation
    const handleCancel = () => {
        setIsModalVisible(false); // Cacher le modal sans soumettre le formulaire
    };

    // soumission du formulaire
    const handleFormSubmit = (values) => {
        if (entityStatus !== values.status) {
            setFormValues(values); // Stocker les valeurs du formulaire temporairement
            setIsModalVisible(true); // Afficher le modal de confirmation
            setEntityStatus(values.status);
        } else {
            onFinish(values); // Soumettre le formulaire
        }
    };

    // Fonction pour comparer deux objets et extraire les différences
    const getDifferences = (obj1, obj2) => {
        const differences = {};
        if (obj2) {
            Object.keys(obj1).forEach((key) => {
                if (obj1[key] !== obj2[key]) {
                    differences[key] = obj1[key];
                }
            });
            return differences;
        } else {
            return { ...obj1 }; // Renvoie une copie de obj1 s'il n'y a pas de comparaison à faire
        }
    };

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <Form
                        form={form}
                        onFinish={handleFormSubmit}
                        layout='vertical'
                    >
                        {/* Statut */}
                        {isEdit && (
                            <EntityFormStatus
                                handleSelect={handleSelect}
                                statusSelectedOption={statusSelectedOption}
                            />
                        )}

                        <div className='grid grid-cols-1 xl:grid-cols-2 gap-2'>
                            {/* Societe */}
                            <Form.Item
                                label={t('entityGroupForm.companyName')}
                                name='companyName'
                            >
                                <Input
                                    disabled
                                    placeholder={t(
                                        'entityGroupForm.companyName',
                                    )}
                                />
                            </Form.Item>

                            {/* Group */}
                            <Form.Item
                                label={t('entityGroupForm.groupName')}
                                name='name'
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'entityGroupForm.pleaseEnterGroupName',
                                        ),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={t('entityGroupForm.groupName')}
                                />
                            </Form.Item>
                        </div>

                        {/* entity user */}
                        {!showSelectEntityType && (
                            <>
                                <p className='text-left'>
                                    {t('entityGroupForm.associatedUser')}
                                    {entity?.user
                                        ? entity?.user
                                        : t('entityGroupForm.none')}
                                </p>

                                {!closeModal && (
                                    <Form.Item
                                        label={t('entityGroupForm.FormLabel')}
                                        name='entity'
                                    >
                                        <SelectEntityParent
                                            entity={entity}
                                            setSelected={
                                                setSelectedParentEntityId
                                            }
                                            entityDetail={entityDetail}
                                        />
                                    </Form.Item>
                                )}
                            </>
                        )}

                        <FormLegend requiredFormItemLegend={true} />

                        {/* Bouton submit et Dismiss (reset form) */}
                        <div className='flex flex-row justify-center gap-2 mt-2'>
                            <Form.Item>
                                <DismissButton
                                    onClick={() => handleResetForm()}
                                />
                            </Form.Item>

                            <Form.Item>
                                <SubmitButton
                                    label={
                                        isEdit
                                            ? t('entityGroupForm.save')
                                            : t('entityGroupForm.add')
                                    }
                                    className='h-[42px]'
                                />
                            </Form.Item>
                        </div>

                        <ConfirmationModal
                            onOk={handleConfirm}
                            onCancel={handleCancel}
                            visible={isModalVisible}
                            content={t(
                                'entityCustomerCompanyForm.areYouSureChangeStatus',
                            )}
                        />
                    </Form>
                </>
            )}
        </>
    );
}
