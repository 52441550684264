import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Space, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
    Spinner,
    Table,
} from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButton from 'ck_commun/src/app/modUtils/components/buttons/submitButton/SubmitButton';
import FilterByEquipmentType from 'ck_commun/src/app/modUtils/components/filters/FilterByEquipmentType';
import FilterByDate from 'ck_commun/src/app/modUtils/components/filters/FilterByDate';
import FilterBySearchSite from 'ck_commun/src/app/modManager/components/filterBySearchSite/FilterBySearchSite';
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage';

export default function Logs() {
    // const { logs, loading } = useSelector((state) => state.logs)
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [siteList, setSiteList] = useState();
    const [equipmentTypeList, setEquipmentTypeList] = useState();
    function handleSiteValueChange(selectedList) {
        setSiteList(selectedList?.length > 0 ? selectedList : null);
    }
    const handleEquipmentType = (selectedList) => {
        setEquipmentTypeList(selectedList?.length > 0 ? selectedList : null);
    };
    const onExecuteSubmitBtn = () => {
        console.log('btn clicked');
    };
    useEffect(() => {
        //dispatch(getLogs())
    }, []);
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
    // input search
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size='small'
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() =>
                            clearFilters && handleReset(clearFilters)
                        }
                        size='small'
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    //
    const columns = [
        {
            title: t('table.tabColDate'),
            dataIndex: 'date',
            key: 'date',
            width: '160px',

            // ...getColumnSearchProps("date"),

            // onCell: (record)=>({style: {
            //     textAlign: "center"
            //   }}),

            // // specify the condition of filtering result
            // // here is that finding the name started with `value`

            // sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
        },
        {
            title: '',
            dataIndex: 'logIcon',
            key: 'logIcon',

            // sorter: (a, b) => a.description.localeCompare(b.description),
            render: (text, record) => {
                switch (record.description) {
                    case 'connexion':
                        return (
                            <>
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/equipmentStatusIcons/connectedStatus.png'
                                    }
                                    alt='img'
                                />
                            </>
                        );
                    case 'déconnexion':
                        return (
                            <>
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/equipmentStatusIcons/offlineStatus.png'
                                    }
                                    alt='img'
                                />
                            </>
                        );

                    default:
                        return <span></span>;
                }
            },
        },
        {
            title: t('table.tabColSite'),
            dataIndex: 'site',
            key: 'site',
            bold: true,
            // ...getColumnSearchProps("site"),
            // sorter: (a, b) => a.site.localeCompare(b.site),
        },
        {
            title: t('table.tabColEquipment'),
            dataIndex: 'equipement',
            key: 'equipement',
            bold: true,
            // ...getColumnSearchProps("equipement"),
            // sorter: (a, b) => a.equipement.localeCompare(b.equipement),
        },
        {
            title: t('table.tabColDescription'),
            dataIndex: 'description',
            key: 'description',
            bold: true,
            // ...getColumnSearchProps("description"),
            // sorter: (a, b) => a.description.localeCompare(b.description),
        },
    ];
    return (
        <>
            <BuildingPage />
            {/*<div className="flex-display flex-row align-items-end filters">
        <FilterByEquipmentType onValueChange={handleEquipmentType} />
        <FilterBySearchSite
          onValueChange={handleSiteValueChange}
        ></FilterBySearchSite>
        <FilterByDate></FilterByDate>
        <SubmitButton onClick={() => onExecuteSubmitBtn()} />
      </div>
       {loading ? (
         <Spinner size="48px" color="red" />
      ) : (
        <>
          {logs.length>=1 && <div className="table-pre-text">{logs.length} log(s)</div> }
          <Table columns={columns} dataSource={logs} onChange={onChange} /> 
        </>
      )}*/}
        </>
    );
}
