import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { export_sales_by_group_export_get } from '../../../modSale/slices/saleSlice';
import ExportModal from '../../../modUtils/components/exportModal/ExportModal';
import { simulateDowloadLink } from '../../../modUtils/functions/simulateDowloadLink';
import { hasPermission } from '../../../modUtils/functions/handlePermissions';
import PermissionFadeContainer from '../../../modUtils/components/permissionFadeContainer/PermissionFadeContainer';

export default function ExportModalSalePage({ selectForm }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [fileFormat, setFileFormat] = useState('csv');
    const { groupId } = useSelector((state) => state.manager);
    const formatDate = 'DD/MM/YYYY HH:mm';

    // permissions

    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission =
        hasPermission('mod_sale:show_sale', permissions) &&
        hasPermission('mod_data:data_export', permissions);

    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission =
        hasPermission('mod_sale:show_sale', groupPermissions) &&
        hasPermission('mod_data:data_export', groupPermissions);

    const getExport = async () => {
        setLoading(true);
        const response = await dispatch(
            export_sales_by_group_export_get({
                fileFormat: fileFormat,
                groupId: groupId,
                dateStart: moment(selectForm['dateStart'], formatDate).unix(),
                dateEnd: moment(selectForm['dateEnd'], formatDate).unix(),
                creditCard: selectForm['creditCard'],
                equipmentType: selectForm['equipmentType']
                    ? selectForm['equipmentType'].join(',')
                    : null,
                sites: selectForm['siteListName']
                    ? selectForm['siteListName'].join(',')
                    : null,
                payment: selectForm['payment'],
                ordering: selectForm['ordering'],
            }),
        ).unwrap();
        setLoading(false);
        simulateDowloadLink(
            response,
            'ventes - ' + selectedGroup.name,
            fileFormat,
        );
    };

    const onChangeFileType = ({ target: { value } }) => {
        setFileFormat(value);
    };

    return (
        verifyPermission && (
            <PermissionFadeContainer permission={verifyGroupPermission}>
                <ExportModal
                    selectForm={selectForm}
                    getExport={getExport}
                    loading={loading}
                    onChangeFileType={onChangeFileType}
                />
            </PermissionFadeContainer>
        )
    );
}
