import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { create_cash_boxes_by_group } from '../../../slices/statementSlice';
import {
    Button,
    Input,
    Modal,
    Select,
} from '../../../../modUtils/components/componentsLibrary/componentsLibrary';
import { get_sites_by_group_paginated } from '../../../../modManager/slices/managerSlice';
import { get_equipments_gb_sites_by_group_paginated } from '../../../../modEquipment/slices/equipmentSlice';
import SubmitButton from '../../../../modUtils/components/buttons/submitButton/SubmitButton';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FaExclamationCircle, FaPlus } from 'react-icons/fa';
import i18next from 'i18next';
import { message } from 'antd';

const AddNewCashBox = ({ refresh, site, disabled }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groupId, sites } = useSelector((state) => state.manager);
    const [loading, setLoading] = useState(false);
    const [cashBoxEquipments, setCashBoxEquipments] = useState([]);
    const [equipments, setEquipments] = useState([]);
    const [creationError, setCreationError] = useState(null);
    const formReset = useRef(null);
    const selectKey = modalVisible ? 'open' : 'closed';
    const CashboxSchema = Yup.object().shape({
        name: Yup.string().required(t('addNewCashbox.addCashboxInputError')),
        cashBoxEquipments: Yup.array()
            .min(1, t('addNewCashbox.selectAtLeastOneEquipement'))
            .required(t('addNewCashbox.selectAtLeastOneEquipement')),
    });
    const createCashBox = async (values, resetForm) => {
        setCreationError(null);
        setLoading(true);
        try {
            const params = {
                groupId,
                name: values.name,
                site: site,
                isOld: false,
                cashBoxEquipments: cashBoxEquipments.map((equipment) => ({
                    equipment: equipment.id,
                })),
            };
            await dispatch(create_cash_boxes_by_group(params)).unwrap();
            message.success(t('addNewCashbox.successAdd'));
            refresh();
            resetForm();
            handleClose(); // Close modal after successful creation
        } catch (error) {
            setCreationError(error?.data?.detail);
        } finally {
            setLoading(false);
        }
    };

    const fetchEquipments = async () => {
        try {
            const equipmentData = await dispatch(
                get_equipments_gb_sites_by_group_paginated({
                    groupId,
                    limit: 100,
                    offset: 0,
                }),
            ).unwrap();
            //find site object from site selection
            const siteDataSelection = equipmentData.results.find(
                (item) => item.id === site,
            );
            //get equipements of site selection
            const equipmentSiteData = siteDataSelection.equipments;
            setEquipments(equipmentSiteData);
        } catch (error) {
            console.error('Error fetching equipment:', error);
        }
    };

    useEffect(() => {
        if (groupId && site) {
            fetchEquipments();
        }
    }, [groupId, site, i18next.language]);
    useEffect(() => {
        if (!modalVisible) {
            setCashBoxEquipments([]);
        }
    }, [modalVisible]);
    const handleClose = () => {
        setModalVisible(false);
        setCreationError(null);
        setCashBoxEquipments([]);
        if (formReset.current) {
            formReset.current(); // Reset form on modal close
        }
    };

    return (
        <>
            <Button
                onClick={() => {
                    setModalVisible(true);
                }}
                className='flex items-center group hover:fill-white'
                disabled={disabled}
            >
                <FaPlus
                    className={`mt-0 fill-primary-color ${
                        disabled ? 'fill-white' : ' group-hover:fill-white'
                    }`}
                />

                <span className='APP_tablePre-btnLabel ml-2'>
                    {t('addNewCashbox.addCashboxTitle')}
                </span>
            </Button>
            <Modal
                isOpen={modalVisible}
                onClose={handleClose}
                width={700}
                zIndex={2000}
                height={600}
            >
                <div className='flex items-center justify-center'>
                    <span className='font-bold text-base items-center justify-center uppercase text-primary-color'>
                        {t('addNewCashbox.addCashboxTitle')}
                    </span>
                </div>

                <br />

                <Formik
                    validationSchema={CashboxSchema}
                    initialValues={{ name: '', cashBoxEquipments: [] }}
                    onSubmit={(values, { resetForm }) => {
                        createCashBox(values, resetForm);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        resetForm,
                        setFieldValue,
                    }) => {
                        formReset.current = resetForm;

                        const handleEquipementValueChange = (selectedValue) => {
                            if (!Array.isArray(selectedValue)) {
                                selectedValue = [selectedValue];
                            }
                            const selectedEquipments = selectedValue
                                .map((selectedId) => {
                                    const foundEquipment = equipments.find(
                                        (equipment) =>
                                            Number(equipment.id) ===
                                            Number(selectedId),
                                    );
                                    return foundEquipment;
                                })
                                .filter(Boolean);
                            const newEquipments = selectedEquipments.filter(
                                (selectEq) =>
                                    !values.cashBoxEquipments.some(
                                        (prevEq) => prevEq.id === selectEq.id,
                                    ),
                            );
                            setFieldValue('cashBoxEquipments', [
                                ...values.cashBoxEquipments,
                                ...newEquipments,
                            ]);
                            setCashBoxEquipments((prevEquipment) => {
                                const newEquipment = selectedEquipments.filter(
                                    (selectEq) =>
                                        !prevEquipment.some(
                                            (prevEq) => prevEq.id === selectEq,
                                        ),
                                );
                                if (newEquipment.length === 0)
                                    return prevEquipment;
                                return [...prevEquipment, ...newEquipment];
                            });
                        };
                        const handleDeselect = (selectedValue) => {
                            const deselectedEquipments = Array.isArray(
                                selectedValue,
                            )
                                ? selectedValue
                                : [selectedValue];
                            setFieldValue(
                                'cashBoxEquipments',
                                values.cashBoxEquipments.filter(
                                    (equipment) =>
                                        !deselectedEquipments.includes(
                                            equipment.id,
                                        ),
                                ),
                            );
                        };

                        return (
                            <form
                                i='form'
                                noValidate
                                onSubmit={handleSubmit}
                                ref={formReset}
                            >
                                <div className='flex flex-row gap-8 mt-16'>
                                    <div className='items-start'>
                                        <Input
                                            label={
                                                <span className='text-black normal-case'>
                                                    {t(
                                                        'addNewCashbox.cashboxName',
                                                    ) + '*'}
                                                </span>
                                            }
                                            value={values.name}
                                            name='name'
                                            onChange={handleChange}
                                            placeholder={t(
                                                'addNewCashbox.cashboxName',
                                            )}
                                        />

                                        <p className='text-red-500 text-xs'>
                                            {errors.name &&
                                                touched.name &&
                                                errors.name}
                                        </p>
                                    </div>
                                    <div className='items-end ml-10'>
                                        <Select
                                            key={selectKey}
                                            selectLabel={
                                                <span className='text-black normal-case'>
                                                    {t(
                                                        'addNewCashbox.cashboxEquipement',
                                                    )}
                                                </span>
                                            }
                                            value={cashBoxEquipments.map(
                                                (eq) => eq.id,
                                            )}
                                            options={
                                                Array.isArray(equipments)
                                                    ? equipments.map((eq) => ({
                                                          value: eq.id,
                                                          label: eq.name,
                                                      }))
                                                    : []
                                            }
                                            onSelect={
                                                handleEquipementValueChange
                                            }
                                            onDeselect={handleDeselect}
                                            multiple
                                            placeholder={t(
                                                'addNewCashbox.cashboxEquipementSelect',
                                            )}
                                        />
                                        <p className='text-red-500 text-xs'>
                                            {errors.cashBoxEquipments &&
                                                touched.cashBoxEquipments &&
                                                errors.cashBoxEquipments}
                                        </p>
                                    </div>
                                </div>

                                <div className='grid justify-items-center mt-48 '>
                                    <SubmitButton
                                        label={
                                            <span className='uppercase'>
                                                {t(
                                                    'addNewCashbox.addCashboxButton',
                                                )}
                                            </span>
                                        }
                                    />
                                    {creationError && (
                                        <div className='items-center gap-1'>
                                            <FaExclamationCircle
                                                className='w-4'
                                                fill='red'
                                            />
                                            <span className='text-red-500 text-xs '>
                                                {creationError}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};

export default AddNewCashBox;
