import React from 'react';

export default function Option({ onClick, className = {}, ...props }) {
    return (
        <div
            className={`
                w-full text-left bg-white hover:bg-primary-color-600 text-primary-color
                hover:text-white px-4 py-2 rounded-md text-base
                ${className}`}
            onClick={onClick}
        >
            {props.children}
        </div>
    );
}
