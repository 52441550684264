import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    httpGet,
    httpPost,
    httpDelete,
    httpPut,
} from 'ck_commun/src/app/modUtils/apis/httpConfig';

// retourner la liste des contraintes financière d'une configuration
export const get_constraint_financial_paginated = createAsyncThunk(
    'mod_profil/get_constraint_financial_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_financial/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Créer une contrainte financière
export const create_constraint_financial = createAsyncThunk(
    'mod_profil/create_constraint_financial',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_financial/`,
                {
                    name: values.name,
                    equipment_type: values.equipmentType,
                    is_global: values.isGlobal,
                    period_type: values.periodType,
                    amount_max: values.amountMax,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// retourner le détail d'une contrainte financière
export const get_constraint_financial_detail = createAsyncThunk(
    'mod_profil/get_constraint_financial_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_financial/${values.constraintId}/`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// modifier une contrainte financière
export const update_constraint_financial_detail = createAsyncThunk(
    'mod_profil/update_constraint_financial_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPut(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_financial/${values.constraintId}/`,
                {
                    name: values.name,
                    equipment_type: values.equipmentType,
                    is_global: values.isGlobal,
                    period_type: values.periodType,
                    amount_max: values.amountMax,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Supprimer une contrainte financière
export const delete_constraint_financial_detail = createAsyncThunk(
    'mod_profil/delete_constraint_financial_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpDelete(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_financial/${values.constraintId}/`,
            );
            return { ...response.data, id: values.constraintId };
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const constraintFinancialSlice = createSlice({
    name: 'constraintFinancial',
    initialState: {
        loading: true,
        constraintsFinancial: [],
        constraintsFinancialDetail: {},
    },
    extraReducers: (builder) => {
        builder
            /* get_constraint_financial_paginated */
            .addCase(
                get_constraint_financial_paginated.pending,
                (state, action) => {
                    state.constraintsFinancial = [];
                },
            )
            .addCase(
                get_constraint_financial_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.constraintsFinancial = action.payload.results;
                },
            )
            .addCase(
                get_constraint_financial_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* create_constraint_financial */
            .addCase(create_constraint_financial.fulfilled, (state, action) => {
                state.loading = false;
                action.payload.index = action.payload.id;
                state.constraintsFinancial = [
                    ...state.constraintsFinancial,
                    action.payload,
                ];
            })
            .addCase(create_constraint_financial.rejected, (state, action) => {
                state.loading = false;
            })

            /* get_constraint_financial_detail*/
            .addCase(
                get_constraint_financial_detail.pending,
                (state, action) => {
                    state.constraintsFinancialDetail = {};
                },
            )
            .addCase(
                get_constraint_financial_detail.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.constraintsFinancialDetail = action.payload;
                },
            )
            .addCase(
                get_constraint_financial_detail.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* update_constraint_financial_detail*/
            .addCase(
                update_constraint_financial_detail.fulfilled,
                (state, action) => {
                    state.loading = false;
                    const updatedConstraintFinancial = action.payload;
                    const updatedConstraintsFinancialList =
                        state.constraintsFinancial.map((constraint) => {
                            if (
                                constraint.id === updatedConstraintFinancial.id
                            ) {
                                return updatedConstraintFinancial;
                            }
                            return constraint;
                        });
                    state.constraintsFinancial =
                        updatedConstraintsFinancialList;
                },
            )
            .addCase(
                update_constraint_financial_detail.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* delete_constraint_financial_detail */
            .addCase(
                delete_constraint_financial_detail.fulfilled,
                (state, action) => {
                    const constraintFinancialToDeleteId = action.payload.id;
                    state.constraintsFinancial =
                        state.constraintsFinancial.filter(
                            (constraint) =>
                                constraint.id !== constraintFinancialToDeleteId,
                        );
                    state.loading = false;
                },
            )
            .addCase(
                delete_constraint_financial_detail.rejected,
                (state, action) => {
                    state.loading = false;
                },
            );
    },
});

export default constraintFinancialSlice.reducer;
