import React from 'react';
import Button from './Button';

interface ButtonAdminProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    children?: React.ReactNode;
}

const ButtonAdmin: React.FC<ButtonAdminProps> = ({
    onClick,
    className = '',
    ...props
}) => {
    return (
        <Button
            color='dark'
            className={`
                font-bold w-auto rounded shadow-xl my-2
                ${className}`}
            onClick={onClick}
        >
            {props.children}
        </Button>
    );
};

export default ButtonAdmin;
