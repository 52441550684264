import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import EntityList from '../components/EntityList/EntityList';
import EntityDetailsContainer from '../components/EntityDetailsContainer';
import SearchInput from '../../modUtils/components/searchInput/SearchInput';
import {
    get_entity_paginated,
    get_entity_detail,
    update_entity_customer_detail,
    update_entity_company_detail,
} from '../slices/entitySlice';
import SubmitButton from '../../modUtils/components/buttons/submitButton/SubmitButton';
import AddEntityCustomerCompanyModal from '../components/AddEntityCustomerCompanyModal';
import {
    Card,
    Pagination,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import i18next from 'i18next';

export default function CustomersPage({ supportId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const groupIdRef = useRef(null);
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const { groupId } = useSelector((state) => state.manager);
    const [inputSearchValue, setInputSearchValue] = useState();
    const [showClosedAccounts, setShowClosedAccounts] = useState(false);
    const statusFilterValue = showClosedAccounts ? '0,1,2,3' : '0,1,3';
    const { profilByEntity } = useSelector((state) => state.profil.profil);
    const { entitiesCount } = useSelector((state) => state.entity);

    useEffect(() => {
        groupIdRef.current = groupId;
        setSelectedEntity(null);
    }, [groupId]);

    // appelée quand on clique sur le bouton exécuter
    const onExecuteSubmitButton = () => {
        setOffset(0);
        getEntities(0, inputSearchValue);
    };

    // modifie la valeur de showClosedAccounts
    const handleOnChangeCheckbox = () => {
        setShowClosedAccounts(!showClosedAccounts);
    };

    async function getEntities(newOffset, name, idGroup) {
        await dispatch(
            get_entity_paginated({
                groupId: idGroup ? idGroup : groupId,
                limit: limit,
                offset: newOffset || newOffset === 0 ? newOffset : offset,
                onlyParent: true,
                name: name
                    ? name
                    : inputSearchValue?.length > 0
                      ? inputSearchValue
                      : null,
                status: statusFilterValue,
            }),
        );
    }

    async function getEntityDetail() {
        if (selectedEntity?.id) {
            await dispatch(
                get_entity_detail({
                    groupId,
                    entityId: selectedEntity?.id,
                }),
            ).unwrap();
        }
    }

    useEffect(() => {
        // Vérifie si selectedEntity est défini et que le groupId n'a pas changé
        if (selectedEntity && groupIdRef.current === groupId) {
            getEntityDetail(); // Appelle les détails de l'entité si le groupId est toujours le même
        }
    }, [selectedEntity, profilByEntity, i18next.language]);

    async function updateEntityCustomer(entityId, parentEntityId, value) {
        await dispatch(
            update_entity_customer_detail({
                groupId,
                entityCustomerId: entityId,
                entity: parentEntityId,
                ...value,
            }),
        );
        getEntities();
        getEntityDetail();
    }

    async function updateEntityCompany(entityId, value) {
        await dispatch(
            update_entity_company_detail({
                groupId,
                entityCompanyId: entityId,
                ...value,
            }),
        );
        getEntities();
        getEntityDetail();
    }

    // Fonction de gestion de changement de pagination
    function handlePaginationChange(page, pageSize) {
        // Mettez à jour les valeurs de limit et offset en fonction de la page et de la taille de page
        const newOffset = (page - 1) * pageSize;
        setOffset(newOffset);
        if (newOffset < 0) {
            setCurrentPage(1);
        } else {
            setCurrentPage(page);
        }
        setLimit(pageSize);

        // Effectuez une requête à l'API avec les nouvelles valeurs de limit et offset pour obtenir les données correspondantes
        getEntities(newOffset);
    }

    return (
        <div className='flex flex-col h-full'>
            <div className='flex flex-row gap-1'>
                <div className='w-1/4 pr-2 min-w-72'>
                    <SearchInput
                        value={inputSearchValue}
                        onChange={(e) => setInputSearchValue(e.target.value)}
                        placeholder={t('customersPage.searchCustomer')}
                        fetchPressEnter={(value) =>
                            getEntities(0, value, groupIdRef.current)
                        }
                    />
                </div>

                <div className='w-3/4 pl-2'>
                    <SubmitButton onClick={onExecuteSubmitButton} />
                </div>
            </div>

            <div className='flex flex-row grow gap-1 mt-2 overflow-y-clip min-h-[91vh] max-h-[91vh]'>
                <div className='w-1/4 pr-2 min-w-72'>
                    <Card className='w-full h-full overflow-x-scroll overflow-y-auto'>
                        <div className='flex flex-col items-center mt-2 mx-1'>
                            <AddEntityCustomerCompanyModal
                                supportId={supportId}
                                type='button'
                                getEntities={getEntities}
                            />

                            <div className='my-4 flex'>
                                <Checkbox
                                    onChange={handleOnChangeCheckbox}
                                    className='h-[16px]'
                                >
                                    <span className='text-primary-color font-medium'>
                                        {t('customersPage.viewClosedAccounts')}
                                    </span>
                                </Checkbox>
                            </div>
                        </div>

                        {entitiesCount > 0 && (
                            <div className='flex justify-center'>
                                <Pagination
                                    defaultCurrent={currentPage}
                                    defaultPageSize={limit}
                                    showTotal={false}
                                    total={entitiesCount}
                                    onChange={handlePaginationChange}
                                    onShowSizeChange={handlePaginationChange}
                                    hideOnSinglePage={false}
                                    showSizeChange={false}
                                    showQuickJumper={false}
                                />
                            </div>
                        )}

                        <EntityList
                            saveSelectedEntityCallback={setSelectedEntity}
                            supportId={supportId}
                            getEntities={getEntities}
                            statusFilterValue={statusFilterValue}
                            fetchUpdateEntityCustomer={updateEntityCustomer}
                        />
                    </Card>
                </div>

                <div className='w-3/4 pl-2 min-w-80'>
                    <Card className='w-full h-full overflow-y-auto'>
                        {/* appliquer une opacité à 50% et empêcher les pointer-events quand on n'a pas un entité sélectionnée */}
                        <div
                            className={`${!selectedEntity && 'opacity-50 pointer-events-none'}`}
                        >
                            <EntityDetailsContainer
                                entity={selectedEntity}
                                getEntities={getEntities}
                                fetchUpdateEntityCustomer={updateEntityCustomer}
                                fetchUpdateEntityCompany={updateEntityCompany}
                            />
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}
