import React from 'react';
import { Modal } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { useTranslation } from 'react-i18next';
import GroupPlans from './GroupPlans';

export default function UserSubscriptionPlanModal({
    modalVisible,
    setModalVisible,
}) {
    const { t } = useTranslation();

    return (
        <Modal
            title={t(
                'userSubscriptionPlan.transUserSubscriptionPlanModalTitle',
            )}
            isOpen={modalVisible}
            onClose={() => setModalVisible(false)}
            width='fit-content'
        >
            <GroupPlans />
        </Modal>
    );
}
