export const simulateDowloadLink = (dataHref, fileName, fileFormat) => {
    const downloadLink = document.createElement('a');
    downloadLink.href =
        'data:text/plain;charset=utf-8,' + encodeURIComponent(dataHref);
    downloadLink.download = fileName + '.' + fileFormat;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    // Nettoyage
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(downloadLink);
};
