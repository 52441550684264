import React from 'react';
import LoginPage from '../../cklibs/ck_commun/src/app/modAuthentication/pages/LoginPage';
import {
    APP_LOGO,
    APP_NAME,
    CONTACT_PHONE_NUMBER,
    URL_MOBILE_ANDROID,
    URL_MOBILE_APPLE,
} from '../../utils/constants';
import ModalMobilePage from 'ck_commun/src/app/modUtils/pages/ModalMobilPage';

export default function Login() {
    return (
        <>
            <ModalMobilePage
                logoSrc={APP_LOGO}
                urlMobileAppApple={URL_MOBILE_APPLE}
                urlMobileAppAndroid={URL_MOBILE_ANDROID}
            />
            <LoginPage
                appName={APP_NAME}
                contactPhoneNumber={CONTACT_PHONE_NUMBER}
                appLogo={APP_LOGO}
            />
        </>
    );
}
