import React from 'react';

export default function NoData({ text }) {
    return (
        <div className='flex flex-col items-center mt-16'>
            <img
                src={process.env.PUBLIC_URL + '/img/design/noData.png'}
                alt='no data to display'
                width={50}
            />
            <div className='text-center'>{text}</div>
        </div>
    );
}
