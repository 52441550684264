import React from 'react';
import { FaAddressBook } from 'react-icons/fa';

const GroupInfoCard = ({ icon, title, subtitle }) => {
    return (
        <div>
            <div className='block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring'>
                <span className='inline-block rounded-lg bg-gray-50 p-3'>
                    {icon}
                </span>

                <h2 className='mt-4 text-base	 font-bold '>{title}</h2>

                <p className='text-gray-600 break hover:text-secondary-color font-semibold'>
                    {subtitle}{' '}
                </p>
            </div>
        </div>
    );
};
export default GroupInfoCard;
