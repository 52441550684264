import React, { useEffect, useState } from 'react';
import { Marker } from 'react-leaflet';
import { Icon } from 'leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';

import SiteManageModal from '../../../modEquipment/components/siteManageModal/SiteManageModal';

export default function SiteMarker({ site }) {
    const [modalVisible, setModalVisible] = useState(false);
    const handleModal = () => {
        setModalVisible(true);
    };
    const closeModal = () => {
        setModalVisible(false);
    };

    return site.location_latitude && site.location_longitude ? (
        <Marker
            position={{
                lat: site.location_latitude,
                lng: site.location_longitude,
            }}
            icon={
                new Icon({
                    iconUrl: markerIconPng,
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                })
            }
            eventHandlers={{
                click: (e) => {
                    handleModal();
                },
            }}
        >
            <SiteManageModal
                site={site}
                modalVisible={modalVisible}
                handleModal={handleModal}
                closeModal={closeModal}
            />
        </Marker>
    ) : null;
}
