import React from 'react';
import { Route } from 'react-router-dom';
import CrmDashboard from '../pages/crm/crmDashboard/CrmDashboard';
import Clients from '../pages/crm/Clients';
import ProfilesAndPackages from '../pages/crm/ProfilsAndPackages';
import CkRoutes from 'ck_commun/src/app/modUtils/routes/CkRoutes';

export default function CrmRoutes() {
    return (
        <CkRoutes>
            <Route path='dashboard' element={<CrmDashboard />} />
            <Route path='clients' element={<Clients />} />
            <Route path='profiles' element={<ProfilesAndPackages />} />
        </CkRoutes>
    );
}
