import React from 'react';
import {
    APP_LOGO,
    URL_MOBILE_APPLE,
    URL_MOBILE_ANDROID,
} from '../../utils/constants';
import HomePage from 'ck_commun/src/app/modUtils/pages/Home/HomePage';
import ModalMobilePage from 'ck_commun/src/app/modUtils/pages/ModalMobilPage';

// import WidgetCATopSites from "ck_commun/src/app/modCumulative/components/widgetCATopSites";

export default function Home() {
    return (
        <>
            <ModalMobilePage
                logoSrc={APP_LOGO}
                urlMobileAppApple={URL_MOBILE_APPLE}
                urlMobileAppAndroid={URL_MOBILE_ANDROID}
            />
            <HomePage appLogo={APP_LOGO} />
        </>
    );
}
