import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Table,
    Spinner,
    Select,
    Pagination,
} from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import {
    export_tellecollects_by_group_export_get,
    get_group_telecollects_paginated,
} from '../slices/bankingSlice';
import { dateFormat } from 'ck_commun/src/app/modUtils/functions/dateFormat';
import SubmitButton from 'ck_commun/src/app/modUtils/components/buttons/submitButton/SubmitButton';
import FilterByEquipmentType from 'ck_commun/src/app/modUtils/components/filters/FilterByEquipmentType';
import FilterByDate from 'ck_commun/src/app/modUtils/components/filters/FilterByDate';
import FilterBySearchSite from 'ck_commun/src/app/modManager/components/filterBySearchSite/FilterBySearchSite';
import { eApplicationType } from '../../../commun_global/enums';
import moment from 'moment';
import TelecollectDetailsModal from '../components/TelecollectDetailModal';
import { amountFormatWithCurrencySymbol } from 'ck_commun/src/app/modUtils/functions/numberFormat';
import { message } from 'antd';
import AlertMessage from 'ck_commun/src/app/modUtils/components/AlertMessage';

import ExportModalTelecollectPage from '../components/exportModalTelecollectPage/exportModalTelecollectPage';
import { hasPermission } from '../../modUtils/functions/handlePermissions';
import i18next from 'i18next';

export default function TelecollectsPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId, selectedGroup } = useSelector((state) => state.manager);
    const [loading, setLoading] = useState(true);
    const siteCount = selectedGroup?.site_count;
    const { telecollectsCount } = useSelector((state) => state.banking);
    const [searchInputValue, setSearchInputValue] = useState();
    const [telecollectIdSelected, setTelecollectIdSelected] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [equipmentTypeList, setEquipmentTypeList] = useState([]);
    const [siteList, setSiteList] = useState([]);
    let [limit, setLimit] = useState(20);
    let [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [telecollects, setTelecollects] = useState([]);
    const formatDate = 'DD/MM/YYYY HH:mm';
    const ordering = '-date';
    const filterBySearchOptions = [
        { label: t('telecollectInfo.optionApp'), value: 'app' },
        { label: t('telecollectInfo.optionDiscount'), value: 'remittance' },
        { label: t('telecollectInfo.optionAmount'), value: 'amount' },
    ];
    const [selectedOption, setSelectedOption] = useState(
        filterBySearchOptions[0].value,
    );
    const [startDate, setStartDate] = useState(
        moment().subtract(6, 'days').startOf('day').format(formatDate),
    );
    const [endDate, setEndDate] = useState(
        moment().endOf('day').format(formatDate),
    );
    const groupPermissions = selectedGroup.permissions;
    const verifyPermissionShowSale = hasPermission(
        'mod_sale:show_sale',
        groupPermissions,
    );
    const verifyPermissionDataExport = hasPermission(
        'mod_data:data_export',
        groupPermissions,
    );
    const [selectForm, setSelectForm] = useState({
        dateStart: startDate,
        dateEnd: endDate,
    });

    //getTelecollectes : fonction qui appelle l'api get_group_telecollects_paginated pour récupérer la liste des télecollectes
    const getTelecollectes = async (values) => {
        let searchValue = searchInputValue;
        try {
            setLoading(true);
            const response = await dispatch(
                get_group_telecollects_paginated({
                    groupId,
                    limit: values.limit,
                    offset: values.offset,
                    ordering: ordering,
                    equipmentType: equipmentTypeList?.join(','),
                    sites: siteList?.join(','),
                    dateStart: startDate
                        ? moment(startDate, formatDate).unix()
                        : null,
                    dateEnd: endDate
                        ? moment(endDate, formatDate).unix()
                        : null,
                    remittance:
                        selectedOption == 'remittance' &&
                        searchValue?.trim().length > 0
                            ? searchValue.trim()
                            : null,
                    app:
                        selectedOption == 'app' &&
                        searchValue?.trim().length > 0
                            ? searchValue.trim()
                            : null,
                    amount:
                        selectedOption == 'amount' &&
                        searchValue?.trim().length > 0
                            ? searchValue.trim()
                            : null,
                }),
            ).unwrap();
            setTelecollects(response.results);
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('telecollectInfo.errorMessage')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        setLoading(true);
        setEquipmentTypeList(null);
        setSiteList(null);
        if (startDate && endDate) {
            groupId && getTelecollectes({ offset: 0, limit });
        }
    }, [groupId, i18next.language]);
    // Fonction de gestion de changement de pagination
    const handlePaginationChange = (page, pageSize) => {
        // Mettez à jour les valeurs de limit et offset en fonction de la page et de la taille de page
        const newOffset = (page - 1) * pageSize;
        setOffset(newOffset);
        if (newOffset < 0) {
            setCurrentPage(1);
        } else {
            setCurrentPage(page);
        }
        setLimit(pageSize);
        // Effectuez une requête à l'API avec les nouvelles valeurs de limit et offset pour obtenir les données correspondantes
        getTelecollectes({ offset: newOffset, limit: pageSize });
    };
    //filtre par site
    const handleSiteValueChange = (selectedList) => {
        setSiteList(selectedList?.length > 0 ? selectedList : null);
        setSelectForm({ ...selectForm, siteListName: selectedList });
    };
    //filtre par type d'équipement
    const handleEquipmentTypeChange = (selectedList) => {
        setEquipmentTypeList(selectedList?.length > 0 ? selectedList : null);
        setSelectForm({ ...selectForm, equipmentType: selectedList });
    };

    function formatedDate(selectedDate) {
        let formatedDate;

        if (typeof selectedDate === 'object') {
            const date = selectedDate;
            formatedDate = moment(date, formatDate, true).format(formatDate);
        } else if (
            typeof selectedDate === 'string' &&
            selectedDate.length > 0
        ) {
            formatedDate = moment(selectedDate, formatDate, true).format(
                formatDate,
            );
        }

        return formatedDate;
    }

    //filtre par date
    const handleDateChange = (selectedDate) => {
        const formatedStartDate = formatedDate(selectedDate[0]);
        const formatedEndDate = formatedDate(selectedDate[1]);

        setStartDate(formatedStartDate);
        setEndDate(formatedEndDate);

        setSelectForm({
            ...selectForm,
            dateStart: formatedStartDate,
            dateEnd: formatedEndDate,
        });
    };

    //filtre recherche par app / remise / montant
    const handlePressEnter = (selectedOption, searchInputValue) => {
        setSelectedOption(selectedOption[0]);
        getTelecollectes({ offset: 0, limit, searchInputValue });
    };

    const handleRowClick = (rowKey) => {
        setTelecollectIdSelected(rowKey);
        setModalVisible(true);
    };

    const columns = [
        {
            title: t('table.tabColDate'),
            dataIndex: 'date',
            key: 'date',
            width: '10%',
            render: (text, record) => dateFormat(record.date),
        },
        {
            title: t('table.tabColSite'),
            dataIndex: 'site',
            key: 'site',
            align: 'left',
            width: '15%',
            bold: true,
        },
        {
            title: t('table.tabColEquipment'),
            dataIndex: 'equipment',
            key: 'equipment',
            align: 'left',
            width: '15%',
            ellipsis: true,
            bold: true,
        },
        {
            title: t('table.tabColApplication'),
            dataIndex: 'app',
            key: 'app',
            width: '20%',
            ellipsis: true,
            bold: true,
            render: (text, record) => t(eApplicationType[text]),
        },
        {
            title: t('table.tabColFile'),
            dataIndex: 'sequence_number',
            key: 'sequence_number',
            width: '10%',
            bold: true,
        },
        {
            title: t('table.tabColDiscount'),
            dataIndex: 'remittance',
            key: 'remittance',
            width: '10%',
            bold: true,
        },
        {
            title: t('table.tabColOk'),
            dataIndex: 'ok_count',
            key: 'ok_count',
            width: '5%',
            bold: true,
        },
        {
            title: t('table.tabColFail'),
            dataIndex: 'ko_count',
            key: 'ko_count',
            width: '5%',
            bold: true,
        },
        {
            title: t('table.tabColAmount'),
            dataIndex: 'amount',
            key: 'amount',
            width: '8%',
            bold: true,
            className: 'table-column-accent',
            headerClassName: 'table-column-accent',
            render: (text, record) => (
                <span>{amountFormatWithCurrencySymbol(text)}</span>
            ),
        },
        {
            title: '',
            dataIndex: '',
            key: 'actions',
            width: '5%',
            render: (text, record) => (
                <div
                    className='telecollect-actions-menu'
                    onClick={(event) => event.stopPropagation()}
                >
                    <TelecollectDetailsModal
                        telecollectData={record}
                        telecollectId={telecollectIdSelected}
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                    />
                </div>
            ),
        },
    ];
    return (
        <>
            <div className='filters gap-2 flex flex-row items-end flex-wrap md:max-w-[calc(100vw-500px)]'>
                <FilterByEquipmentType
                    onValueChange={handleEquipmentTypeChange}
                />
                {siteCount > 1 && (
                    <FilterBySearchSite onValueChange={handleSiteValueChange} />
                )}
                <FilterByDate
                    onValueChange={handleDateChange}
                    defaultPeriod='lastSevenDays'
                />
                <SubmitButton
                    onClick={() => getTelecollectes({ offset: 0, limit })}
                />
            </div>
            <Select
                defaultActiveFirstOption={true}
                options={filterBySearchOptions}
                onSelect={(value) => setSelectedOption(value)}
                selectLabel={t('filterBySearchOptions.selectLabel')}
                searchInput
                onPressEnter={handlePressEnter}
                setSearchInputValue={setSearchInputValue}
            />
            {loading ? (
                <Spinner />
            ) : (
                <div style={{ marginTop: 20 }}>
                    <div className='flex flex-row-reverse justify-between'>
                        <div className='data-button-container'>
                            {verifyPermissionShowSale &&
                                verifyPermissionDataExport && (
                                    <ExportModalTelecollectPage
                                        selectForm={selectForm}
                                    />
                                )}
                        </div>

                        {telecollects.length > 0 && (
                            <div>
                                <div className='flex flex-row justify-between'>
                                    <Pagination
                                        defaultCurrent={currentPage}
                                        defaultPageSize={limit}
                                        showQuickJumper={true}
                                        showTotal={false}
                                        total={telecollectsCount}
                                        onChange={handlePaginationChange}
                                        onShowSizeChange={
                                            handlePaginationChange
                                        }
                                        hideOnSinglePage={true}
                                    />
                                </div>

                                <div className='table-pre-text m-2'>
                                    {telecollectsCount}{' '}
                                    {t('telecollectInfo.telecollect')}
                                </div>
                            </div>
                        )}
                    </div>

                    <Table
                        tableClassName={'min-w-[970px] '}
                        columns={columns}
                        dataSource={telecollects}
                        onRowClick={(rowKey, event) =>
                            handleRowClick(rowKey, event)
                        }
                    />
                </div>
            )}
        </>
    );
}
