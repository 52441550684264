import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { eCdbsPaymentType } from 'ck_commun/src/commun_global/enums';
import { dateFormat } from '../../../modUtils/functions/dateFormat';
import NoData from '../../../modUtils/components/noData/NoData';
import { amountFormatWithCurrencySymbol } from '../../../modUtils/functions/numberFormat';
import { ReactComponent as AwaitStatus } from 'ck_commun/src/app/modStatement/assets/images/await.svg';
import { ReactComponent as OkStatus } from 'ck_commun/src/app/modStatement/assets/images/checked.svg';
import { ReactComponent as KOStatus } from 'ck_commun/src/app/modStatement/assets/images/x-red.svg';
import { ReactComponent as NotReadyStatus } from 'ck_commun/src/app/modStatement/assets/images/circle-gray.svg';

import PermissionFadeContainer from 'ck_commun/src/app/modUtils/components/permissionFadeContainer/PermissionFadeContainer';
import { hasPermission } from 'ck_commun/src/app/modUtils/functions/handlePermissions';

import MapTableCell from '../mapTableCell/MapTableCell';
import PickupDetails from '../pickupDetails/PickupDetails';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { get_statements_by_group_paginated } from '../../slices/statementSlice';

export default function PickupsTable({
    statements,
    dateStart,
    dateEnd,
    filterSite,
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groupId, sites } = useSelector((state) => state.manager);
    const [selectedStatement, setSelectedStatement] = useState(null);
    const [selectedDetail, setSelectedDetail] = useState(null);
    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_statement:manage_statement',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_statement:manage_statement',
        groupPermissions,
    );

    const [expandedChevrons, setExpandedCheveron] = useState({});
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [equipmentTypeList, setEquipmentTypeList] = useState();
    const formatDate = 'DD/MM/YYYY HH:mm';
    const [cashboxStatement, setCashboxStatement] = useState([]);
    const { cashBoxes } = useSelector((state) => state.statement);

    //retourne l'icone à afficher dans la ligne relevé par type de paiement
    const renderStatementDifferenceIcon = (
        amountCashbox,
        amountCashboxCounted,
    ) => {
        if (amountCashboxCounted == null)
            return <NotReadyStatus className='w-4' />;
        else {
            if (amountCashboxCounted !== amountCashbox)
                return <KOStatus className='w-4' />;
            else if (amountCashboxCounted === amountCashbox)
                return <OkStatus className='w-4' />;
        }
    };
    // retrouver les nom des site à partir des ids
    const getSiteForCashboxes = (cashboxId) => {
        //trouver le cashbox avec l id qui correspond
        const cashbox = cashBoxes.find(
            (searchedCashbox) => searchedCashbox.id === cashboxId,
        );
        //verifier si le cashbox existe
        if (cashbox && cashbox.site) {
            //trouver le nom du site à partir de la liste des site
            const site = sites.find(
                (searchedSite) => searchedSite.id === cashbox.site,
            );
            return site ? site.name : 'site inconnu';
        }
    };
    //retourne l'icone à afficher dans la ligne relevé par équipement
    const renderEquipmentStatementStatusIcon = (status) => {
        switch (status) {
            case 0:
                return <AwaitStatus className='w-4' />;
            case 1:
                return <KOStatus className='w-4' />;
            case 2:
                return <OkStatus className='w-4' />;
        }
    };
    //calcul différence entre montant relevé attendu et montant comptabilisé
    const getStatementDifference = (amountCashbox, amountCashboxCounted) => {
        if (amountCashboxCounted == null) return { value: '-' };
        else {
            const differenceAmount = amountCashboxCounted - amountCashbox;
            const colorDifference =
                differenceAmount >= 0 ? 'text-green-500' : 'text-red-500';
            return {
                value:
                    differenceAmount === 0
                        ? amountFormatWithCurrencySymbol('0.00')
                        : amountFormatWithCurrencySymbol(differenceAmount),
                colorDifference,
            };
        }
    };
    const refreshData = () => {
        dispatch(
            get_statements_by_group_paginated({
                groupId,
                dateStart: moment(dateStart, formatDate).unix(),
                dateEnd: moment(dateEnd, formatDate).unix(),
                cashBoxes: (cashboxStatement || []).join(','),
                sites: (filterSite || []).join(','),
                equipments: (equipmentTypeList || []).join(','),
                limit,
                offset,
            }),
        );
    };
    const onClose = () => {
        setExpandedCheveron(false);
    };
    const handlePickupDetail = (statementId, detailId) => {
        if (expandedChevrons === `${statementId}-${detailId}`) {
            setExpandedCheveron(null);
            setSelectedStatement(null);
            setSelectedDetail(null);
        } else {
            setExpandedCheveron((prev) => ({
                ...prev,
                [`${statementId}-${detailId}`]:
                    !prev[`${statementId}-${detailId}`],
            }));
            setSelectedStatement(statementId);
            setSelectedDetail(detailId);
        }
    };

    return (
        <>
            <table className='table table-stripped min-w-[970px]'>
                <thead>
                    <tr>
                        <th>{t('table.tabColDate')}</th>
                        <th>{t('table.tabColSite')}</th>
                        <th>{t('table.tabColCashbox')}</th>
                        <th>{t('table.tabColEquipment')}</th>
                        <th>{t('table.tabColStatus')}</th>
                        <th>{t('table.tabColPickuper')}</th>
                        <th>{t('table.tabColPaymentType')}</th>
                        <th>{t('table.tabColPickupAmount')}</th>
                        <th>{t('table.tabColPickupCount')}</th>
                        <th>{t('table.tabColPickupDifference')}</th>
                        <th>{t('table.tabColState')}</th>

                        {verifyPermission && (
                            <PermissionFadeContainer
                                permission={verifyGroupPermission}
                            >
                                <th>{t('table.tabColPickup')}</th>
                            </PermissionFadeContainer>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {statements?.map((statement) => (
                        <tr key={statement?.id}>
                            <td>{dateFormat(statement?.date)}</td>
                            {/*afficher le nom du site */}
                            <td>
                                {getSiteForCashboxes(statement?.cash_box?.id) ||
                                    statement?.cash_box?.id}
                            </td>
                            <td>
                                {statement?.cash_box?.name
                                    ? statement?.cash_box?.name
                                    : t('table.tabColCashbox') +
                                      ' n° ' +
                                      statement?.cash_box?.id}
                            </td>
                            <td className='!p-0'>
                                {statement?.equipment_statements?.map(
                                    (eq_statement, index) => (
                                        <MapTableCell
                                            index={index}
                                            table={
                                                statement?.equipment_statements
                                            }
                                        >
                                            {eq_statement?.equipment?.name}
                                        </MapTableCell>
                                    ),
                                )}
                            </td>
                            <td className='!p-0'>
                                {statement?.equipment_statements?.map(
                                    (eq_statement, index) => (
                                        <MapTableCell
                                            index={index}
                                            table={
                                                statement?.equipment_statements
                                            }
                                        >
                                            {renderEquipmentStatementStatusIcon(
                                                eq_statement?.status,
                                            )}
                                        </MapTableCell>
                                    ),
                                )}
                            </td>
                            <td>
                                {statement?.identification?.label?.replace(
                                    'Utilisateur: ',
                                    '',
                                )}
                            </td>
                            <td className='!p-0'>
                                {statement?.details?.map((detail, index) => (
                                    <MapTableCell
                                        index={index}
                                        table={statement?.details}
                                    >
                                        {t(
                                            eCdbsPaymentType[
                                                detail?.payment_type
                                            ],
                                        )}
                                    </MapTableCell>
                                ))}
                            </td>
                            <td>
                                {statement?.details?.map((detail, index) => (
                                    <MapTableCell
                                        index={index}
                                        table={statement?.details}
                                    >
                                        {detail?.amount_cashbox
                                            ? amountFormatWithCurrencySymbol(
                                                  detail?.amount_cashbox,
                                              )
                                            : '-'}
                                    </MapTableCell>
                                ))}
                            </td>
                            <td>
                                {statement?.details?.map((detail, index) => (
                                    <MapTableCell
                                        index={index}
                                        table={statement?.details}
                                    >
                                        {detail?.amount_cashbox_counted == null
                                            ? '-'
                                            : amountFormatWithCurrencySymbol(
                                                  detail?.amount_cashbox_counted,
                                              )}
                                    </MapTableCell>
                                ))}
                            </td>
                            <td>
                                {statement?.details?.map((detail, index) => {
                                    const { value, colorDifference } =
                                        getStatementDifference(
                                            detail?.amount_cashbox,
                                            detail?.amount_cashbox_counted,
                                        );
                                    return (
                                        <MapTableCell
                                            index={index}
                                            table={statement?.details}
                                        >
                                            <span className={colorDifference}>
                                                {value}
                                            </span>
                                        </MapTableCell>
                                    );
                                })}
                            </td>
                            <td>
                                {statement?.details?.map((detail, index) => (
                                    <MapTableCell
                                        index={index}
                                        table={statement?.details}
                                    >
                                        {renderStatementDifferenceIcon(
                                            detail?.amount_cashbox,
                                            detail?.amount_cashbox_counted,
                                        )}
                                    </MapTableCell>
                                ))}
                            </td>

                            {verifyPermission && (
                                <PermissionFadeContainer
                                    permission={verifyGroupPermission}
                                    opacity={70}
                                >
                                    <td className='!p-0'>
                                        {statement?.details?.map(
                                            (detail, index) => (
                                                <MapTableCell
                                                    index={index}
                                                    table={statement?.details}
                                                >
                                                    <div className='relative'>
                                                        <div
                                                            key={index}
                                                            className={`flex flex-col items-center w-8 m-4 chevron-down ${
                                                                expandedChevrons[
                                                                    `${statement.id}-${detail.id}`
                                                                ]
                                                                    ? 'expanded'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    '/img/design/chevronDown.svg'
                                                                }
                                                                style={{
                                                                    transform:
                                                                        expandedChevrons[
                                                                            `${statement.id}-${detail.id}`
                                                                        ]
                                                                            ? 'rotate(-90deg)'
                                                                            : 'rotate(0deg)',
                                                                }}
                                                                onClick={() =>
                                                                    handlePickupDetail(
                                                                        statement?.id,
                                                                        detail?.id,
                                                                    )
                                                                }
                                                                className='cursor-pointer'
                                                            />
                                                        </div>
                                                        {expandedChevrons[
                                                            `${statement.id}-${detail.id}`
                                                        ] &&
                                                            selectedStatement ===
                                                                statement.id &&
                                                            selectedDetail ===
                                                                detail.id && (
                                                                <PickupDetails
                                                                    statementId={
                                                                        selectedStatement
                                                                    }
                                                                    detailId={
                                                                        selectedDetail
                                                                    }
                                                                    onClose={() => {
                                                                        onClose();
                                                                        refreshData();
                                                                    }}
                                                                />
                                                            )}
                                                    </div>
                                                </MapTableCell>
                                            ),
                                        )}
                                    </td>
                                </PermissionFadeContainer>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            {statements?.length === 0 && (
                <NoData text={t('pickupsTable.noResults')} />
            )}
        </>
    );
}
