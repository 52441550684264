import React, {
    useEffect,
    useState,
    useRef,
    cloneElement,
    isValidElement,
    Children,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_sites_by_group_paginated } from '../../../modManager/slices/managerSlice';

import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Icon } from 'leaflet';
import SiteMarker from '../../components/siteMarker/SiteMarker';
import { Spinner } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import i18next from 'i18next';

export default function SitesMapPage() {
    const { sites } = useSelector((state) => state.manager);
    const { groupId, loadingGroup } = useSelector((state) => state.manager);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            dispatch(get_sites_by_group_paginated({ groupId }));
        }
        setIsLoading(false);
        if (groupId) {
            fetchData();
        }
    }, [groupId, i18next.language]);

    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <div>
                    <MapContainer
                        className='position-relative  w-full h-screen z-0'
                        center={[46, 4]}
                        zoom={6}
                        minZoom={4}
                        scrollWheelZoom={true}
                    >
                        <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />

                        {sites?.map((site) => (
                            <SiteMarker site={site} key={'marker' + site.id} />
                        ))}
                    </MapContainer>
                </div>
            )}
        </>
    );
}
