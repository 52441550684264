import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGet, httpPut, httpPost } from '../../modUtils/apis/httpConfig';
import { getRefreshToken } from '../../modUtils/functions/handleTokens';
//récupérer tous les sites et leurs équipmeents par groupId
export const get_equipments_gb_sites_by_group_paginated = createAsyncThunk(
    'mod_equipment/get_equipments_gb_sites_by_group_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `/mod_equipment/groups/${values.groupId}/sites_equipments/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                        equipments__type: values.equipment__type
                            ? values.equipment__type
                            : null,
                        equipments__is_connected:
                            values.equipment__is_connected,
                        equipments__search: values.equipment__search,
                        equipments__state: values.equipment__state,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const countEquipmentStatus = (sites) => {
    let connectedCount = 0;
    let disconnectedCount = 0;
    let workingCount = 0;
    let errorCount = 0;
    let outCount = 0;

    sites.forEach((site) => {
        site.equipments.forEach((equipment) => {
            if (equipment.is_connected == true) {
                connectedCount++;
            } else {
                disconnectedCount++;
            }
            if (equipment.out_of_order) {
                outCount++;
            } else if (
                !equipment.out_of_order &&
                equipment.devices_error_count > 0
            ) {
                errorCount++;
            } else {
                workingCount++;
            }
        });
    });

    return {
        connectedCount,
        disconnectedCount,
        workingCount,
        errorCount,
        outCount,
    };
};

//récupérer les types d'équipement d'un groupe
export const get_types_by_group_paginated = createAsyncThunk(
    'mod_equipment/get_types_by_group_paginated',
    async (groupId) => {
        const response = await httpGet(
            `/mod_equipment/groups/${groupId}/types`,
            {
                params: { limit: 100, offset: 0 },
            },
        );
        return response.data.results;
    },
);

//récupérer les périphériques d'un équipement par equipmentId lié à un groupeId
export const get_devices_by_equipment_by_group_list = createAsyncThunk(
    'mod_equipment/get_devices_by_equipment_by_group_list',
    async (values) => {
        const response = await httpGet(
            `/mod_equipment/groups/${values.groupId}/equipments/${values.equipmentId}/devices`,
            {
                //la limite 500 est donnée arbitrairement car pour l'instant l'appel est paginé or cela n'a pas de sens
                // pour cette fonctionnalité
                //TODO #A MODIFIER AVEC l'API Sans paginated
                params: {
                    limit: 500,
                    offset: 0,
                    status: values.status,
                },
            },
        );
        return response.data.results;
    },
);

//récupérer les événements d'un équipement par equipmentId lié à un groupeId
export const get_events_by_equipment_by_group_paginated = createAsyncThunk(
    'mod_equipment/get_events_by_equipment_by_group_paginated',
    async (values) => {
        const response = await httpGet(
            `/mod_equipment/groups/${values.groupId}/equipments/${values.equipmentId}/events/`,
            {
                params: {
                    limit: values.limit,
                    offset: values.offset,
                    ordering: values.ordering,
                },
            },
        );
        return response.data;
    },
);

//récupérer l'historique des péripheriques d'un equipment par equipmentId lié à un groupeId
export const get_device_histories_by_equipment_by_group_paginated =
    createAsyncThunk(
        'mod_equipment/get_device_histories_by_equipment_by_group_paginated',
        async (values) => {
            const response = await httpGet(
                `/mod_equipment/groups/${values.groupId}/equipments/${values.equipmentId}/histories`,
                { params: { limit: values.limit, offset: values.offset } },
            );
            return response.data;
        },
    );

//récupérer l'historique d'un périphérique par deviceId d'un equipment par equipmentId lié à un groupeId
export const get_device_histories_by_device_by_equipment_by_group_paginated =
    createAsyncThunk(
        'mod_equipment/get_device_histories_by_device_by_equipment_by_group_paginated',
        async (values) => {
            const response = await httpGet(
                `/mod_equipment/groups/${values.groupId}/equipments/${values.equipmentId}/devices/${values.deviceId}/histories`,
                { params: { limit: values.limit, offset: values.offset } },
            );

            return response.data.results;
        },
    );

// modifier un équipement
export const update_equipment_detail_by_group = createAsyncThunk(
    'mod_equipment/update_equipment_detail_by_group',
    async (values) => {
        const response = await httpPut(
            `mod_equipment/groups/${values.groupId}/equipments/${values.equipmentId}`,
            {
                name: values.name,
                ip_wan: values.ipWan,
                port_access_wan: values.portAccessWan,
            },
        );
        return response.data;
    },
);

//Créer le lien de portail vers l'objet. L'authentification est transféré sur le portail.
export const create_access_link = createAsyncThunk(
    'mod_equipment/create_access_link',
    async (values) => {
        let tokenRefresh = getRefreshToken();
        const response = await httpPost(
            `mod_equipment/groups/${values.groupId}/equipments/${values.equipmentId}/access`,
            { token_refresh: tokenRefresh, back_link: values.backLink },
        );
        return response.data;
    },
);

const equipmentSlice = createSlice({
    name: 'equipment',
    initialState: {
        equipment: [],
        loading: true,
        equipmentDevices: [],
        equipmentEvents: [],
        equipmentDevicesHistory: [],
        deviceHistory: [],
        equipmentTypeGroup: [],
        sitesAndEquipment: [],
        countEquipmentStatus: {},
        equipmentLink: [],
    },
    extraReducers: (builder) => {
        builder
            //  get_equipments_gb_sites_by_group_paginated : récupérer tous les sites et leurs équipements par groupId
            .addCase(
                get_equipments_gb_sites_by_group_paginated.pending,
                (state, action) => {
                    state.sitesAndEquipment = [];
                    state.loading = true;
                },
            )
            .addCase(
                get_equipments_gb_sites_by_group_paginated.fulfilled,
                (state, action) => {
                    state.sitesAndEquipment = action.payload.results;
                    state.countEquipmentStatus = countEquipmentStatus(
                        action.payload.results,
                    );
                    state.loading = false;
                },
            )
            .addCase(
                get_equipments_gb_sites_by_group_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            // get_types_by_group_paginated : récupérer les types d'équipement d'un groupe
            .addCase(get_types_by_group_paginated.pending, (state, action) => {
                state.equipmentTypeGroup = action.payload;
            })
            .addCase(
                get_types_by_group_paginated.fulfilled,
                (state, action) => {
                    state.equipmentTypeGroup = action.payload;
                    state.loading = false;
                },
            )
            .addCase(get_types_by_group_paginated.rejected, (state, action) => {
                state.loading = false;
            })
            // mettre à jour un  équipement
            .addCase(
                update_equipment_detail_by_group.fulfilled,
                (state, action) => {
                    // const index = state.equipment.findIndex(
                    //   (equipment) => equipment.id === action.payload.id
                    // );
                    // state.equipment[index] = action.payload;
                    state.loading = false;
                },
            )
            .addCase(
                update_equipment_detail_by_group.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            // get_devices_by_equipment_by_group_list : récupérer les périphériques d'un équipement par equipmentId lié à un groupeId
            .addCase(
                get_devices_by_equipment_by_group_list.pending,
                (state, action) => {
                    state.equipmentDevices = [];
                },
            )
            .addCase(
                get_devices_by_equipment_by_group_list.fulfilled,
                (state, action) => {
                    state.equipmentDevices = action.payload;
                    state.loading = false;
                },
            )
            .addCase(
                get_devices_by_equipment_by_group_list.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            // get_events_by_equipment_by_group_paginated :récupérer les événements d'un équipement par equipmentId lié à un groupeId
            .addCase(
                get_events_by_equipment_by_group_paginated.fulfilled,
                (state, action) => {
                    state.equipmentEvents = action.payload.results;
                    state.loading = false;
                },
            )
            .addCase(
                get_events_by_equipment_by_group_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            // get_device_histories_by_equipment_by_group_paginated :récupérer l'historique des péripheriques d'un equipment par equipmentId lié à un groupeId
            .addCase(
                get_device_histories_by_equipment_by_group_paginated.pending,
                (state, action) => {
                    state.equipmentDevicesHistory = [];
                },
            )
            .addCase(
                get_device_histories_by_equipment_by_group_paginated.fulfilled,
                (state, action) => {
                    state.equipmentDevicesHistory = action.payload.results;
                    state.loading = false;
                },
            )
            .addCase(
                get_device_histories_by_equipment_by_group_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            // get_device_histories_by_device_by_equipment_by_group_paginated :récupérer l'historique d'un périphérique par deviceId d'un equipment par equipmentId lié à un groupeId
            .addCase(
                get_device_histories_by_device_by_equipment_by_group_paginated.pending,
                (state, action) => {
                    state.deviceHistory = [];
                },
            )
            .addCase(
                get_device_histories_by_device_by_equipment_by_group_paginated.fulfilled,
                (state, action) => {
                    state.deviceHistory = action.payload;
                    state.loading = false;
                },
            )
            .addCase(
                get_device_histories_by_device_by_equipment_by_group_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            .addCase(create_access_link.fulfilled, (state, action) => {
                state.equipmentLink = action.payload.url;
                state.loading = false;
            })
            .addCase(create_access_link.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export default equipmentSlice.reducer;
