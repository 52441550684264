import React from 'react';
import EquipmentCard from '../equipmentCard/EquipmentCard';

export default function EquipmentList({ siteId, siteName, equipmentList }) {
    return (
        <>
            {equipmentList?.map((equipment) => (
                <EquipmentCard
                    siteId={siteId}
                    siteName={siteName}
                    equipment={equipment}
                />
            ))}
        </>
    );
}
