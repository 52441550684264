import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Input, message } from 'antd';
import { eEquipmentType } from 'ck_commun/src/commun_global/enums.js';
import { periods } from '../functions/handleDaysOfWeek';
import { create_constraint_financial } from '../slices/constraintFinancialSlice';
import FormLegend from '../../modUtils/components/FormLegend';
import AlertMessage from '../../modUtils/components/AlertMessage';
import {
    Modal,
    Select,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';

export default function AddFinancialConstraintModal({
    isModalVisible,
    setIsModalVisible,
    selectedConfigId,
    setLoadingTable,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);
    const [form] = Form.useForm();

    // options Select
    const transformedData = Object.entries(eEquipmentType).map(
        ([key, value], index) => ({
            label: t(value),
            value: parseInt(key),
        }),
    );
    // Ajout de la première valeur { label: "Tous", value: null }
    transformedData.unshift({
        label: t('addFinancialConstraintModal.all'),
        value: null,
    });

    // handle change select equipment type
    const handleEquipmentTypeChange = (value) => {
        form.setFieldsValue({ equipmentType: value });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        form.submit();
    };

    // handle select period type
    const handleSelectPeriodType = (selectedValue) => {
        form.setFieldsValue({ periodType: selectedValue });
    };

    // ajouter nouvelle contrainte finanicère
    const addNewFinancialConstraint = async (values) => {
        try {
            setLoadingTable(true);
            await dispatch(
                create_constraint_financial({
                    groupId,
                    configurationId: selectedConfigId,
                    ...values,
                }),
            ).unwrap();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de l'ajout d'une nouvelle contrainte financière !`}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoadingTable(false);
            setIsModalVisible(false);
        }
    };

    useEffect(() => {
        form.resetFields();
    }, [groupId, form]);

    // fonction appelée quand on envoie le formulaire
    const onFinish = (values) => {
        addNewFinancialConstraint(values);
    };

    return (
        <Modal
            title={t('addFinancialConstraintModal.addFinancialConstraint')}
            isOpen={isModalVisible}
            onClose={handleCancel}
            onCancel={handleCancel}
            onOk={handleOk}
        >
            <Form form={form} onFinish={onFinish} layout='vertical'>
                <div className='flex flex-col items-center'>
                    <div className='grid grid-cols-2 gap-0 md:gap-4'>
                        <div className='col-span-2 lg:col-span-1'>
                            <Form.Item
                                label={t(
                                    'addFinancialConstraintModal.equipmentType',
                                )}
                                name='equipmentType'
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'addFinancialConstraintModal.pleaseSelectDeviceType',
                                        ),
                                        // Validation personnalisée pour accepter null
                                        validator: (_, value) => {
                                            if (value !== null) {
                                                return Promise.resolve();
                                            }
                                            // Si "Tous" est sélectionné (null), le formulaire peut toujours passer
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Select
                                    options={transformedData}
                                    placeholder={t(
                                        'addFinancialConstraintModal.equipmentType',
                                    )}
                                    onSelect={handleEquipmentTypeChange}
                                />
                            </Form.Item>
                        </div>

                        <div className='col-span-2 lg:col-span-1'>
                            <Form.Item
                                label={t(
                                    'addFinancialConstraintModal.periodType',
                                )}
                                name='periodType'
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'addFinancialConstraintModal.pleaseSelectPeriodType',
                                        ),
                                    },
                                ]}
                            >
                                <Select
                                    options={periods}
                                    placeholder={t(
                                        'addFinancialConstraintModal.periodType',
                                    )}
                                    onSelect={handleSelectPeriodType}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <Form.Item
                        label={t('addFinancialConstraintModal.limitIn')}
                        name='amountMax'
                        rules={[
                            {
                                required: true,
                                message: t(
                                    'addFinancialConstraintModal.pleaseEnterTheLimit',
                                ),
                            },
                            {
                                validator: (_, value) => {
                                    const regex = /^\d{1,4}(\.\d{0,2})?$/; // Vérifie 4 chiffres avant la virgule et jusqu'à 2 après
                                    if (!value || regex.test(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(
                                            t(
                                                'addFinancialConstraintModal.invalidAmount',
                                            ),
                                        ),
                                    );
                                },
                            },
                        ]}
                    >
                        <Input
                            placeholder=''
                            type='text' // Utiliser "text" pour permettre la validation avec regex
                            className='w-40'
                        />
                    </Form.Item>
                </div>

                <FormLegend requiredFormItemLegend={true} />
            </Form>
        </Modal>
    );
}
