/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/payment/e_cdbs_payment_type.py
Aucune modification manuelle acceptée
*/

// Enumération des types de paiement
const eCdbsPaymentType = Object.freeze({
  // Pièce
  0: 'eCdbsPaymentType.coin',

  // Jeton
  1: 'eCdbsPaymentType.token',

  // Badge
  2: 'eCdbsPaymentType.badge',

  // CB
  3: 'eCdbsPaymentType.cb',

  // Billet
  4: 'eCdbsPaymentType.bill',

  // Code
  5: 'eCdbsPaymentType.code',

  // Dépannage. Anciennement TYPE_FREE en C ou TYPE_REPAIR en c++
  6: 'eCdbsPaymentType.troubleshoot',

  // Test
  7: 'eCdbsPaymentType.test',

  // Exploitant
  8: 'eCdbsPaymentType.manager',

  // Mobile
  9: 'eCdbsPaymentType.mobile',

  // Rendu de monnaie. Donc on rends des pièces, et pas des jetons(sinon on ne peut pas calculer la taxe, car on ne connait pas la source).Le serveur considère donc toujours que c'est des pièces
  10: 'eCdbsPaymentType.repay',

  // CB Sans-contact
  11: 'eCdbsPaymentType.cbSc',

  // Serveur
  12: 'eCdbsPaymentType.server',

  // Promotion. Anciennement TYPE_PROMO
  13: 'eCdbsPaymentType.offer',

  // Prévente local
  14: 'eCdbsPaymentType.localPresale',

  // Badge facturation
  15: 'eCdbsPaymentType.badgeInvoicing',

  // Prépaiement
  16: 'eCdbsPaymentType.prepayment',

  // Anciennement TYPE_OFFER. Ne plus utiliser ce type car fesait doublon. Est remplacé par OFFER
  17: 'eCdbsPaymentType.offerDoNotUse',

  // Web externe
  20: 'eCdbsPaymentType.webExterne',

  // Paiement référençant tous ce qui n'a pas été payé à cause de l'utilisation d'un abonnement. L'utilisateur paie un abonnement mensuel, mais ne paie plus certain service sur l'équipement. Cette quantité non payé est stocké ici.
  21: 'eCdbsPaymentType.subscription',

  // Espèces
  40: 'eCdbsPaymentType.cash',

  // Chèque
  41: 'eCdbsPaymentType.cheque',

  // Personnalisé 1
  50: 'eCdbsPaymentType.custom1',

  // Personnalisé 2
  51: 'eCdbsPaymentType.custom2',

  // Personnalisé 3
  52: 'eCdbsPaymentType.custom3',

  // Personnalisé 4
  53: 'eCdbsPaymentType.custom4',

  // Personnalisé 5
  54: 'eCdbsPaymentType.custom5',

  // Échange
  60: 'eCdbsPaymentType.swap',

  // Transfert
  61: 'eCdbsPaymentType.transfert',

  // Effacement
  62: 'eCdbsPaymentType.erase',

  // Payment permettant d'annuler une vente.
  // On considère que la vente annulée est une vente avec des produits où la tva à
  // été payé.
  70: 'eCdbsPaymentType.cancel',

  // CB Web
  99: 'eCdbsPaymentType.cbWeb',

  // Donne les informations de souhait de l'envoie d'un ticket
  242: 'eCdbsPaymentType.ticket',

  // Les données du QR code généré
  243: 'eCdbsPaymentType.qrCode',

  // Ck Sys Code
  244: 'eCdbsPaymentType.cksSyscode',

  // Information marketing (effectué à des fins de statistiques)
  // le marketing correspond au menu proposé/affiché à l'utilisateur lors d'une sélection
  245: 'eCdbsPaymentType.marketing',

  // Envoie un produit et non une sélection
  246: 'eCdbsPaymentType.targetSub',

  // L'équipement envoie les informations d'identification de la personne qui a généré
  // cet historique
  247: 'eCdbsPaymentType.infoAccount',

  // Information CB, contient les informations d'une transaction bancaire. Soit tout est envoyé ici (QT), soit divisé en section (nouveau C), soit en plusieurs info CB (ancien C)
  248: 'eCdbsPaymentType.infoCb',

  // Information CB2, n'est et ne doit pas être utilisé sur les nouveaux équipements
  249: 'eCdbsPaymentType.infoCb2',

  // Information CB3, n'est et ne doit pas être utilisé sur les nouveaux équipements
  250: 'eCdbsPaymentType.infoCb3',

  // Information CB4, n'est et ne doit pas être utilisé sur les nouveaux équipements
  251: 'eCdbsPaymentType.infoCb4',

  // Trame donnant la cible de la vente
  252: 'eCdbsPaymentType.target',

  // Identification.
  // Donne l'identification de celui ayant effectué le paiement
  253: 'eCdbsPaymentType.identification',

  // Relevé.
  // La borne envoie tous ses relevés(cumule) des paiements depuis la dernière fois.
  // Ceci est déclanchée par l'exploitant
  254: 'eCdbsPaymentType.statement',

  // Inconnu
  255: 'eCdbsPaymentType.unknown',
});

export default eCdbsPaymentType;
