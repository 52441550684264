import React from 'react';
import { ReactComponent as BurgerMenuSvg } from 'ck_commun/src/app/modUtils/assets/images/nav/burger-menu.svg';

export default function BurgerButton({ onClick }) {
    return (
        <div onClick={onClick} className='fixed top-4 right-4 z-[1000]'>
            <BurgerMenuSvg />
        </div>
    );
}
