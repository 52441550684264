import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEllipsisV } from 'react-icons/fa';
import { ReactComponent as SwitcherExpanded } from '../../../modEntity/assets/images/minus-square-primary.svg';
import { ReactComponent as SwitcherDefault } from '../../../modEntity/assets/images/plus-square-primary.svg';
import AddConfigurationModal from '../AddConfigurationModal';
import DropdownActionsMenu from '../../../modUtils/components/dropdownActionsMenu/DropdownActionsMenu';
import './ProfilNode.scss';

export default function ProfilNode({
    profilData,
    onClick,
    saveSelectedProfilCallback,
    expandedProfilId,
}) {
    const { t } = useTranslation();
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isAddConfigrationModalVisible, setIsAddConfigurationModalVisible] =
        useState();

    // traitement de callback pour renvoyer l'id du profil sélectionné au parent composant ProfilList
    const handleOnClickProfilNode = (profilId) => {
        onClick(profilId);
        saveSelectedProfilCallback(profilId);
    };

    const menuItems = [
        <p onClick={() => setIsAddConfigurationModalVisible(true)}>
            {t('profilNode.addConfiguration')}
        </p>,
    ];

    // fermer le popover
    const hide = () => {
        setIsMenuVisible(false);
    };

    // TODO CH FONCTION POSSIBLE DANS LE MOD UTILE
    const toPascalCase = (str) => {
        if (!str) return ''; // Si la chaîne est vide ou null, retourner une chaîne vide
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <>
            <div
                onClick={() => handleOnClickProfilNode(profilData?.id)}
                className='profil-tree-node-container flex items-center'
            >
                <div
                    className={`profil-tree-node p-2 mb-1.5 min-w-[256px] max-w-[256px]
                    border-2 border-solid rounded-md
                    ${expandedProfilId == profilData.id ? 'border-error-color' : 'border-primary-color'}
                `}
                >
                    <div className=' flex items-center justify-between'>
                        <div className='flex items-center line-clamp-1 mr-2'>
                            <div className='mr-4 w-6'>
                                <img
                                    src={profilData?.service_type_image}
                                    alt={profilData?.service_type_label}
                                />
                            </div>

                            <div className='font-bold text-sm'>
                                {toPascalCase(profilData?.name)}
                            </div>
                        </div>

                        <div className='cursor-pointer w-6'>
                            {profilData.configurations_count !== 0 &&
                                (expandedProfilId == profilData.id ? (
                                    <SwitcherExpanded />
                                ) : (
                                    <SwitcherDefault />
                                ))}
                        </div>
                    </div>
                </div>

                <div className='ml-2 w-1'>
                    <DropdownActionsMenu
                        trigger='hover'
                        triggerImg={
                            <FaEllipsisV className='text-primary-color' />
                        }
                        placement={'right'}
                        menuItems={menuItems}
                        visible={isMenuVisible}
                        handleVisibleChange={() =>
                            setIsMenuVisible(!isMenuVisible)
                        }
                    />
                </div>
            </div>

            {isAddConfigrationModalVisible && (
                <AddConfigurationModal
                    isModalVisible={isAddConfigrationModalVisible}
                    setIsModalVisible={setIsAddConfigurationModalVisible}
                    profil={profilData}
                    closePopover={hide}
                />
            )}
        </>
    );
}
