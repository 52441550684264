import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    httpGet,
    httpPost,
    httpPut,
} from 'ck_commun/src/app/modUtils/apis/httpConfig';

// retourner la liste de toutes les entités entity_by_group_paginated_get
export const get_entity_paginated = createAsyncThunk(
    'mod_entity/get_entity_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_entity/groups/${values.groupId}/entities/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                        only_parent: values.onlyParent,
                        name: values.name,
                        type: values.type,
                        status: values.status,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// retourner la liste de toutes les entités entity_by_group_paginated_get
export const get_entity_paginated_selected = createAsyncThunk(
    'mod_entity/get_entity_paginated_selected',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_entity/groups/${values.groupId}/entities/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                        only_parent: values.onlyParent,
                        name: values.name,
                        type: values.type,
                        status: values.status,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// retourner le détail d'une entité n'importe quel est son type
export const get_entity_detail = createAsyncThunk(
    'mod_entity/get_entity_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_entity/groups/${values.groupId}/entities/${values.entityId}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

//******************************************************************* ENTITE CLIENT********************************************** */
// retourner la liste de toutes les entités client
export const get_entity_customers_paginated = createAsyncThunk(
    'mod_entity/get_entity_customers_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_entity/groups/${values.groupId}/entity_customers`,
                {
                    params: {
                        limit: 100,
                        offset: 0,
                        name: values.name,
                        ordering: values.ordering,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// créer une entité de type client
export const create_entity_customers = createAsyncThunk(
    'mod_entity/create_entity_customers',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_entity/groups/${values.groupId}/entity_customers/`,
                {
                    first_name: values.firstName,
                    name: values.name,
                    email: values.email,
                    date_of_birth: values.dateOfBirth,
                    user: values.user,
                    entity: values.entity,
                    phone_mobile: values.phoneMobile,
                    location_number: values.locationNumber,
                    location_street: values.locationStreet,
                    location_additional: values.locationAdditional,
                    location_city: values.locationCity,
                    status: values.status,
                },
            );

            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// modifier entité de type client
export const update_entity_customer_detail = createAsyncThunk(
    'mod_entity/update_entity_customer_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPut(
                `mod_entity/groups/${values.groupId}/entity_customers/${values.entityCustomerId}/`,
                {
                    first_name: values.firstName,
                    name: values.name,
                    email: values.email,
                    date_of_birth: values.dateOfBirth,
                    user: values.user,
                    entity: values.entity,
                    phone_mobile: values.phoneMobile,
                    location_number: values.locationNumber,
                    location_street: values.locationStreet,
                    location_additional: values.locationAdditional,
                    location_city: values.locationCity,
                    status: values.status, // Enum:[(0, 'activé'), (1, 'bloqué'), (2, 'desactivé')]
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

//******************************************************************* ENTITE SOCIETE ***********************************************/
// retourner la liste de toutes les entités société
export const get_entity_companies_paginated = createAsyncThunk(
    'mod_entity/get_entity_companies_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_entity/groups/${values.groupId}/entity_companies`,
                {
                    params: {
                        limit: 100,
                        offset: 0,
                        name: values.name,
                        ordering: values.ordering,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// créer entité de type société
export const create_entity_companies = createAsyncThunk(
    'mod_entity/create_entity_companies',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_entity/groups/${values.groupId}/entity_companies/`,
                {
                    name: values.name,
                    email: values.email,
                    siret: values.siret,
                    entity: values.entity,
                    location_number: values.locationNumber,
                    location_street: values.locationStreet,
                    location_additional: values.locationAdditional,
                    location_city: values.locationCity,
                    status: values.status,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// modifier entité de type société
export const update_entity_company_detail = createAsyncThunk(
    'mod_entity/update_entity_company_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPut(
                `mod_entity/groups/${values.groupId}/entity_companies/${values.entityCompanyId}/`,
                {
                    name: values.name,
                    email: values.email,
                    siret: values.siret,
                    entity: values.entity,
                    location_number: values.locationNumber,
                    location_street: values.locationStreet,
                    location_additional: values.locationAdditional,
                    location_city: values.locationCity,
                    status: values.status,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

//******************************************************************* ENTITE GROUPE ********************************************** */
// retourner la liste de toutes les entités group
export const get_entity_groups_paginated = createAsyncThunk(
    'mod_entity/get_entity_groups_paginated',
    async (values) => {
        const response = await httpGet(
            `mod_entity/groups/${values.groupId}/entity_groups`,
            {
                params: {
                    limit: 100,
                    offset: 0,
                    name: values.name,
                    ordering: values.ordering,
                },
            },
        );
        return response.data;
    },
);

// créer entité de type group
export const create_entity_groups = createAsyncThunk(
    'mod_entity/create_entity_groups',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_entity/groups/${values.groupId}/entity_groups/`,
                {
                    name: values.name,
                    entity: values.entity,
                    status: values.status,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// modifier entité de type group
export const update_entity_group_detail = createAsyncThunk(
    'mod_entity/update_entity_group_detail',
    async (values) => {
        const response = await httpPut(
            `mod_entity/groups/${values.groupId}/entity_groups/${values.entityGroupId}/`,
            {
                name: values.name,
                entity: values.entity,
                status: values.status,
            },
        );
        return response.data;
    },
);

const entitySlice = createSlice({
    name: 'entity',
    initialState: {
        entities: [],
        entitiesCount: 0,
        entitiesSelected: [],
        entityDetail: {},
        entityCustomerDetail: {},
        entityGroupDetail: {},
        entityCompanyDetail: {},
        entitiesCompany: [],
        entitiesGroup: [],
        entitiesCustomer: [],
        loading: true,
    },
    extraReducers: (builder) => {
        builder
            /* get_entity_paginated */
            .addCase(get_entity_paginated.pending, (state, action) => {
                state.entities = [];
            })
            .addCase(get_entity_paginated.fulfilled, (state, action) => {
                state.loading = false;
                state.entities = action.payload.results;
                state.entitiesCount = action.payload.count;
            })
            .addCase(get_entity_paginated.rejected, (state, action) => {
                state.loading = false;
            })

            /* get_entity_paginated_selected */
            .addCase(get_entity_paginated_selected.pending, (state, action) => {
                state.entitiesSelected = [];
            })
            .addCase(
                get_entity_paginated_selected.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.entitiesSelected = action.payload.results;
                },
            )
            .addCase(
                get_entity_paginated_selected.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* get_entity_detail */
            .addCase(get_entity_detail.pending, (state, action) => {
                state.entityDetail = {};
            })
            .addCase(get_entity_detail.fulfilled, (state, action) => {
                state.loading = false;
                state.entityDetail = action.payload;
            })
            .addCase(get_entity_detail.rejected, (state, action) => {
                state.loading = false;
            })

            //******************************************************************* ENTITE CLIENT********************************************** */
            /* get_entity_customers_paginated */
            .addCase(
                get_entity_customers_paginated.pending,
                (state, action) => {
                    state.entitiesCustomer = [];
                },
            )
            .addCase(
                get_entity_customers_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.entitiesCustomer = action.payload.results;
                },
            )
            .addCase(
                get_entity_customers_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* create_entity_customers */
            .addCase(create_entity_customers.fulfilled, (state, action) => {
                state.loading = false;
                state.entitiesCustomer = [
                    ...state.entitiesCustomer,
                    action.payload,
                ];
                const newEntity = {
                    id: action.payload.id,
                    name: action.payload.name,
                    created_at: action.payload.created_at,
                    entity_group: null,
                    entity_customer: action.payload,
                    entity_company: null,
                };
                state.entities = [...state.entities, newEntity];
            })
            .addCase(create_entity_customers.rejected, (state, action) => {
                state.loading = false;
            })

            /* update_entity_customer_detail */
            .addCase(
                update_entity_customer_detail.fulfilled,
                (state, action) => {
                    state.loading = false;

                    // update entitiesCustomer
                    const updatedEntitiesCustomer = state.entitiesCustomer?.map(
                        (entity) =>
                            entity.id === action.payload.id
                                ? action.payload
                                : entity,
                    );
                    state.entitiesCustomer = updatedEntitiesCustomer;

                    // update entities
                    const updatedEntities = state.entities.map((entity) => {
                        if (entity.id === action.payload.id) {
                            return {
                                ...entity,
                                name: action.payload.name,
                                first_name: action.payload.first_name,
                                status: action.payload.status,
                                created_at: action.payload.created_at,
                                entity_customer: action.payload,
                            };
                        } else {
                            return entity;
                        }
                    });
                    state.entities = updatedEntities;

                    // update entityCustomerDetail
                    if (state.entityCustomerDetail.id === action.payload.id) {
                        state.entityCustomerDetail = action.payload;
                    }
                },
            )
            .addCase(
                update_entity_customer_detail.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            //******************************************************************* ENTITE SOCIETE********************************************** */
            /* get_entity_companies_paginated */
            .addCase(
                get_entity_companies_paginated.pending,
                (state, action) => {
                    state.entitiesCompany = [];
                },
            )
            .addCase(
                get_entity_companies_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.entitiesCompany = action.payload.results;
                },
            )
            .addCase(
                get_entity_companies_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* create_entity_companies */
            .addCase(create_entity_companies.fulfilled, (state, action) => {
                state.loading = false;
                state.entitiesCompany = [
                    ...state.entitiesCompany,
                    action.payload,
                ];
                const newEntity = {
                    id: action.payload.id,
                    name: action.payload.name,
                    created_at: action.payload.created_at,
                    entity_group: null,
                    entity_customer: null,
                    entity_company: action.payload,
                };
                state.entities = [...state.entities, newEntity];
            })
            .addCase(create_entity_companies.rejected, (state, action) => {
                state.loading = false;
            })

            /* update_entity_company_detail */
            .addCase(
                update_entity_company_detail.fulfilled,
                (state, action) => {
                    state.loading = false;
                    const updatedElement = action.payload;
                    // update entitiesCompany
                    const updatedEntitiesCompany = state.entitiesCompany.map(
                        (entity) =>
                            entity.id === updatedElement.id
                                ? updatedElement
                                : entity,
                    );
                    state.entitiesCompany = updatedEntitiesCompany;
                    // update entities
                    const updatedEntities = state.entities.map((entity) => {
                        if (entity.id === updatedElement.id) {
                            return {
                                ...entity,
                                name: updatedElement.name,
                                created_at: updatedElement.created_at,
                                entity_company: updatedElement,
                            };
                        } else {
                            return entity;
                        }
                    });
                    state.entities = updatedEntities;
                    // update entityCompanyDetail
                    if (state.entityCompanyDetail.id == updatedElement.id) {
                        state.entityCompanyDetail = updatedElement;
                    }
                },
            )
            .addCase(update_entity_company_detail.rejected, (state, action) => {
                state.loading = false;
            })

            //******************************************************************* ENTITE GROUPE ********************************************** */
            /* get_entity_groups_paginated */
            .addCase(get_entity_groups_paginated.pending, (state, action) => {
                state.entitiesGroup = [];
            })
            .addCase(get_entity_groups_paginated.fulfilled, (state, action) => {
                state.loading = false;
                state.entitiesGroup = action.payload.results;
            })
            .addCase(get_entity_groups_paginated.rejected, (state, action) => {
                state.loading = false;
            })

            /* create_entity_groups */
            .addCase(create_entity_groups.fulfilled, (state, action) => {
                state.loading = false;

                // Vérifie que action.payload est valide
                if (
                    action.payload &&
                    action.payload.id &&
                    action.payload.name
                ) {
                    // Ajoute l'entité au tableau entitiesGroup
                    state.entitiesGroup = state.entitiesGroup
                        ? [...state.entitiesGroup, action.payload]
                        : [action.payload]; // Si le tableau entitiesGroup est vide ou undefined

                    // Crée un nouvel objet entité
                    const newEntity = {
                        id: action.payload.id,
                        name: action.payload.name,
                        created_at: action.payload.created_at,
                        entity_group: action.payload,
                        entity_customer: null, // Valeur par défaut
                        entity_company: null, // Valeur par défaut
                    };

                    // Ajoute le nouvel objet entité au tableau entities
                    state.entities = state.entities
                        ? [...state.entities, newEntity]
                        : [newEntity]; // Si le tableau entities est vide ou undefined
                } else {
                    // console.error("Payload mal formé ou manquant", action.payload);
                }
            })
            .addCase(create_entity_groups.rejected, (state, action) => {
                state.loading = false;
            })

            /* update_entity_group_detail */
            .addCase(update_entity_group_detail.fulfilled, (state, action) => {
                state.loading = false;
                state.entityGroupDetail = action.payload;
            })
            .addCase(update_entity_group_detail.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export default entitySlice.reducer;
