import React from 'react';

export default function FilterSection({ label, values }) {
    // affiche les filtres selectionnés quand on souhaite exporter des données
    return (
        <div>
            <h5 className='m-0'>{label}</h5>
            {values.map((value, index) => (
                <p key={index}>{value}</p>
            ))}
        </div>
    );
}
