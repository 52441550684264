import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LanguageSwitcher from '../languageSwitcher/LanguageSwitcher';
import WeatherCard from 'ck_commun/src/app/modWeather/components/weatherCard/WeatherCard';
import { get_user_details } from 'ck_commun/src/app/modAuthentication/slices/authenticationSlice';
import SelectGroup from '../../../modManager/components/SelectGroup/SelectGroup';
import { hasPermission } from 'ck_commun/src/app/modUtils/functions/handlePermissions';
import PermissionFadeContainer from '../../../modUtils/components/permissionFadeContainer/PermissionFadeContainer';
import './RightSideBar.scss';
import AdminLink from '../adminLink/AdminLink';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export default function RightSideBar({ collapsed }) {
    const dispatch = useDispatch();
    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_weather:show_weather',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_weather:show_weather',
        groupPermissions,
    );
    const [loading, setLoading] = useState(true);
    const { user } = useSelector((state) => state.authentication);
    const { t } = useTranslation();

    useEffect(() => {
        async function fetchData() {
            await dispatch(get_user_details(localStorage.getItem('user')));
            setLoading(false);
        }
        fetchData();
    }, [i18next.language]);

    return (
        <>
            {!collapsed && (
                <div className='flex flex-col p-2.5'>
                    <div className='h-10'>
                        <LanguageSwitcher color='light' />
                    </div>
                    <div className='userName'>
                        {!loading && user.first_name + ' ' + user.last_name}
                    </div>
                    <SelectGroup />
                    {verifyPermission && (
                        <PermissionFadeContainer
                            permission={verifyGroupPermission}
                        >
                            <div className='mt-10'>
                                <WeatherCard />
                            </div>
                        </PermissionFadeContainer>
                    )}
                </div>
            )}
            {!collapsed && (
                <div className='absolute bottom-10 right-0 px-7 w-[inherit] flex flex-col justify-content-center '>
                    <AdminLink label={t('Admin.label')} type='button' />
                </div>
            )}
        </>
    );
}
