import Tree from 'rc-tree';
import EntityNode from '../EntityNode/EntityNode';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { update_entity_group_detail } from '../../slices/entitySlice';
import { Spinner } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import 'rc-tree/assets/index.css';
import './EntityList.scss';
import i18next from 'i18next';

export default function EntityList({
    supportId,
    getEntities,
    nameFilterValue,
    statusFilterValue,
    showClosedAccounts,
    fetchUpdateEntityCustomer,
    saveSelectedEntityCallback,
}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { groupId } = useSelector((state) => state.manager);
    const [expandedEntities, setExpandedEntites] = useState([]);
    const [selectedEntityId, setselectedEntityId] = useState([]);
    const { entities } = useSelector((state) => state.entity);
    const { profilByEntity } = useSelector((state) => state.profil.profil);

    const entityFolder = [
        {
            id: 'parentId',
            entity_type: 'EntityFolder',
            childs: [...entities],
        },
    ];

    useEffect(() => {
        const fetchEntities = async () => {
            setLoading(true);
            if (groupId) {
                await getEntities();
            }
            setExpandedEntites(
                expandedEntities
                    ? ['parentId', ...expandedEntities]
                    : ['parentId'],
            );
            setLoading(false);
        };

        fetchEntities();
    }, [
        groupId,
        nameFilterValue,
        statusFilterValue,
        showClosedAccounts,
        i18next.language,
        profilByEntity,
    ]);

    useEffect(() => {
        if (!loading) {
            const selectedEntity = entities?.find(
                (entity) => entity?.id === selectedEntityId[0],
            );
            selectedEntity && saveSelectedEntityCallback(selectedEntity);
        }
    }, [loading, selectedEntityId, entities]);

    const renderTreeNodes = (nodes) => {
        return nodes?.map((node) => {
            const entityTypeClass =
                node.entity_type === 'EntityCustomer'
                    ? 'drag-over-customer'
                    : node.entity_type === 'EntityGroup'
                      ? 'drag-over-group'
                      : node.entity_type === 'EntityCompany' &&
                        'drag-over-company';
            return (
                <Tree.TreeNode
                    key={node.id}
                    title={
                        <EntityNode
                            entityData={node}
                            saveSelectedEntityCallback={
                                saveSelectedEntityCallback
                            }
                            supportId={supportId}
                            getEntities={getEntities}
                        />
                    }
                    className={`${entityTypeClass}`}
                    disabled={node.id === 'parentId'}
                >
                    {node.childs &&
                        node.childs.length > 0 &&
                        renderTreeNodes(node.childs, true)}
                </Tree.TreeNode>
            );
        });
    };

    async function updateEntityCustomer(entityId, parentEntityId) {
        setLoading(true);
        await fetchUpdateEntityCustomer(entityId, parentEntityId);
        setLoading(false);
    }

    async function updateEntityGroupDetail(entityId, parentEntityId) {
        setLoading(true);
        await dispatch(
            update_entity_group_detail({
                groupId,
                entityGroupId: entityId,
                entity: parentEntityId,
            }),
        );
        await getEntities();
        setLoading(false);
    }

    const onDrop = (info) => {
        const draggedEntityId = info.dragNode.key; // ID de l'entité déplacée
        const dropTargetId = info.node.key; // ID de la cible (entité sur laquelle on dépose)

        if (info.node.title.props.entityData.entity_type === 'EntityFolder') {
            if (
                info.dragNode.title.props.entityData.entity_type ===
                'EntityCustomer'
            ) {
                updateEntityCustomer(draggedEntityId, null);
            } else if (
                info.dragNode.title.props.entityData.entity_type ===
                'EntityGroup'
            ) {
                updateEntityGroupDetail(draggedEntityId, null);
            }
        } else if (dropTargetId) {
            if (
                info.dragNode.title.props.entityData.entity_type ===
                    'EntityCustomer' &&
                info.node.title.props.entityData.entity_type !==
                    'EntityCustomer'
            ) {
                updateEntityCustomer(draggedEntityId, dropTargetId);
            } else if (
                info.dragNode.title.props.entityData.entity_type ===
                    'EntityGroup' &&
                info.node.title.props.entityData.entity_type !==
                    'EntityCustomer'
            ) {
                updateEntityGroupDetail(draggedEntityId, dropTargetId);
            }
        }
    };

    //fonction appelé quand on clique sur un noeud entity
    const handleOnClickEntity = (selectedKeys) => {
        if (selectedKeys[0] !== 'parentId') {
            let selectedkey;

            if (selectedKeys?.length === 0) {
                selectedkey = selectedEntityId[0];
            } else {
                selectedkey = selectedKeys[0];
            }

            setselectedEntityId([selectedkey]);
            //le code au dessous sert à gérer les noeuds étendues
            // Vérifier si la clé sélectionnée est déjà dans les clés étendues
            const index = expandedEntities.indexOf(selectedkey);

            // Si la clé sélectionnée est déjà dans les clés étendues, la retirer
            if (index !== -1) {
                const updatedExpandedKeys = expandedEntities.filter(
                    (key) => key !== selectedkey,
                );
                setExpandedEntites(updatedExpandedKeys);
            } else {
                // Sinon, l'ajouter aux clés étendues
                setExpandedEntites([...expandedEntities, selectedkey]);
            }
        }
    };

    return (
        <>
            {loading ? (
                <div className='mt-12'>
                    <Spinner />
                </div>
            ) : (
                <Tree
                    showLine={true}
                    showIcon={false}
                    switcherIcon={false}
                    expandedKeys={expandedEntities}
                    selectedKeys={selectedEntityId}
                    onSelect={(key) => handleOnClickEntity(key)}
                    draggable
                    onDrop={onDrop}
                >
                    {renderTreeNodes(entityFolder)}
                </Tree>
            )}
        </>
    );
}
