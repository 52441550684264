import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    httpGet,
    httpPost,
    httpDelete,
    httpPut,
} from 'ck_commun/src/app/modUtils/apis/httpConfig';

// retourner la liste des remises de paiement d'une configuration
export const get_discount_payment_paginated = createAsyncThunk(
    'mod_profil/get_discount_payment_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/discount_payment/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Créer une remise de paiement
export const create_discount_payment = createAsyncThunk(
    'mod_profil/create_discount_payment',
    async (values, { rejectWithValue }) => {
        try {
            response = await httpPost(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/discount_payment/`,
                {
                    discount_percent: values.discountPercent,
                    amount_min: values.amountMin,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// retourner le détail d'une remise paiement d'une configuration
export const get_discount_payment_detail = createAsyncThunk(
    'mod_profil/get_discount_payment_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/discount_payment/${values.discountPaymentId}/`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// modifier une remise de paiement
export const update_discount_payment_detail = createAsyncThunk(
    'mod_profil/update_discount_payment_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPut(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/discount_payment/${values.discountPaymentId}/`,
                {
                    discount_percent: values.discountPercent,
                    amount_min: values.amountMin,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Supprimer une remise de paiement
export const delete_discount_payment_detail = createAsyncThunk(
    'mod_profil/delete_discount_payment_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpDelete(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/discount_payment/${values.discountPaymentId}/`,
            );
            return { ...response.data, id: values.discountPaymentId };
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// retourner la liste des remises horaire d'une configuration
export const get_discount_schedule_paginated = createAsyncThunk(
    'mod_profil/get_discount_schedule_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/discount_schedule/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Créer une remise horaire
export const create_discount_schedule = createAsyncThunk(
    'mod_profil/create_discount_schedule',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/discount_schedule/`,
                {
                    discount_percent: values.discountPercent,
                    equipment_type: values.equipmentType,
                    schedule_monday: values.scheduleMonday,
                    schedule_tuesday: values.scheduleTuesday,
                    schedule_wednesday: values.scheduleWednesday,
                    schedule_thursday: values.scheduleThursday,
                    schedule_friday: values.scheduleFriday,
                    schedule_saturday: values.scheduleSaturday,
                    schedule_sunday: values.scheduleSunday,
                    schedule_hours_start: values.scheduleHoursStart,
                    schedule_hours_end: values.scheduleHoursEnd,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// retourner le détail d'une remise horaire d'une configuration
export const get_discount_schedule_detail = createAsyncThunk(
    'mod_profil/get_discount_schedule_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/discount_schedule/${values.discountScheduleId}/`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// modifier une remise horaire
export const update_discount_schedule_detail = createAsyncThunk(
    'mod_profil/update_discount_schedule_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPut(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/discount_schedule/${values.discountScheduleId}/`,
                {
                    discount_percent: values.discountPercent,
                    equipment_type: values.equipmentType,
                    priority: values.priority,
                    schedule_monday: values.scheduleMonday,
                    schedule_tuesday: values.scheduleTuesday,
                    schedule_wednesday: values.scheduleWednesday,
                    schedule_thursday: values.scheduleThursday,
                    schedule_friday: values.scheduleFriday,
                    schedule_saturday: values.scheduleSaturday,
                    schedule_sunday: values.scheduleSunday,
                    schedule_hours_start: values.scheduleHoursStart,
                    schedule_hours_end: values.scheduleHoursEnd,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Supprimer une remise horaire
export const delete_discount_schedule_detail = createAsyncThunk(
    'mod_profil/delete_discount_schedule_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpDelete(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/discount_schedule/${values.discountScheduleId}/`,
            );
            return { ...response.data, id: values.discountScheduleId };
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const discountSlice = createSlice({
    name: 'discount',
    initialState: {
        loading: true,
        discountsPayment: [],
        discountPaymentDetail: {},
        discountsSchedule: [],
        discountScheduleDetail: {},
    },
    extraReducers: (builder) => {
        builder
            /* get_discount_payment_paginated */
            .addCase(
                get_discount_payment_paginated.pending,
                (state, action) => {
                    state.discountsPayment = [];
                },
            )
            .addCase(
                get_discount_payment_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.discountsPayment = action.payload.results;
                },
            )
            .addCase(
                get_discount_payment_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* create_discount_payment */
            .addCase(create_discount_payment.fulfilled, (state, action) => {
                state.loading = false;
                action.payload.index = action.payload.id;
                state.discountsPayment = [
                    ...state.discountsPayment,
                    action.payload,
                ];
            })
            .addCase(create_discount_payment.rejected, (state, action) => {
                state.loading = false;
            })

            /* get_discount_payment_detail */
            .addCase(get_discount_payment_detail.pending, (state, action) => {
                state.discountPaymentDetail = {};
            })
            .addCase(get_discount_payment_detail.fulfilled, (state, action) => {
                state.loading = false;
                state.discountPaymentDetail = action.payload;
            })
            .addCase(get_discount_payment_detail.rejected, (state, action) => {
                state.loading = false;
            })

            /* update_discount_payment_detail */
            .addCase(
                update_discount_payment_detail.fulfilled,
                (state, action) => {
                    state.loading = false;
                    const updatedDiscountPayment = action.payload;
                    const updatedDiscountPaymentsList =
                        state.discountsPayment.map((discount) => {
                            if (discount.id === updatedDiscountPayment.id) {
                                return updatedDiscountPayment;
                            }
                            return discount;
                        });
                    state.discountsPayment = updatedDiscountPaymentsList;
                },
            )
            .addCase(
                update_discount_payment_detail.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* delete_discount_payment_detail */
            .addCase(
                delete_discount_payment_detail.fulfilled,
                (state, action) => {
                    state.loading = false;
                    const discountPaymentToDeleteId = action.payload.id;
                    state.discountsPayment = state.discountsPayment.filter(
                        (discount) => discount.id !== discountPaymentToDeleteId,
                    );
                },
            )
            .addCase(
                delete_discount_payment_detail.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* get_discount_schedule_paginated */
            .addCase(
                get_discount_schedule_paginated.pending,
                (state, action) => {
                    state.discountsSchedule = [];
                },
            )
            .addCase(
                get_discount_schedule_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.discountsSchedule = action.payload.results;
                },
            )
            .addCase(
                get_discount_schedule_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* create_discount_schedule */
            .addCase(create_discount_schedule.fulfilled, (state, action) => {
                state.loading = false;
                action.payload.index = action.payload.id;
                state.discountsSchedule = [
                    ...state.discountsSchedule,
                    action.payload,
                ];
            })
            .addCase(create_discount_schedule.rejected, (state, action) => {
                state.loading = false;
            })

            /* get_discount_schedule_detail */
            .addCase(get_discount_schedule_detail.pending, (state, action) => {
                state.discountScheduleDetail = {};
            })
            .addCase(
                get_discount_schedule_detail.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.discountScheduleDetail = action.payload;
                },
            )
            .addCase(get_discount_schedule_detail.rejected, (state, action) => {
                state.loading = false;
            })

            /* update_discount_schedule_detail */
            .addCase(
                update_discount_schedule_detail.fulfilled,
                (state, action) => {
                    state.loading = false;
                    const updatedDiscountSchedule = action.payload;
                    const updatedDiscountScheduleList =
                        state.discountsSchedule.map((discount) => {
                            if (discount.id === updatedDiscountSchedule.id) {
                                return updatedDiscountSchedule;
                            }
                            return discount;
                        });
                    state.discountsSchedule = updatedDiscountScheduleList;
                },
            )
            .addCase(
                update_discount_schedule_detail.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* delete_discount_schedule_detail */
            .addCase(
                delete_discount_schedule_detail.fulfilled,
                (state, action) => {
                    const discountScheduleToDeleteId = action.payload.id;
                    state.discountsSchedule = state.discountsSchedule.filter(
                        (discount) =>
                            discount.id !== discountScheduleToDeleteId,
                    );
                    state.loading = false;
                },
            )
            .addCase(
                delete_discount_schedule_detail.rejected,
                (state, action) => {
                    state.loading = false;
                },
            );
    },
});

export default discountSlice.reducer;
