import React, { useState } from 'react';
import { Modal, Spinner } from '../componentsLibrary/componentsLibrary';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import './ConfirmationModal.scss';

export default function ConfirmationModal({
    content,
    onOk,
    onCancel,
    visible,
    confirmLoading,
}) {
    const [modalVisible, setModalVisible] = useState(false);
    const { t } = useTranslation();

    const handleOk = () => {
        onOk();
        // Ne pas définir modalVisible à false ici car le modal se ferme
        // lorsque confirmLoading devient true et déclenche une réinitialisation de modalVisible
    };

    const handleCancel = () => {
        onCancel();
        setModalVisible(false);
    };

    return (
        <>
            <Modal
                isOpen={visible}
                onClose={handleCancel}
                zIndex={9998}
                onOk={handleOk}
                okText={t('common.transButtonSubmit')}
                onCancel={handleCancel}
            >
                <div className='confirmationModalContent'>
                    {content ? (
                        <span>{content}</span>
                    ) : (
                        <span>{t('confirmationModal.content')}</span>
                    )}
                </div>
            </Modal>

            {confirmLoading && createPortal(<Spinner />, document.body)}
        </>
    );
}
