import React from 'react';
import { Route } from 'react-router-dom';
import SitesAndEquipment from '../pages/equipment/SitesAndEquipment';
import SitesMap from '../pages/equipment/SitesMap';
import CkRoutes from 'ck_commun/src/app/modUtils/routes/CkRoutes';
import SharedEquipment from '../pages/equipment/SharedEquipment';

export default function EquipmentRoutes() {
    return (
        <CkRoutes>
            <Route path='sites-equipments' element={<SitesAndEquipment />} />
            <Route path='sites-map' element={<SitesMap />} />
            <Route path='equipments-shared' element={<SharedEquipment />} />
        </CkRoutes>
    );
}
