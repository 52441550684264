import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import Home from 'pages/home/Home.jsx';
import Login from '../pages/login/Login';
import Store from '../pages/store/Store';
import ActivitiesRoutes from './ActivitiesRoutes';
import BadgesAndKeysRoutes from './BadgesAndKeysRoutes';
import EquipmentRoutes from './EquipmentRoutes';
import HistoryRoutes from './HistoryRoutes';
import PromoRoutes from './PromoRoutes';
import SettingsRoutes from './SettingsRoutes';
import CrmRoutes from './CrmRoutes';
import PrivateRoutes from './PrivateRoutes';
import MainLayout from 'shared/components/mainLayout/MainLayout';
import CkRoutes from 'ck_commun/src/app/modUtils/routes/CkRoutes';
import Plans from 'ck_commun/src/app/modManager/pages/Plans';
import { IS_PROD } from '../utils/constants';

export default function MainRoutes() {
    // TODO : on désactive cela pour le moment tant qu'on ne peux pas récuperer la date d'expiration
    // pour le remplacement du cookie
    // // on remplace le cookie en lui aoutant un .
    // replaceCookieDomain();

    return (
        <CkRoutes>
            <Route exact path='/' element={<Login />} />
            <Route element={<PrivateRoutes />}>
                <Route element={<MainLayout />}>
                    <Route path='/home' element={<Home />} />
                    <Route path='plans' element={<Plans />} />
                    <Route path='equipment/*' element={<EquipmentRoutes />} />
                    <Route path='activity/*' element={<ActivitiesRoutes />} />
                    <Route path='history/*' element={<HistoryRoutes />} />
                    <Route path='badges/*' element={<BadgesAndKeysRoutes />} />
                    <Route path='settings/*' element={<SettingsRoutes />} />

                    {IS_PROD === false && (
                        <>
                            <Route path='promo/*' element={<PromoRoutes />} />
                            <Route path='crm/*' element={<CrmRoutes />} />
                            <Route path='store' element={<Store />} />
                        </>
                    )}
                </Route>
            </Route>
        </CkRoutes>
    );
}
