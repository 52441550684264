import React from 'react';
import ErrorPage from '../components/componentsLibrary/error/ErrorPage';
import { ReactComponent as AuthenticationSvg } from '../assets/images/error-session.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { logout } from '../functions/logout';

const ErrorSession = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <ErrorPage
            icon={<AuthenticationSvg className={`max-w-sm`} />}
            title={String(t('errorSessionPage.title'))}
            description={String(t('errorSessionPage.description'))}
            onClick={() => {
                logout();
                navigate('/');
            }}
            buttonText={'errorSessionPage.returnLoginPage'}
        />
    );
};

export default ErrorSession;
