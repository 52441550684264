import React from 'react';
import { ReactComponent as LaodingSvg } from '../../assets/images/laoding/laoding.svg';

export default function LoadingMessageSelect({ className }) {
    return (
        <>
            <LaodingSvg
                className={`inline mx-3 w-4 h-4 text-white animate-spin ${className}`}
            />
            <span>Loading...</span>
        </>
    );
}
