import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_user_details } from '../slices/authenticationSlice';
import { Form, Input, Button, message } from 'antd';
import { Spinner } from '../../modUtils/components/componentsLibrary/componentsLibrary';
import SubmitButton from '../../modUtils/components/buttons/submitButton/SubmitButton';
import AlertMessage from '../../modUtils/components/AlertMessage';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

function UserInformationsForm() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const userId = localStorage.getItem('user');
    const { user } = useSelector((state) => state.authentication);
    const { t } = useTranslation();

    const getUserDetail = async () => {
        setLoading(true);
        try {
            await dispatch(get_user_details(userId)).unwrap();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('userInformationForm.userDetailError')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        user.id !== userId && userId && getUserDetail();
    }, [userId, i18next.language]);

    useEffect(() => {
        form.resetFields();
        if (user) {
            form.setFieldsValue({
                firstName: user?.first_name || '',
                lastName: user?.last_name || '',
                email: user?.email || '',
            });
        }
    }, [user, userId, form]);

    const handleSubmit = (values) => {};

    return (
        <div>
            <div> {t('loginInformation.lastName')} </div>
            <div className='text-secondary-color font-semibold'>
                {user?.last_name}
            </div>
            <div className='mt-4'> {t('loginInformation.firstName')} </div>
            <div className='text-secondary-color font-semibold'>
                {user?.first_name}
            </div>
            <div className='mt-4'> Email : </div>
            <div className='text-secondary-color font-semibold'>
                {user?.email}
            </div>
        </div>
    );
}
export default UserInformationsForm;

/* <Form form={form} onFinish={handleSubmit} layout="vertical">
<Form.Item
  label="Nom"
  name="lastName"
  // rules={[
  //   {
  //     required: true,
  //     message: "Veuillez entrer le nom",
  //   },
  // ]}
>
  <Input placeholder="" readOnly/>
</Form.Item>
<Form.Item
  label="Prénom"
  name="firstName"
  // rules={[
  //   {
  //     required: true,
  //     message: "Veuillez entrer le prénom",
  //   },
  // ]}
>
  <Input placeholder="" readOnly/>
</Form.Item>
<Form.Item
  label="Email"
  name="email"
  // rules={[
  //   {
  //     required: true,
  //     message: "Veuillez entrer l'email",
  //   },
  //   {
  //     type: "email",
  //     message: "Veuillez entrer un E-mail valide",
  //   },
  // ]}
>
  <Input type="email" placeholder="Email" readOnly/>
</Form.Item>


//TODO fonctionnalité à rajouter quand l'api update user detail est prête
<Form.Item>
  <SubmitButton onClick={() => handleSubmit()} label="ENREGISRTER" />
</Form.Item> 
</Form>      */
