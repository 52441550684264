import React from 'react';
import { Tooltip } from 'antd';

export default function CKToolTip({ placement, content, trigger, ...props }) {
    return (
        <Tooltip placement={placement} trigger={trigger} title={content}>
            {props.children}
        </Tooltip>
    );
}
