import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Select,
    Spinner,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { dateFormat } from '../../../modUtils/functions/dateFormat';
import { get_devices_by_equipment_by_group_list } from '../../slices/equipmentSlice';
import {
    eDeviceId,
    eDeviceCategories,
    eDeviceProtocol,
} from '../../../../commun_global/enums';
import './EquipmentDevicesList.scss';
import i18next from 'i18next';

export default function EquipmentDevicesList({
    siteName,
    equipment,
    selectDevice,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const filterOptions = [
        { label: t('equipmentDevicesList.labelInstalledOption'), value: '3,4' },
        { label: t('equipmentDevicesList.labelErrorOption'), value: '3' },
        { label: t('equipmentDevicesList.labelAllOption'), value: '0,3,4' },
    ];
    const [loading, setLoading] = useState(true);
    const { groupId, loadingGroup } = useSelector((state) => state.manager);
    const { equipmentDevices } = useSelector((state) => state.equipment);
    const [filterValue, setFilterValue] = useState([filterOptions[0].value]);

    async function fetchData() {
        setLoading(true);
        await dispatch(
            get_devices_by_equipment_by_group_list({
                groupId: groupId,
                equipmentId: equipment.id,
                status: filterValue[0],
            }),
        );
        setLoading(false);
    }

    useEffect(() => {
        if (groupId || equipment.id || filterValue) {
            fetchData();
        }
    }, [groupId, equipment.id, filterValue, i18next.language]);

    function handleChange(value) {
        setFilterValue([value]);
    }

    return (
        <div className='flex-display flex-column'>
            <div className='info-container flex-display justify-content-between'>
                <div className='devicesListEquipmentInfos'>
                    <div>
                        {t('equipmentDevicesList.serialNumber')}{' '}
                        {equipment.serial_number}
                    </div>
                    <div>
                        {t('equipmentDevicesList.site')} {siteName}
                    </div>
                </div>
                <div className='cursor-pointer'>
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            '/img/design/IconRefresh.svg'
                        }
                        alt='refresh'
                        onClick={() => fetchData()}
                    />
                </div>
            </div>

            <div
                className='flex-display flex-row  align-items-center'
                style={{ padding: '10px 0 10px 0' }}
            >
                <div className='devicesFilter'>
                    {t('equipmentDevicesList.filterDevices')}
                </div>

                <Select
                    onSelect={(value) => handleChange(value)}
                    defaultValue={filterValue}
                    options={filterOptions}
                    width={220}
                    onDeselect={(value) =>
                        setFilterValue([filterOptions[0].value])
                    }
                />
            </div>

            <div className='info-container deviceList'>
                {loading ? (
                    <Spinner></Spinner>
                ) : equipmentDevices.length > 0 ? (
                    <table>
                        <tbody>
                            {equipmentDevices?.map((device) => (
                                <tr
                                    key={device.id}
                                    onClick={() => selectDevice(device.id)}
                                >
                                    {/*TODO comment afficher les icones ? selon status ? !!!!!!!!!!!! */}
                                    <td>
                                        {device?.status === 4 ? (
                                            <img
                                                width={30}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/img/deviceStatusIcons/Installed.svg'
                                                }
                                                alt='ok'
                                            />
                                        ) : device?.status === 3 ? (
                                            <img
                                                width={30}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/img/deviceStatusIcons/Error.svg'
                                                }
                                                alt='error'
                                            />
                                        ) : (
                                            <img
                                                width={30}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/img/deviceStatusIcons/Not-installed.svg'
                                                }
                                                alt='not installed'
                                            />
                                        )}
                                    </td>
                                    <td style={{ paddingLeft: 10 }}>
                                        <div className='deviceRowName'>
                                            {t(
                                                eDeviceId[device?.device_id],
                                            ).toUpperCase()}
                                            {device?.protocol !== null &&
                                            device?.is_software_pure == false &&
                                            device?.protocol != 0
                                                ? ` (${t(eDeviceProtocol[device?.protocol]).toLowerCase()})`
                                                : ''}
                                            {/* le test device?.protocol != 0 est fait pour vérifier que le protocole est différent de "inutile" (eDeviceProtocol.unused)'
                                             */}
                                        </div>
                                        <div className='deviceRowType'>
                                            {t(
                                                eDeviceCategories[
                                                    device?.device_category_id
                                                ],
                                            )}
                                        </div>
                                    </td>
                                    <td
                                        className='deviceRowDate'
                                        style={{ paddingLeft: 10 }}
                                    >
                                        <div>
                                            {' '}
                                            {dateFormat(
                                                device?.updated_at,
                                                'DD/MM/yyyy',
                                            )}
                                        </div>
                                        <div>
                                            {' '}
                                            {dateFormat(
                                                device?.updated_at,
                                                'HH:mm',
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div
                        className='flex-display flex-column align-items-center'
                        style={{ marginTop: '2em' }}
                    >
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/img/design/noData.png'
                            }
                            alt='no data to display'
                            width={50}
                        ></img>
                        <div>{t('equipmentDevicesList.noDevice')} </div>
                    </div>
                )}
            </div>
        </div>
    );
}
