/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/support/e_action_type.py
Aucune modification manuelle acceptée
*/

// Type d'action support local
const eActionType = Object.freeze({
  // Crédit
  1: 'eActionType.credit',

  // Remise à zero
  2: 'eActionType.reset',

  // imprimer ticket
  3: 'eActionType.print',

  // Question
  4: 'eActionType.question',
});

export default eActionType;
