import React, { useEffect, useState } from 'react';
import './MapTableCell.scss';

export default function MapTableCell({ index, table, ...props }) {
    const [browserClass, setBrowserClass] = useState('');

    const heightClasses = {
        1: 'h-full',
        2: 'h-1/2',
        3: 'h-1/3',
        4: 'h-1/4',
        5: 'h-1/5',
        6: 'h-1/6',
        7: 'h-[14.28%]',
        8: 'h-[12.50%]',
        9: 'h-[11.11%]',
        10: 'h-[10%]',
    };

    const getHeightClass = (length) => {
        if (length in heightClasses) return heightClasses[length];
        if (length > 10) return 'h-14';
        return '';
    };
    useEffect(() => {
        if (navigator.userAgent.indexOf('Chrome') !== -1) {
            setBrowserClass('chrome-class');
        } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
            setBrowserClass('firefox-class');
        }
    }, []);

    return (
        <>
            {index > 0 && (
                <div
                    className={`
            border-0 border-t-[0.5px] border-solid border-[#00CFF933]
            w-full
          `}
                />
            )}

            <div
                key={index}
                className={`
          flex justify-center items-center 
          ${getHeightClass(table?.length)} ${browserClass} 
        `}
            >
                {props.children}
            </div>
        </>
    );
}
