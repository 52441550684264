import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Alert.scss';
const Alert = ({
    description,
    type,
    closable,
    message,
    icon,
    onClose,
    placement,
    duration,
    visible,
    style,
}) => {
    const [isVisible, setIsVisible] = useState(visible || true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, duration);

        return () => {
            clearTimeout(timer);
        };
    }, [duration]);

    const handleCloseAlert = () => {
        //onClose()
        setIsVisible(false);
    };

    const alertClassName = `alert ${type} ${placement} ${isVisible ? 'visible' : 'hidden'}`;

    return (
        <div
            id='alert'
            className={`flex-display flex-row  ${alertClassName}`}
            style={{ ...style }}
        >
            <div id='alert-icon'>{icon}</div>
            <div id='alert-content'>
                {description && <div id='alert-description'>{description}</div>}
                {message && <div id='alert-message'>{message}</div>}
            </div>
            {closable && (
                <div className='alert-close' onClick={handleCloseAlert}>
                    X
                </div>
            )}
        </div>
    );
};
Alert.propTypes = {
    type: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,
    message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    onClose: PropTypes.func,
    closable: PropTypes.bool,
    icon: PropTypes.node,
    placement: PropTypes.oneOf([
        'topLeft',
        'topRight',
        'bottomLeft',
        'bottomRight',
        'middle',
    ]),
    duration: PropTypes.number,
    visible: PropTypes.bool,
};

Alert.defaultProps = {
    placement: 'bottomRight',
    duration: 10000, // Default duration is 10 seconds
};
export default Alert;
