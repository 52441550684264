import { useTranslation } from 'react-i18next';
import { Button } from '../componentsLibrary';

const ErrorPage = ({
    icon,
    title,
    description,
    onClick = () => {},
    buttonText = 'Error404Page.homeReturn',
}) => {
    const { t } = useTranslation();
    return (
        <div className='grid bg-white'>
            <div className='text-center mt-20 flex items-center flex-col'>
                {icon}
                <h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                    {title}
                </h2>
                <p className='mt-2 text-xl text-gray-500'>{description}</p>
                <Button
                    onClick={() => onClick()}
                    type='primary'
                    className='w-max p-4 mt-2 h-max shadow-xl text-xl font-bold'
                >
                    {t(buttonText)}
                </Button>
            </div>
        </div>
    );
};

export default ErrorPage;
