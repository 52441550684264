import React from 'react';
import ErrorPage from '../components/componentsLibrary/error/ErrorPage';
import { ReactComponent as UnderConstructionSvg } from '../assets/images/error-internal.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ErrorInternal = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <ErrorPage
            icon={<UnderConstructionSvg className={`max-w-sm`} />}
            title={String(t('errorInternalPage.title'))}
            description={String(t('errorInternalPage.description'))}
            onClick={() => navigate('/home')}
        />
    );
};

export default ErrorInternal;
