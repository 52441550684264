/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/support/e_service_type.py
Aucune modification manuelle acceptée
*/

// Type service pour un profil
const eServiceType = Object.freeze({
  // Abonnement
  0: 'eServiceType.subscription',

  // Porte feuille
  1: 'eServiceType.wallet',

  // Facturation
  2: 'eServiceType.invoice',

  // Tarif préférentiel
  3: 'eServiceType.preferentialTarif',
});

export default eServiceType;
