import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Input,
    Spinner,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import Tabs from '../../../modUtils/components/componentsLibrary/tabs/Tabs';
import { get_sale_detail_by_group } from '../../slices/saleSlice';
import { transactions_by_group_paginated_get } from '../../../modWalletLocal/slices/walletLocalSlice';
import { get_transaction_sale_paginated_get } from '../../../modBanking/slices/bankingSlice';
import { amountFormatWithCurrencySymbol } from '../../../modUtils/functions/numberFormat';
import SupportTransactionDetails from '../../../modWalletLocal/components/SupportTransactionDetails';
import PaiementCBInfos from '../../../modBanking/components/PaiementCBInfos';
import { eCdbsPaymentType } from '../../../../commun_global/enums';
import i18next from 'i18next';

export default function SaleDetailsModal({ closeMenu, saleId, closeModal }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { saleDetails } = useSelector((state) => state.sale);
    const { groupId } = useSelector((state) => state.manager);
    const [supportTransactionsById, setSupportTransactionsById] = useState([]);
    const [saleTransactions, setSaleTransactions] = useState([]);
    const [loadingDetails, setLoadingDetails] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const { TabPane } = Tabs;
    const [
        loadingSupportTransactionsDetails,
        setLoadingSupportTransactionsDetails,
    ] = useState(false);
    const [loadingTransactionsBySale, setTransactionsBySale] = useState(false);

    const getSupportTransactionsDetails = async (saleId) => {
        setLoadingSupportTransactionsDetails(true);
        const response = await dispatch(
            transactions_by_group_paginated_get({
                groupId,
                saleId: saleId,
            }),
        ).unwrap();
        setSupportTransactionsById(response.results);
        setLoadingSupportTransactionsDetails(false);
    };

    const getTransactionsBySale = async (saleId) => {
        setTransactionsBySale(true);
        const response = await dispatch(
            get_transaction_sale_paginated_get({
                groupId,
                saleId: saleId,
            }),
        ).unwrap();
        setSaleTransactions(response.results);
        setTransactionsBySale(false);
    };

    const fetchData = async () => {
        setLoadingDetails(true);
        const response = await dispatch(
            get_sale_detail_by_group({ groupId, saleId }),
        );
        closeMenu && closeMenu();

        loadingDetails && setModalVisible(true);
        setLoadingDetails(false);

        const paymentTypes = response?.payload?.payments?.map(
            (payment) => payment?.type_payment,
        );
        const supportId = response?.payload?.identification?.element_id;

        // Fonction pour inverser l'énumération (obtenir les clés à partir des valeurs)
        const getPaymentTypeKeyByValue = (value) => {
            return parseInt(
                Object.keys(eCdbsPaymentType).find(
                    (key) => eCdbsPaymentType[key] === value,
                ),
            );
        };

        if (
            paymentTypes?.includes(
                getPaymentTypeKeyByValue('eCdbsPaymentType.badge'),
            ) &&
            supportId
        ) {
            getSupportTransactionsDetails(saleId, supportId);
        }

        if (
            (paymentTypes?.includes(
                getPaymentTypeKeyByValue('eCdbsPaymentType.cb'),
            ) ||
                paymentTypes?.includes(
                    getPaymentTypeKeyByValue('eCdbsPaymentType.cbSc'),
                )) &&
            supportId
        ) {
            getTransactionsBySale(saleId);
        }
    };

    useEffect(() => {
        fetchData();
    }, [groupId, i18next.language]);

    const onCloseModal = () => {
        closeModal();
    };

    const renderPaymentTabs = (paymentTabs, type) => {
        const tabsToRender = paymentTabs
            ?.map((payment, index) => {
                return (
                    <TabPane
                        label={
                            type === 'badge'
                                ? t('saleDetailsModal.badgePaymentInformation')
                                : t(
                                      'saleDetailsModal.creditCardPaymentInformation',
                                  )
                        }
                        tabKey={
                            type === 'badge'
                                ? 'badge-tab-' + index
                                : 'cb-tab-' + index
                        }
                        key={
                            type === 'badge'
                                ? 'badge-tab-' + index
                                : 'cb-tab-' + index
                        }
                    >
                        {type === 'badge' ? (
                            <SupportTransactionDetails payment={payment} />
                        ) : (
                            type === 'cb' && (
                                <PaiementCBInfos payment={payment} />
                            )
                        )}
                    </TabPane>
                );
            })
            .filter(Boolean);

        // Vérifiez s'il y a des onglets à rendre
        if (tabsToRender?.length > 0) {
            return (
                <Tabs classNameTabContent='bg-secondary-color-50'>
                    {tabsToRender}
                </Tabs>
            );
        }
    };

    const getTd = (value, className = '') => {
        return (
            <td
                className={`truncate w-full border-solid border-collapse border-[1px]
                    border-gray-300 text-gray-600 px-2 align-middle text-sm h-6
                    ${className}
                `}
            >
                {value}
            </td>
        );
    };

    const generateRetailRows = () =>
        saleDetails?.retails?.map((retail) => (
            <tr key={retail?.id}>
                {getTd(retail?.label_local, 'w-1/2')}
                {getTd(`* ${retail?.quantity}`, 'w-1/4')}
                {getTd(
                    amountFormatWithCurrencySymbol(retail?.price_include_vat),
                    'w-1/4 text-right',
                )}
            </tr>
        )) || [];

    const generatePaymentRows = () =>
        saleDetails?.payments?.map((payment) => (
            <tr key={payment?.id}>
                {getTd(t(eCdbsPaymentType[payment?.type_payment]), 'w-1/2')}
                {getTd(
                    `
                    ${payment?.count > 1 ? payment?.count + ' * ' : ''}
                    ${amountFormatWithCurrencySymbol(payment?.unit)}
                `,
                    'w-1/4',
                )}
                {getTd(amountFormatWithCurrencySymbol(payment.amount), 'w-1/4')}
            </tr>
        )) || [];

    const balanceRows = (rows1, rows2) => {
        const maxRowCount = Math.max(rows1.length, rows2.length);

        // Clonage des tableaux pour éviter de modifier les originaux
        const balancedRows1 = [...rows1];
        const balancedRows2 = [...rows2];

        // Ajout de lignes vides si nécessaire
        while (balancedRows1.length < maxRowCount) {
            balancedRows1.push(
                <tr key={`emptyRetailRow${balancedRows1.length + 1}`}>
                    {getTd(null, 'w-1/2')}
                    {getTd(null, 'w-1/4')}
                    {getTd(null, 'w-1/4 text-right')}
                </tr>,
            );
        }

        while (balancedRows2.length < maxRowCount) {
            balancedRows2.push(
                <tr key={`emptyPaymentRow${balancedRows2.length + 1}`}>
                    {getTd(null, 'w-1/2')}
                    {getTd(null, 'w-1/4')}
                    {getTd(null, 'w-1/4 text-right')}
                </tr>,
            );
        }

        return [balancedRows1, balancedRows2];
    };
    const retailRows = generateRetailRows();
    const paymentRows = generatePaymentRows();
    const [balancedRetailRows, balancedPaymentRows] = balanceRows(
        retailRows,
        paymentRows,
    );

    const detailsSale = [
        { label: 'saleDetailsModal.siteName', value: saleDetails?.site },
        {
            label: 'saleDetailsModal.equipmentName',
            value: saleDetails?.equipment?.name,
        },
        {
            label: 'saleDetailsModal.hardware',
            value:
                saleDetails?.equipment?.hardware_id &&
                `${saleDetails?.equipment?.hardware_id} / ${saleDetails?.equipment?.hardware_version}`,
        },

        {
            label: 'saleDetailsModal.totalIn',
            value: amountFormatWithCurrencySymbol(
                saleDetails?.price_total_real,
            ),
        },
        {
            label: 'saleDetailsModal.free',
            value: amountFormatWithCurrencySymbol(saleDetails?.amount_free),
        },
        {
            label: 'saleDetailsModal.paidIncludingTax',
            value: amountFormatWithCurrencySymbol(
                saleDetails?.amount_paid_currency_subject_vat,
            ),
        },
        {
            label: 'saleDetailsModal.vat',
            value: amountFormatWithCurrencySymbol(saleDetails?.amount_paid_vat),
        },
        {
            label: 'saleDetailsModal.transferred',
            value: amountFormatWithCurrencySymbol(
                saleDetails?.amount_paid_intermediate,
            ),
        },
    ];

    // Division du tableau pour chaque section de la grille
    const grid3Columns = detailsSale.slice(0, 3); // Les 3 premiers éléments
    const grid5Columns = detailsSale.slice(3); // Les 5 éléments suivants

    return (
        <Modal
            title={t('saleDetailsModal.transactionDetails')}
            isOpen={modalVisible}
            onClose={onCloseModal}
            width='70%'
            height='90%'
        >
            {!loadingDetails && (
                <>
                    <div className='border border-solid border-secondary-color-400 rounded bg-secondary-color-50 p-4'>
                        <div className='grid lg:grid-cols-3 gap-4'>
                            {grid3Columns.map(({ label, value }) => (
                                <div>
                                    <span>{t(label)}</span>
                                    <Input
                                        className='w-full'
                                        size={30}
                                        disabled
                                        value={value}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className='grid lg:grid-cols-5 gap-4'>
                            {grid5Columns.map(({ label, value }) => (
                                <div>
                                    <span>{t(label)}</span>
                                    <Input
                                        className='w-full'
                                        size={30}
                                        disabled
                                        value={value}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div
                        className={`grid grid-cols-1 md:grid-cols-7 gap-4 max-h-[300px] overflow-auto
                        sale-details-tables mt-4 mb-6 mr-0 ml-0
                    `}
                    >
                        <div className='col-span-1 md:col-span-3'>
                            <table className='w-full'>
                                <thead>
                                    <tr>
                                        <th className='w-1/2 text-left font-bold text-sm border-none'>
                                            {t(
                                                'saleDetailsModal.productsPurchased',
                                            )}
                                        </th>
                                        <th className='w-1/4 text-left font-bold text-sm border-none'>
                                            {t('saleDetailsModal.quantity')}
                                        </th>
                                        <th className='w-1/4 text-left font-bold text-sm border-none'>
                                            {t('saleDetailsModal.total')}
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>{balancedRetailRows}</tbody>
                            </table>
                        </div>

                        <div className='col-span-1 flex justify-center items-center'>
                            {saleDetails.has_error_balance ? (
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/unequalIcon.png'
                                    }
                                    alt='img'
                                />
                            ) : (
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/equalIcon.png'
                                    }
                                    alt='img'
                                />
                            )}
                        </div>

                        <div className='col-span-1 md:col-span-3'>
                            <table className='w-full'>
                                <thead>
                                    <tr>
                                        <th className='w-1/2 text-left font-bold text-sm border-none'>
                                            {t(
                                                'saleDetailsModal.paymentMethods',
                                            )}
                                        </th>
                                        <th className='w-1/4 text-left font-bold text-sm border-none'>
                                            {t('saleDetailsModal.detail')}
                                        </th>
                                        <th className='w-1/4 text-left font-bold text-sm border-none'>
                                            {t('saleDetailsModal.total')}
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>{balancedPaymentRows}</tbody>
                            </table>
                        </div>
                    </div>

                    {supportTransactionsById.length > 0 &&
                    loadingSupportTransactionsDetails ? (
                        <Spinner />
                    ) : (
                        renderPaymentTabs(supportTransactionsById, 'badge')
                    )}

                    {saleTransactions.length > 0 &&
                    loadingTransactionsBySale ? (
                        <Spinner />
                    ) : (
                        <div className='mt-4'>
                            {renderPaymentTabs(saleTransactions, 'cb')}
                        </div>
                    )}
                </>
            )}
        </Modal>
    );
}
