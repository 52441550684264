import React, { useState, useEffect } from 'react';
import {
    Modal,
    Input,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { Form, Button, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FormLegend from '../../../modUtils/components/FormLegend';
import moment from 'moment';
import { create_actions_by_support_by_group } from '../../slices/walletLocalSlice';
import ConfirmationModal from '../../../modUtils/components/confirmationModal/ConfirmationModal';
import { amountFormatWithCurrencySymbol } from '../../../modUtils/functions/numberFormat';
import { message } from 'antd';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import './SupportCreditModal.scss';

export default function SupportCreditModal({ closeModal, support }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [modalVisible, setModalVisible] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false); // État pour le modal de confirmation
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { groupId, loadingGroup } = useSelector((state) => state.manager);
    const [amountInputValue, setAmountInputValue] = useState();
    const formatValidatedDate = 'DD/MM/YYYY';
    const defaultValidatedDate = moment().add(1, 'months'); //par défaut après un mois de la date actuelle

    const openModal = () => {
        form.resetFields();
        form.setFieldValue('validatedDate', defaultValidatedDate);
        setAmountInputValue();
        setModalVisible(true);
        closeModal();
    };

    const handleAmountInput = (event) => {
        setAmountInputValue(event.target.value);
    };

    const onExecuteUpdateBadgeSolde = async () => {
        try {
            // Valider les champs du formulaire
            await form.validateFields();
            // Si les champs sont validés, afficher le modal de confirmation
            setConfirmModalVisible(true);
        } catch (errorInfo) {
            // Si la validation échoue
            //console.log('Validation failed:', errorInfo);
        }
    };

    const handleConfirmOk = async () => {
        const formValues = form.getFieldsValue();
        const date = formValues.validatedDate;
        try {
            setConfirmLoading(true);
            await dispatch(
                create_actions_by_support_by_group({
                    groupId,
                    supportId: support.id,
                    amount: formValues.amount,
                    type: 1, //créditer
                    validatedDate: date
                        ? Math.floor(date.valueOf() / 1000)
                        : null,
                }),
            ).unwrap();
            setConfirmLoading(false);
            setModalVisible(false);
            setConfirmModalVisible(false);
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('badge.badgeError')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
            setConfirmLoading(false);
            setConfirmModalVisible(false);
        }
    };

    const handleConfirmCancel = () => {
        setConfirmModalVisible(false);
        form.resetFields();
    };

    const handleModalCancel = () => {
        setModalVisible(false);
    };

    // Fonction de validation personnalisée pour vérifier si la valeur est positive
    const validatePositiveNumber = (rule, value, callback) => {
        if (value && value < 0) {
            callback(t('badge.badgePositifNombre'));
        } else {
            callback();
        }
    };

    return (
        <>
            <p onClick={openModal} className='capitalize'>
                {t('badge.badgeCreditButton')}
            </p>

            <Modal
                title={`${t('badge.badgeCreditModalTitle')} N°${support.badge_number}`}
                isOpen={modalVisible}
                onClose={handleModalCancel}
                onOk={onExecuteUpdateBadgeSolde}
                onCancel={() => setModalVisible(false)}
            >
                <div className='credit-support-form'>
                    <Form
                        form={form}
                        onFinish={onExecuteUpdateBadgeSolde}
                        layout='vertical'
                    >
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 content-center'>
                            <Form.Item
                                label={t('badge.badgeCreditAmount')}
                                name='amount'
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'badge.badgeRequiredMessage',
                                        ),
                                    },
                                    {
                                        validator: validatePositiveNumber, // Utiliser la fonction de validation personnalisée
                                    },
                                ]}
                            >
                                <Input
                                    type='number'
                                    onChange={handleAmountInput}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t('badge.badgeValidationDate')}
                                name='validatedDate'
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'badge.badgeVAlidationMessage',
                                        ),
                                    },
                                ]}
                            >
                                <DatePicker format={formatValidatedDate} />
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <FormLegend requiredFormItemLegend={true} />
            </Modal>

            {/* Modal de confirmation */}
            <ConfirmationModal
                onOk={handleConfirmOk}
                onCancel={handleConfirmCancel}
                visible={confirmModalVisible}
                confirmLoading={confirmLoading}
                content={
                    <div>
                        {t('badge.badgeContent')}{' '}
                        <b>N°{support.badge_number}</b> {t('badge.badgeAmount')}{' '}
                        <b>
                            {amountFormatWithCurrencySymbol(
                                form.getFieldValue('amount'),
                            )}{' '}
                        </b>
                        {t('badge.badgeSecondContent')}
                    </div>
                }
            />
        </>
    );
}
