/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_device_id.py
Aucune modification manuelle acceptée
*/

// Identifiant de device
// Permet de savoir de quel périphérique il s'agit
// En C, c'est nommé FEATURECID
const eDeviceId = Object.freeze({
  // Inconnu
  0: 'eDeviceId.unknown',

  // Lecteur de billets
  1: 'eDeviceId.billValidator1',

  // Lecteur de billets 2
  2: 'eDeviceId.billValidator2',

  // Accepteur de pièces
  6: 'eDeviceId.coinValidator1',

  // Accepteur de pièces 2
  7: 'eDeviceId.coinValidator2',

  // Monnayeur rendeur
  8: 'eDeviceId.coinChanger1',

  // Monnayeur rendeur 2
  9: 'eDeviceId.coinChanger2',

  // Pré-caisse
  10: 'eDeviceId.preCashRegister',

  // Paiement sans-contact
  11: 'eDeviceId.cashless1',

  // Paiement sans-contact 2
  12: 'eDeviceId.cashless2',

  // Terminal bancaire
  13: 'eDeviceId.bankingTerminal1',

  // Terminal bancaire 2
  14: 'eDeviceId.bankingTerminal2',

  // Télécollecte
  15: 'eDeviceId.remoteCollection',

  // Lecteur de badges
  21: 'eDeviceId.readerCashless1',

  // Lecteur de badges 2
  22: 'eDeviceId.readerCashless2',

  // Lecteur INSIDE
  23: 'eDeviceId.readerInside',

  // Lecteur mifare
  24: 'eDeviceId.readerMifare',

  // Badge rechargement
  25: 'eDeviceId.badgeReload',

  // Avaleur de badges
  26: 'eDeviceId.badgeSwallower',

  // Périphérique 1
  31: 'eDeviceId.periph1',

  // Périphérique 2
  32: 'eDeviceId.periph2',

  // Périphérique 3
  33: 'eDeviceId.periph3',

  // Périphérique 4
  34: 'eDeviceId.periph4',

  // Périphérique 5
  35: 'eDeviceId.periph5',

  // Périphérique 6
  36: 'eDeviceId.periph6',

  // Distributeur de produits
  37: 'eDeviceId.productDistributor1',

  // Distributeur de produits 2
  38: 'eDeviceId.productDistributor2',

  // Imprimante client
  41: 'eDeviceId.printerCustomer1',

  // Imprimante client 2
  42: 'eDeviceId.printerCustomer2',

  // Vocal 1
  46: 'eDeviceId.vocal1',

  // Vocal 2
  47: 'eDeviceId.vocal2',

  // Lecteur de code-barres
  51: 'eDeviceId.barcodeReader1',

  // Lecteur de code-barres 2
  52: 'eDeviceId.barcodeReader2',

  // Application mobile
  56: 'eDeviceId.mobileAppli',

  // Compteur déporté local
  61: 'eDeviceId.deportedCounterLocal',

  // Compteur déporté serveur
  62: 'eDeviceId.deportedCounterServer',

  // Historique serveur
  63: 'eDeviceId.serverHistoric',

  // Historique serveur
  64: 'eDeviceId.historicCkwash',

  // Bannière led
  66: 'eDeviceId.ledBanner',

  // Journal lumineux
  67: 'eDeviceId.ledBoard',

  // Dimmer
  68: 'eDeviceId.dimmer',

  // Système mail
  71: 'eDeviceId.mailSystem',

  // Système sms
  72: 'eDeviceId.smsSystem',

  // Serveur code
  73: 'eDeviceId.webServer',

  // Web serveur protégé
  74: 'eDeviceId.protectedWebServer',

  // Ethernet actif
  75: 'eDeviceId.ethernetActif',

  // Système d'alerte
  76: 'eDeviceId.alertSystem',

  // Ticket distant
  77: 'eDeviceId.remoteTicket',

  // Configuration console
  81: 'eDeviceId.configurationConsole',

  // Protected menu
  82: 'eDeviceId.protectedMenu',

  // Discount
  86: 'eDeviceId.discount',

  // Alarme
  91: 'eDeviceId.alarm',

  // Button
  92: 'eDeviceId.button',

  // Clavier Matriciel
  93: 'eDeviceId.matrixKeyboard',

  // Interphone
  94: 'eDeviceId.intercom',

  // Selecteur
  95: 'eDeviceId.selector',

  // Programme led
  96: 'eDeviceId.ledProgram',

  // Mode esclave
  97: 'eDeviceId.slaveMode',

  // Animation led
  98: 'eDeviceId.ledAnimation',

  // Date
  99: 'eDeviceId.dateTime',

  // Machines
  100: 'eDeviceId.machines',

  // Controle d'entrée 1
  101: 'eDeviceId.inputControl1',

  // Controle d'entrée 2
  102: 'eDeviceId.inputControl2',

  // Controle d'entrée 3
  103: 'eDeviceId.inputControl3',

  // Controle d'entrée 4
  104: 'eDeviceId.inputControl4',

  // Controle d'entrée 5
  105: 'eDeviceId.inputControl5',

  // Controle d'entrée 6
  106: 'eDeviceId.inputControl6',

  // Affichage
  121: 'eDeviceId.display1',

  // Affichage 2
  122: 'eDeviceId.display2',

  // Affichage 3
  123: 'eDeviceId.display3',

  // Système général
  131: 'eDeviceId.systemGeneral',

  // Système Ckspros
  132: 'eDeviceId.systemCkspros',

  // Système code
  141: 'eDeviceId.codeSystem',

  // Serveur code
  142: 'eDeviceId.serverCode',

  // Détection de mouvement
  151: 'eDeviceId.movmentDetection',

  // Extension IO 1
  161: 'eDeviceId.iosExtension1',

  // Extension IO 2
  162: 'eDeviceId.iosExtension2',

  // Extension IO 3
  163: 'eDeviceId.iosExtension3',

  // Extension IO 4
  164: 'eDeviceId.iosExtension4',

  // Avaleur de badge
  171: 'eDeviceId.cardCapture',

  // Distributeur de cartes
  172: 'eDeviceId.cardDistributor',

  // Point services 1
  181: 'eDeviceId.servicesPoint1',

  // Point services 2
  182: 'eDeviceId.servicesPoint2',

  // Point services 3
  183: 'eDeviceId.servicesPoint3',

  // Point services 4
  184: 'eDeviceId.servicesPoint4',

  // Point services 5
  185: 'eDeviceId.servicesPoint5',

  // Point services 6
  186: 'eDeviceId.servicesPoint6',

  // Lora
  191: 'eDeviceId.lora',

  // Modem
  192: 'eDeviceId.modem',

  // Wifi
  193: 'eDeviceId.wifi',

  // Collection périphérique domotique
  197: 'eDeviceId.domotics',

  // Sécheresse
  198: 'eDeviceId.categoryDrought',

  // Personnalisé1
  199: 'eDeviceId.categoryCustom1',

  // Personnalisé2
  200: 'eDeviceId.categoryCustom2',

  // Personnalisé3
  201: 'eDeviceId.categoryCustom3',

  // Gestionnaire de casier
  202: 'eDeviceId.categoryLockerManager',

  // Pac195x1
  205: 'eDeviceId.categoryPac195X1',

  // Pac195x2
  206: 'eDeviceId.categoryPac195X2',

  // Pac195x3
  207: 'eDeviceId.categoryPac195X3',

  // Pac195x4
  208: 'eDeviceId.categoryPac195X4',

  // Personnalisé
  220: 'eDeviceId.custom',
});

export default eDeviceId;
