import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './en.json';
import translationFR from './fr.json';
import translation from './translation';
import translationCkCommunGlobalFr from 'ck_commun/src/commun_global/js/i18n/fr.json';
import translationCkCommunGlobalEn from 'ck_commun/src/commun_global/js/i18n/en.json';

const defaultLanguage = localStorage.getItem('i18nextLng');

// the translations
const resources = {
    en: {
        translation: {
            ...translationEN,
            ...translation.en,
            ...translationCkCommunGlobalEn,
        },
    },
    fr: {
        translation: {
            ...translationFR,
            ...translation.fr,
            ...translationCkCommunGlobalFr,
        },
    },
};

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: defaultLanguage,

        keySeparator: '.', // to support nested translations

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
