import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DevicesManageModal from '../devicesManageModal/DevicesManageModal';
import EquipmentUpdateModal from '../equipmentUpdateModal/EquipmentUpdateModal';
import EquipmentConnectionToServerButton from '../equipmentConnectionToServerButton/EquipmentConnectionToServerButton';
import EquipmentFixingModal from '../equipmentFixingModal/EquipmentFixingModal';
import MachineManageModal from '../../../modImachine/components/machineManageModal/MachineManageModal';
import SharedEquipmentManageModal from '../../../modSharedEquipment/components/sharedEquipmentManageModal/SharedEquipmentManageModal';
import DropdownActionsMenu from '../../../modUtils/components/dropdownActionsMenu/DropdownActionsMenu';
import { hasPermission } from '../../../modUtils/functions/handlePermissions';
import AdminLink from '../../../modUtils/components/adminLink/AdminLink';
import { useTranslation } from 'react-i18next';

export default function EquipmentActionsMenu({ siteId, siteName, equipment }) {
    const [visible, setVisible] = useState(false);
    const [isEquipmentFixingModalVisible, setIsEquipmentFixingModalVisible] =
        useState(false);
    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup.permissions;
    const verifyPermission = hasPermission(
        'mod_equipment:can_fixing',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_equipment:can_fixing',
        groupPermissions,
    );
    const { t } = useTranslation();

    //fermer le popover
    const hide = () => {
        setVisible(false);
    };

    const handleVisibleChange = () => {
        setVisible((prevVisible) => !prevVisible);
    };
    //fonction appelée quand on clique sur DÉPANNAGE
    const handleOnClickFixingModal = () => {
        setIsEquipmentFixingModalVisible(true);
        setVisible(false);
    };

    //ELEMENTS DU MENU AFFICHE DANS LE POPOVER (LISTE DES ACTIONS POSSIBLES)
    let menuItems = [
        // TODO CH rappelle qu'il faudra gerer le soucis de dépendances des modules dans ce composant
        <SharedEquipmentManageModal
            siteId={siteId}
            closeModal={hide}
            equipment={equipment}
        />,
        <DevicesManageModal
            closeModal={hide}
            siteName={siteName}
            equipment={equipment}
            buttonType='popover'
        />,
        <p
            className={`${!verifyPermission || !verifyGroupPermission ? 'disabled' : ''}`}
            onClick={() => handleOnClickFixingModal()}
        >
            {t('equipmentActionsMenu.troubleshooting')}
        </p>,
        <EquipmentConnectionToServerButton equipment={equipment} />,
        <EquipmentUpdateModal
            closeModal={hide}
            buttonType='popover'
            equipment={equipment}
        />,
        <AdminLink
            adminPath={`mod_equipment/equipment/${equipment.id}/change/`}
            onClick={hide}
        />,
    ];

    //traitement lié aux equipements de type machines
    const EquipmentTypeWithMachinesList = [10, 11, 12];
    if (EquipmentTypeWithMachinesList.includes(equipment?.type)) {
        menuItems = [
            <MachineManageModal
                siteId={siteId}
                closeModal={hide}
                equipment={equipment}
            />,
            ...menuItems,
        ];
    }

    return (
        <>
            <DropdownActionsMenu
                menuItems={menuItems}
                handleVisibleChange={handleVisibleChange}
                visible={visible}
            />
            {isEquipmentFixingModalVisible && (
                <EquipmentFixingModal
                    siteId={siteId}
                    equipment={equipment}
                    isModalVisible={isEquipmentFixingModalVisible}
                    setIsModalVisible={setIsEquipmentFixingModalVisible}
                />
            )}
        </>
    );
}
