import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Error404Page from 'ck_commun/src/app/modUtils/pages/Error404Page';
import ErrorInternal from '../pages/ErrorInternal';
import ErrorSession from '../pages/ErrorSession';

export default function CkRoutes({ ...props }) {
    return (
        <Routes>
            {props.children}

            <Route path='*' element={<Error404Page />} />
            <Route path='/error' element={<ErrorInternal />} />
            <Route path='/session-expired' element={<ErrorSession />} />
        </Routes>
    );
}
