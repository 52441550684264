import { configureStore } from '@reduxjs/toolkit';
import commonReducer from 'ck_commun/src/app/modUtils/reducers/commonReducer';
import rootReducer from './rootReducer';

export const store = configureStore({
    reducer: {
        ...commonReducer,
        ...rootReducer,
    },
});
