import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sharing_request_create } from '../../slices/sharedEquipmentSlice';
import {
    AlertMessage,
    ErrorMessage,
    Input,
    Textarea,
    Modal,
    Label,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import Button from '../../../modUtils/components/buttons/Button';
import ButtonDisabled from '../../../modUtils/components/buttons/ButtonDisabled';

export default function EquipmentAccessResquestForm({}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { user } = useSelector((state) => state.authentication);
    const { groupId, selectedGroup } = useSelector((state) => state.manager);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [statusMessage, setstatusMessage] = useState(null);
    const [defaultMessage, setDefaultMessage] = useState('');

    function resetMessageAlert() {
        setDefaultMessage('');
        setstatusMessage(null);
    }

    function onCloseModal() {
        setModalVisible(false);
        resetMessageAlert();
    }

    function setMessageAuto() {
        let textEmail;
        textEmail = t('equipmentAccessResquestForm.defaultEmail');
        textEmail = textEmail + user.first_name + ' ' + user.last_name + '\n';
        textEmail =
            textEmail +
            t('equipmentAccessResquestForm.company') +
            selectedGroup?.name;

        return textEmail;
    }

    function handleSubmit(values, resetForm) {
        fetchDataSharingRequestCreate(values?.serialNumber, values?.message);
        resetForm();
    }

    async function fetchDataSharingRequestCreate(serialNumber, message) {
        setLoading(true);
        const response = await dispatch(
            sharing_request_create({
                groupId: groupId,
                equipment_to_shared: serialNumber,
                message: message,
            }),
        ).unwrap();
        setstatusMessage(response?.status);
        setDefaultMessage(response?.message);
        setLoading(false);
    }

    const schema = Yup.object().shape({
        serialNumber: Yup.string()
            .required(t('equipmentAccessResquestForm.serialNumberRequired'))
            .min(4, t('equipmentAccessResquestForm.moreCharacters')),
        message: Yup.string()
            .required(t('equipmentAccessResquestForm.messageRequired'))
            .min(4, t('equipmentAccessResquestForm.moreCharacters')),
    });

    return (
        <>
            <Button onClick={() => setModalVisible(true)} color='primary'>
                {t('equipmentAccessResquestForm.requestAccess')}
            </Button>

            <Modal
                title={t(
                    'equipmentAccessResquestForm.requestAccessToEquipment',
                )}
                isOpen={modalVisible}
                onClose={() => onCloseModal()}
                width='50%'
                footer={
                    <p className='text-xs'>
                        {t('equipmentAccessResquestForm.requiredFields')}
                    </p>
                }
            >
                <Formik
                    validationSchema={schema}
                    initialValues={{
                        serialNumber: '',
                        message: setMessageAuto(),
                    }}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmit(values, resetForm);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                    }) => (
                        <form
                            id='form'
                            noValidate
                            onSubmit={handleSubmit}
                            className='w-6/12 max-w-[500px] mx-auto mt-8'
                        >
                            <Label
                                label={t(
                                    'equipmentAccessResquestForm.equipmentSerialNumber',
                                )}
                            />
                            <Input
                                id='serialNumber'
                                name='serialNumber'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.serialNumber}
                            />
                            <ErrorMessage>
                                {errors.serialNumber &&
                                    touched.serialNumber &&
                                    errors.serialNumber}
                            </ErrorMessage>

                            <Textarea
                                label={t('equipmentAccessResquestForm.message')}
                                name='message'
                                className={'h-[160px]'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                    values.message || values.message === ''
                                        ? values.message
                                        : setMessageAuto()
                                }
                                defaultValue={values.message}
                            />
                            <ErrorMessage>
                                {errors.message &&
                                    touched.message &&
                                    errors.message}
                            </ErrorMessage>

                            <div className='flex justify-center'>
                                {isValid ? (
                                    <Button
                                        color='success'
                                        onClick={() => resetMessageAlert()}
                                    >
                                        {t(
                                            'equipmentAccessResquestForm.toValidate',
                                        )}
                                    </Button>
                                ) : (
                                    <ButtonDisabled
                                        onClick={() => resetMessageAlert()}
                                    >
                                        {t(
                                            'equipmentAccessResquestForm.toValidate',
                                        )}
                                    </ButtonDisabled>
                                )}
                            </div>

                            <AlertMessage
                                statusMessage={statusMessage}
                                defaultMessage={defaultMessage}
                                loading={loading}
                            />
                        </form>
                    )}
                </Formik>
            </Modal>
        </>
    );
}
