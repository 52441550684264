import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import './Menu.scss';

const MenuItem = ({
    label,
    itemKey,
    icon,
    submenus,
    openKeys,
    onOpenChange,
    collapsed,
    currentLink,
    setCurrentLink,
    onClick,
    permission = true,
    groupPermission,
    openModal,
    toggleMobileMenu,
    ...props
}) => {
    const isOpen = openKeys.includes(itemKey);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Check if the current item is active and set the activeMenuKey of the local storage accordingly
        itemKey == currentLink &&
            localStorage.setItem('activeMenuKey', itemKey);
    }, [itemKey, currentLink]);

    const handleItemClick = () => {
        if (!permission) {
            setCurrentLink(itemKey);
            openModal();
        } else {
            if (toggleMobileMenu) {
                if (!submenus) {
                    toggleMobileMenu();
                }
            }
            if (submenus) {
                onOpenChange(itemKey);
            } else if (itemKey) {
                setCurrentLink(itemKey); // Update the active menu item in the parent component
                localStorage.setItem('activeMenuKey', itemKey);
                if (onClick) {
                    onClick(); // Call the provided onClick function
                }
                navigate(`/${itemKey}`);
            }
        }
    };

    const subMenuList = () => {
        return (
            <ul className='submenu'>
                {submenus?.map((submenu) => (
                    <MenuItem
                        key={submenu.itemKey}
                        itemKey={submenu.key}
                        {...submenu}
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                        currentLink={currentLink} // Pass the current active menu item as prop
                        setCurrentLink={setCurrentLink} // Pass the function to update the active menu item
                        openModal={openModal}
                    />
                ))}
            </ul>
        );
    };

    function styleItemDisableGet() {
        return '!bg-gray-500 !cursor-not-allowed !opacity-40';
    }

    return (
        <>
            <li
                key={itemKey}
                onClick={handleItemClick}
                className={`${!permission && styleItemDisableGet()}
                ${itemKey === location.pathname.substring(1) ? 'menu-active' : ''}
                ${groupPermission === false && styleItemDisableGet()}
            `}
                //.substring(1) est utilisé pour récupérer la clé du menu à partir l'url sans "/"
            >
                {icon && (
                    <div className='menu-item tooltip'>
                        <div className='active-menu-border'></div>
                        <span className='menu-item-icon'>{icon}</span>
                        {collapsed && (
                            <span className='tooltiptext'>
                                {submenus ? (
                                    subMenuList()
                                ) : (
                                    <span className='menu-item-label'>
                                        {label}
                                    </span>
                                )}
                            </span>
                        )}
                    </div>
                )}
                {!collapsed && <span className='menu-item-label'>{label}</span>}
                {submenus && !collapsed && (
                    <img
                        src={process.env.PUBLIC_URL + '/img/Fleche.svg'}
                        className={
                            isOpen
                                ? 'menu-expand-icon expanded'
                                : 'menu-expand-icon '
                        }
                    />
                )}
            </li>
            {isOpen && submenus && !collapsed && (
                <li
                    onClick={() => toggleMobileMenu && toggleMobileMenu()}
                    className={`${!permission && styleItemDisableGet()}`}
                >
                    {subMenuList()}
                </li>
            )}
        </>
    );
};

MenuItem.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired, //le label d'un élément du menu
    itemKey: PropTypes.string.isRequired, //la clé d'un élément  du menu, elle présente le chemin complet à utiliser dans routes pour la navgation
    collapsed: PropTypes.bool, //définit l'état courant du menu (s'il est collapsed ou non)
    openKeys: PropTypes.array.isRequired, //les clés des sous-menus actuellement ouverts
    submenus: PropTypes.array, //la liste des sous-menus d'un élément du menu
    onClick: PropTypes.func, //fonction à appeler quand l'élement du menu est cliqué
    permission: PropTypes.bool,
};

export default MenuItem;
