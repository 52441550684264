import React from 'react';
import { Form, Radio } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../modUtils/components/buttons/Button';

export default function EntityFormStatus({
    handleSelect,
    statusSelectedOption,
}) {
    const { t } = useTranslation();
    const { entityCompanyDetail } = useSelector((state) => state.entity);

    const statusOptions = [
        // TODO CH a modifier avec E_ENTITY_STATUS
        { label: t('entityFormStatus.onHold'), value: 3 },
        { label: t('entityFormStatus.active'), value: 0 },
        { label: t('entityFormStatus.blocked'), value: 1 },
        { label: t('entityFormStatus.enclose'), value: 2 },
    ];

    return (
        <div className='flex justify-center'>
            <Form.Item label={t('entityFormStatus.status')} name='status'>
                <Radio.Group
                    value={statusSelectedOption}
                    onChange={(e) => handleSelect(e.target.value)}
                >
                    {statusOptions?.map((status) => (
                        <Button
                            color={
                                statusSelectedOption ||
                                statusSelectedOption === 0
                                    ? status.value === statusSelectedOption
                                        ? 'primary'
                                        : 'outline'
                                    : status.value ===
                                        entityCompanyDetail.status
                                      ? 'primary'
                                      : 'outline'
                            }
                            key={status.value}
                            value={status.value}
                            onClick={() => handleSelect(status.value)}
                            className='w-24 mr-2 h-10 mb-2 truncate'
                            size='sm'
                        >
                            {status.label}
                        </Button>
                    ))}
                </Radio.Group>
            </Form.Item>
        </div>
    );
}
