import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Select, Spin } from 'antd';
import { Card } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import {
    saveSelectedGroup,
    get_groups_paginated,
    get_group_detail,
} from 'ck_commun/src/app/modManager/slices/managerSlice';
import './SelectGroup.scss';
import AdminLink from '../../../modUtils/components/adminLink/AdminLink';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const SelectGroup = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const groupId = localStorage.getItem('group');
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const defaultLimit = 25;
    const { groups, groupDetail, selectedGroup } = useSelector(
        (state) => state.manager,
    );
    const [equipmentsCount, setEquipmentsCount] = useState();
    const [sitesCount, setSitesCount] = useState();
    const [groupOptions, setGroupOptions] = useState([]);
    const [searchInputValue, setSearchInputValue] = useState();
    const [selectedValue, setSelectedValue] = useState();
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [groupCount, setGroupCount] = useState();
    const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(true);
    //suivi du state du button flech si utilisé ou non
    const [arrowKeyUsed, setArrawKeyUsed] = useState(false);
    const dropdownRef = useRef(null); // Ref to access dropdown element

    //getGroups appelle l'api get_groups_paginated pour récupérer la liste des groupes et ensuite stocke les données dans groupOptions sous forme {value: groupId, label: groupName}
    const getGroups = async (
        searchValue = null,
        clearData = false,
        resetPage = false,
    ) => {
        const requestParams = {
            limit: defaultLimit,
            offset: resetPage ? 0 : (page - 1) * defaultLimit,
        };
        if (searchValue !== null) {
            //ne pas passer le filtre par name que quand il est passé en props de getGroups
            requestParams.name = searchValue;
        }
        let groups = [];
        await dispatch(get_groups_paginated(requestParams)).then((response) => {
            if (response.error)
                //setLoading(false)
                // navigate("/error");
                console.log(response.error);
            //TODO traitement à faire quand l'api get groups ne retourne pas de données
            else {
                groups = response.payload.results;
                const newData = groups?.map((group) => ({
                    value: group.id,
                    label: group.name,
                }));
                // Mise à jour de l'état HasMoreDataToLoad en fonction de la valeur de "next" dans la réponse de l'API
                setHasMoreDataToLoad(response?.payload?.next !== null);
                if (clearData) {
                    setGroupOptions(newData);
                } else if ((searchValue && page === 1) || page === 1) {
                    setGroupOptions(newData);
                } else {
                    setGroupOptions((prevData) => [...prevData, ...newData]);
                }
            }
        });
        return groups;
    };

    //getGroupDetail appelle get_group_detail pour récupérer le détail du groupe d'id correspondant au groupeId passé en paramètres
    const getGroupDetail = async (groupId) => {
        let exist = false;
        await dispatch(get_group_detail({ groupId })).then((response) => {
            if (response.error) {
                return exist;
            }
            if (response.payload) {
                setSelectedValue(response?.payload?.name); //le name groupe à afficher dans l'input du select
                setSitesCount(response?.payload?.site_count); //mettre à jour le nombre des sites
                setEquipmentsCount(response?.payload?.equipment_count); //mettre à jour le nombre des équipements
                dispatch(saveSelectedGroup(response?.payload)); //fonction appelée pour enregistrer le groupId dans le local storage et l'objet groupe dans le state redux 'selectedGroup'
                exist = true;
            } else exist = false;
        });
        return exist;
    };

    useEffect(() => {
        if (!groupId && (page > 1 || i18next?.language)) {
            getGroups(searchInputValue);
        }
    }, [page, i18next.language, groupId]);

    useEffect(() => {
        const getData = async () => {
            if (groupId) {
                // si on a deja un groupe selectionné, récupérer ses détails
                const groupDetailsExist = await getGroupDetail(groupId);
                if (groupDetailsExist == false) {
                    const groupList = await getGroups(null, true, true); //récupérer toute la liste des groupes
                    // si  getGroupDetail ne retourne pas des données , on récupère la liste des groupes et on selectionne le 1er groupe retourné
                    await getGroupDetail(groupList[0]?.id);
                }
            } else {
                //sinon récupérer toute la liste des groupes et sélectionner le premier groupe retourné
                // await getGroups(null, true, true);
                //const groupList = await getGroups(null, true, true);
                const groupList = await getGroups(null, true, true); //récupérer toute la liste des groupes
                setGroupCount(groupList?.length);
                await getGroupDetail(groupList[0]?.id);
            }
            setLoading(false);
        };
        getData();
    }, [dispatch, groupId]);

    //handleSearch fonction appelée quand on cherche un groupe, càd la valeur de searchInputValue change
    const handleSearch = (newValue) => {
        setSearchInputValue(newValue);
        setPage(1);
        if (
            newValue.length >= 3 &&
            !(newValue.length === 3 && newValue[2] === ' ')
        ) {
            //faire l'appel à l'api que quand searchInputValue contient 3 caractères ou plus, et le 3ème caractère n'est pas vide
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
            setSearchTimeout(
                setTimeout(() => {
                    getGroups(newValue, true, true);
                }, 500), //searchTimeOut utilisé pour ne pas appeler l'api à chaque fois qu'on écrit un caractère de plus, limiter les appels inutiles par un temps d'attente de 500ms
            );
        }
    };

    //handleSelect appelée quand on sélectionne un nouveau groupe
    const handleSelect = (newValue) => {
        setSelectedValue(newValue);
        getGroupDetail(newValue);
        setPage(1); // Reset page when selected group changes
    };
    //handlePopupScroll fonction appelée quand on scrolle dans le dropdown du select
    const handlePopupScroll = (event) => {
        const { target } = event;
        if (
            !loading &&
            target.scrollTop + target.offsetHeight === target.scrollHeight &&
            hasMoreDataToLoad
        ) {
            setPage((prevPage) => prevPage + 1);
        }
    };
    // handleDropdownVisibleChange :fonction ajoutée pour recharger la liste des groupes (pour ne pas afficher la dernière liste recherchée)
    const handleDropdownVisibleChange = (visible) => {
        setPage(1); // Reset the page number
        setSearchInputValue();
        if (visible) {
            if (dropdownRef.current) {
                dropdownRef.current.scrollTop = 0; // Scroll dropdown to top
            }
            getGroups(null, true, true); // Fetch groups, clear search value, clear previous data, and reset page
        }
    };
    const handleKeyDownPress = (event) => {
        //detetction de l'utilisation du button fleche
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            setArrawKeyUsed(true); // changement de l'etat puisque l'utilisateur a utiliser le button flech up ou down
        } else if (event.key === 'Enter' && groupOptions.length > 0) {
            let groupToSelect = null;
            if (arrowKeyUsed) {
                //si on detect l'utilsation d'une flech on appel la fonction handleSelect pour choisir le groupe selectionné
                groupToSelect = selectedValue;
            } else {
                // sinon on selectionne le premier groupe si l'utilisateur ne selectionne aucun
                groupToSelect = groupOptions[0]?.value;
            }
            if (groupToSelect) {
                handleSelect(groupToSelect);
            }
            //réinstalisation apres le clique sur l'entrée button
            setArrawKeyUsed(false);
        }
    };
    return (
        <>
            <div className='select-group-container w-full mb-2.5'>
                <Select
                    showSearch
                    value={selectedValue}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={handleSearch}
                    onPopupScroll={handlePopupScroll}
                    suffixIcon={
                        groupCount > 1 && (
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/design/zoom.svg'
                                }
                                alt='zoom'
                            />
                        )
                    } //ne pas afficher l'icone de recherche quand on a un seul groupe
                    onChange={handleSelect}
                    onKeyDown={handleKeyDownPress}
                    notFoundContent={t('selectGroup.notFoundContent')}
                    listHeight={200}
                    className={`select-customize-input ${
                        groupCount > 1 || !loading ? '' : 'pointer-events-none'
                    }`} //désactiver le fonctionnement du select quand on a un seul groupe
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                    dropdownRender={(menu) => (
                        <div ref={dropdownRef}>{menu}</div>
                    )}
                >
                    {groupOptions?.map((groupOption) => (
                        <Option
                            key={groupOption.value}
                            value={groupOption.value}
                        >
                            {groupOption.label}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className='hidden md:block'>
                <Card
                    className={
                        'w-full min-h-16 rounded-md border-[1px] border-primary-color shadow-none	mx-0 my-1'
                    }
                >
                    <div className='flex-display flex-row justify-content-around'>
                        <div className='w-2/4 content-center'>
                            <div className='text-xs	font-medium text-primary-color'>
                                Sites
                            </div>
                            <div className='text-sm font-semibold text-black'>
                                {loading ? <Spin /> : sitesCount}
                            </div>
                        </div>
                        <div className='headerDivider' />
                        <div className='w-2/4 content-center'>
                            <div className='text-xs	font-medium text-primary-color'>
                                {t('selectGroup.equipments')}
                            </div>
                            <div className='text-sm font-semibold text-black'>
                                {loading ? <Spin /> : equipmentsCount}
                            </div>
                        </div>
                    </div>
                    <AdminLink
                        label={t('selectGroup.adminLinkGroup')}
                        type='button'
                        adminPath={`mod_manager/groupcompany/${selectedGroup?.id}/change/`}
                        className='whitespace-nowrap text-xs leading-3 w-min mx-auto'
                    />
                </Card>
            </div>
        </>
    );
};

export default SelectGroup;
