import React from 'react';
import GroupPlans from '../components/GroupPlans';

export default function Plans() {
    return (
        <div className='max-w-[calc(100vw-500px)] mx-auto'>
            <h1 className='text-center text-primary-color my-5'>
                FORMULES D'ABONNEMENT CKWASH
            </h1>
            <GroupPlans />{' '}
        </div>
    );
}
