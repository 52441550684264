import React, { useState } from 'react';
import OptionCollapsed from './OptionCollapsed';
import Options from './Options';
import OptionHidden from './OptionHidden';
import OptionLoading from './OptionLoading';
import { useTranslation } from 'react-i18next';

export default function Select({
    id,
    label,
    options,
    onClick,
    fetchAPI,
    placement = 'bottom',
    value,
    optionAll = true,
    width,
}) {
    const { t } = useTranslation();
    const [optionsHidden, setOptionsHidden] = useState(false);
    const [searchValue, setSearchValue] = useState(
        value ? value : t('common.transAll'),
    );
    const [loading, setLoading] = useState(false);

    function handleClick(option) {
        setOptionsHidden(!optionsHidden);
        if (id) {
            document.getElementById(id).value = option.id;
        }
        setSearchValue(t(option.name));
        if (typeof onClick === 'function') {
            onClick();
        }
    }

    function handleClickfetchAPI() {
        if (typeof fetchAPI === 'function' && options.length === 0) {
            fetchAPI(setLoading);
        }
        setOptionsHidden(!optionsHidden);
    }

    return (
        <div
            className={`flex flex-col text-primary-color mr-2.5 mb-4 relative`}
        >
            <OptionCollapsed
                onClick={() => handleClickfetchAPI()}
                optionsHidden={optionsHidden}
                searchValue={searchValue}
                label={label}
                width={width}
            />

            <div className={`${!optionsHidden && 'hidden'} `}>
                {loading ? (
                    <OptionLoading />
                ) : (
                    <Options
                        options={options}
                        id={id}
                        onClick={(option) => handleClick(option)}
                        placement={placement}
                        optionAll={optionAll}
                    />
                )}
            </div>

            <OptionHidden
                onClick={() =>
                    optionsHidden && setOptionsHidden(!optionsHidden)
                }
                optionsHidden={optionsHidden}
            />
        </div>
    );
}
