import React from 'react';

const AlertMessage = ({ className, status, alertMessage, errorDetail }) => {
    // Vérifie si errorDetail est un objet
    const errorMessages =
        typeof errorDetail === 'object' && errorDetail !== null
            ? Object.entries(errorDetail)
            : [];

    return (
        <div className={`${className} p-4`}>
            {status && (
                <div className='text-lg font-semibold'>{`Erreur ${status}`}</div>
            )}
            <div className='text-xl font-bold'>{alertMessage}</div>
            {errorMessages.length > 0 ? (
                errorMessages.map(([field, messages]) => (
                    <div key={field} className='text-sm'>
                        <strong>{field}:</strong>{' '}
                        {Array.isArray(messages)
                            ? messages.join(', ')
                            : messages}
                    </div>
                ))
            ) : (
                <div className='text-sm'>{errorDetail}</div>
            )}
        </div>
    );
};

export default AlertMessage;
