export const daysOfWeek = [
    { full: 'Monday', abbrev: 'Lun', key: 'schedule_monday' },
    { full: 'Tuesday', abbrev: 'Mar', key: 'schedule_tuesday' },
    { full: 'Wednesday', abbrev: 'Mer', key: 'schedule_wednesday' },
    { full: 'Thursday', abbrev: 'Jeu', key: 'schedule_thursday' },
    { full: 'Friday', abbrev: 'Ven', key: 'schedule_friday' },
    { full: 'Saturday', abbrev: 'Sam', key: 'schedule_saturday' },
    { full: 'Sunday', abbrev: 'Dim', key: 'schedule_sunday' },
];

export function getScheduledDays(schedule) {
    const scheduledDays = daysOfWeek
        .filter((day) => schedule[day.key])
        .map((day) => day.abbrev);

    return scheduledDays.join('-');
}

//TODO CH à supprimer quand on rajoute l'enum
export const periods = [
    { label: 'Hebdomadaire', value: 0 },
    { label: 'Jounalier', value: 1 },
    { label: 'Mensuel', value: 2 },
    //{ label: "Annuel", value: 23
];

export function getPeriodLabel(value) {
    const period = periods.find((p) => p.value === value);
    return period ? period.label : null;
}
