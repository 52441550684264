import React, { useState, useEffect } from 'react';
import DatePicker from './DatePicker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function RangePicker({
    format,
    startDate,
    endDate,
    onRangeChange,
    onDateModifiedChange,
}) {
    // State pour gérer les dates sélectionnées
    const [selectedStartDate, setSelectedStartDate] = useState(startDate);
    const [selectedEndDate, setSelectedEndDate] = useState(endDate);
    const { t } = useTranslation();
    // Effet pour mettre à jour les dates sélectionnées lorsque les props startDate ou endDate changent
    useEffect(() => {
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
    }, [startDate, endDate]);

    // Effet pour déclencher le callback onRangeChange lorsque les dates sélectionnées changent
    useEffect(() => {
        onRangeChange && onRangeChange(selectedStartDate, selectedEndDate);
    }, [selectedStartDate, selectedEndDate]);

    // Fonction pour gérer le changement de date
    const handleDateChange = (date, type) => {
        // Formatage de la date en utilisant la bibliothèque moment
        const formattedDate = moment(date, format).toDate();
        const startDate = moment(formattedDate).startOf('day').toDate();
        const endDate = moment(formattedDate).endOf('day').toDate();

        // Logique pour mettre à jour les dates en fonction du type (START ou END)
        if (type === 'START') {
            setSelectedStartDate(startDate);
            // Vérifier si la nouvelle date de début est après la date de fin sélectionnée, ajuster si nécessaire
            if (startDate > selectedEndDate) {
                setSelectedEndDate(endDate);
            }
        } else if (type === 'END') {
            setSelectedEndDate(endDate);
            // Vérifier si la nouvelle date de fin est avant la date de début sélectionnée ajuster si nécessaire
            if (endDate < selectedStartDate || selectedStartDate == null) {
                setSelectedStartDate(startDate);
            }
        }
        // Notifier le changement de date au composant parent
        onDateModifiedChange && onDateModifiedChange(date);
    };

    return (
        <>
            {/* Premier DatePicker pour la date de début */}
            <DatePicker
                label={t('common.transDateStartLabel')}
                selectedDate={selectedStartDate}
                onDateChange={(date) => handleDateChange(date, 'START')}
                format={format}
            />
            {/* Deuxième DatePicker pour la date de fin */}
            <DatePicker
                label={t('common.transDateEndLabel')}
                selectedDate={selectedEndDate}
                onDateChange={(date) => handleDateChange(date, 'END')}
                format={format}
            />
        </>
    );
}
