/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/system/e_identification_type.py
Aucune modification manuelle acceptée
*/

// type d'identifiant possible permettant de définir qui a réalisé une action (paiement, démarrage)
// 
// Anciennement E_IDENTIFICATION_TYPE, ESUPPORT_TYPE
// Anciennement TTYPE_ID
const eIdentificationType = Object.freeze({
  // Correspond à une fin d'identification (lorsqu'elle est nécessaire)
  // 
  // ANCIENNEMENT: SUPPORT_TYPE_STOP
  0: 'eIdentificationType.stop',

  // Correspond à l'identifiant local user id du badge et local manager id.
  // Ce numéro est programmé lors de la création du badge.
  // Il n'y a pas de garantie d'unicité.
  // 
  // ANCIENNEMENT: BADGE_USER_ID, SUPPORT_TYPE_BADGE_USER_ID
  1: 'eIdentificationType.supportUserId',

  // Ce numéro correspond à l'UID du support qui est normalement unique au monde
  // Souvent appelé NSE.
  // 
  // PU4-20, MEC-22 23 24, SEC-20 22 23, MA3-14 15 16 17, STP-24, SEV-20 23, CKL-23, SPCTT3-23, MEV-21
  // en numéro de serie concerné. Je suppose donc un bug introduit en 2023 et des mises à jour sur des
  // anciens équipements (j'ai eu une trentaine d'équipement qui m'ont remonté ceci en quelques jours).
  // Les supports remontés, n'étaient jamais serveur, et ils n'avaient pas toujours de numéro de série.
  // Donc vraiment aucune différence avec SUPPORT_USER_ID.
  // # LD: Probablement un badge INSIDE qui renvoie ce numéro.
  // 
  // Pour continuer le traitement sur le serveur, de toutes les bornes concernées, je suis
  // donc obligé de ne pas prendre en compte cette identifiant
  // et de le remplacer par SUPPORT_USER_ID.
  // 
  // À suivre de ce que l'on fera de lui dans des prochaines versions...
  // 
  // ANCIENNEMENT: SUPPORT_TYPE_RFID_HARDWARE_UID
  2: 'eIdentificationType.supportRfidHardwareUid',

  // Correspond à l'identifiant d'une fiche client
  // (entity de type société, ou utilisateur) dans mod_entity du serveur
  // 
  // ANCIENNEMENT: SUPPORT_TYPE_SERVER_ENTITY_ID
  3: 'eIdentificationType.supportQrcode',

  // Correspond à la combinaison d'un identifiant de société (mod_manager:company) avec un
  // identifiant d'utilisateur (de mod_authentication:user)
  // 
  // ANCIENNEMENT: SUPPORT_TYPE_SERVER_COMPANY_USER_ID
  4: 'eIdentificationType.serverCompanyUserId',

  // Correspond à une action par le serveur web de la borne
  // 
  // ANCIENNEMENT: SUPPORT_TYPE_WEB
  // ANCIENNEMENT: SUPPORT_TYPE_WEB_OLD
  5: 'eIdentificationType.web',

  // DEPRECATED
  // Des bornes plus ancienne utilise ce numéro pour le web. Pour la compatibilité
  // nous sommes obligé de le garder actuellement
  6: 'eIdentificationType.webOld',
});

export default eIdentificationType;
